import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import SweetAlert from "react-bootstrap-sweetalert"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiSessionTimeout extends Component {
  constructor(props) {
    super(props)
    this.state = { timeralert: null, timerswitch: false, seconds: 0 }
    this.tick = this.tick.bind(this)
  }

  tick() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({ seconds: prevState.seconds + 1 }))
    }, 1000)
  }

  componentDidMount() {
    this.main_function()
  }

  hideAlert() {
    window.location = "/login"
  }

  confirmAlert() {
    this.setState({ timeralert: null })
  }

  main_function() {
    setTimeout(
      function () {
        setTimeout(
          function () {
            this.function1()
          }.bind(this),
          6000
        )
        this.function2()
      }.bind(this),
      6000
    )
  }

  function1() {
    if (window.location.pathname === "/ui-session-timeout") {
      window.location = "/login"
    } else {
    }
  }

  function2() {
    this.tick()
    const nextmsg = () => (
      <SweetAlert
        showCancel
        confirmBtnText="Stay Connected"
        cancelBtnText="Logout"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        title="Your Session is About to Expire!"
        onCancel={() => this.hideAlert()}
        onConfirm={() => this.confirmAlert()}
      >
        Redirecting in 10s seconds.<br></br>
      </SweetAlert>
    )
    this.setState({ timeralert: nextmsg() })
  }

  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>User Document | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">  
          <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6 mb-4">Document</CardTitle>
                     <Row className="">
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                     </Row>


                     <Row className="border-bottom">
                     <CardTitle className="h6 mb-4 mt-4">Deduction</CardTitle>
                     <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Deduction Type</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Start Month</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Start Year</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Monthly deduction amount"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="No of Installment"
                            />
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Total Deduction"
                            />
                          </div>
                          
                        </Col>
                        <Col lg={3}>
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>

                      <Row className="mt-4">
                          <Col className="col-12">
                              <div className ="d-flex justify-content-end">
                                <div className="border-bottom">
                                        From - To<i className="mdi mdi-calendar-month h4 ms-3"></i>
                                </div>
                                <div className="border-bottom ms-4">
                                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                                </div>
                              </div>
                          </Col>
                      </Row>


                      <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                  
               <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Deduction Type</th>
                          <th scope="col" className="">Start Month/Year</th>
                          <th scope="col" className="">End Month/Year</th>
                          <th scope="col" className="">No of Installments</th>
                          <th scope="col" className="">Installment Amount</th>
                          <th scope="col" className="">Total Deduction</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="user-td">1</th>
                          <td>Advance
                          </td>
                          <td>01/20</td>
                          <td>06/20</td>
                          <td>06</td>
                          <td>1000</td>
                          <td>6000</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil me-3"></i></a>
                              <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">2</th>
                          <td>Advance
                          </td>
                          <td>01/20</td>
                          <td>06/20</td>
                          <td>06</td>
                          <td>1000</td>
                          <td>6000</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil me-3"></i></a>
                              <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">3</th>
                          <td>Advance
                          </td>
                          <td>01/20</td>
                          <td>06/20</td>
                          <td>06</td>
                          <td>1000</td>
                          <td>6000</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil me-3"></i></a>
                              <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
                      
                      {/* <Row>
                        <Col lg={4}>
                        <div  className="form-check mb-3">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                        </Col>
                        <Col className="col-12 mt-4">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row> */}
                     
                               <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiSessionTimeout
