import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

// import image
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const images = [img1, img2, img3, img4, img5, img6, img7];
const imageZoom = [img3, img7];

class UiLightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isFits: false,
      isEffects: false,
      isGallery: false,
      isGalleryZoom: false,
      isOpen: false,
      isOpen1: false,
      modal_standard: false,
    };
    this.openModal = this.openModal.bind(this);
    this.openModal1 = this.openModal1.bind(this);
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }));
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  openModal1() {
    this.setState({ isOpen1: true });
  }

  render() {
    const { photoIndex } = this.state;

    return (
      <React.Fragment>
      <div className="page-content">
          <MetaTags>
            <title>Company Add/Edit | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6 mb-4">Employee Settings</CardTitle>
                     <Row>
                     <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Company</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Branch</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Employee Type</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Designation</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Department</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>User Type</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Direct Manager</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Alternate Manager</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                     

                   
                      <Row>
                        <Col lg={4}>
                        <div  className="form-check mb-3">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                          <label className="form-check-label" htmlFor="defaultCheck1">Manager</label>
                                    </div>
                        </Col>
                        <Col xl={12} sm={6}>
                      <h5 className="font-size-14">Employee Status</h5>
                        <div className="mt-4 mb-4 d-flex align-items-center">
                        
                          <div className="form-check">
                          
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                             On Probation
                            </label>
                          </div>
                          <div className="form-check ms-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                             Confirmed
                            </label>
                          </div>
                        </div>
                      </Col>
                       
                      </Row>

                      <CardTitle className="h6 mb-4 mt-5">Bank Account Details</CardTitle>
                     <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Bank A/c No"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Bank Name"
                            />
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="IFSC Code"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Branch"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="PAN No"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Aadhar No"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="PF No"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="ESIC No"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="UAN No"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                      <Col className="col-12 mt-4">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>
                     
                               <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiLightbox;
