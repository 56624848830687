import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiProgressbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>User Document | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">  
          <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6 mb-4">Document</CardTitle>
                     <Row className="border-bottom">
                     <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Deduction Type</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="input-group">
                              <Input type="file" className="form-control" id="inputGroupFile02" />
                              <Label className="input-group-text" htmlFor="inputGroupFile02">Upload</Label>
                            </div>
                        </Col>
                        <Col lg={3}>
                <div className ="d-flex align-items-center
                 justify-content-between">
                    <a className ="btn btn-primary btn-sm mt-1" href="/">Add</a>
                </div>
                
              
              </Col>
              <Col lg={8}>
                <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control pb-5"
                              id="formrow-InputCity"
                              placeholder="Remarks"
                            />
                          </div>
                </Col>
                      </Row>

                      <Row className="mt-4">
                          <Col className="col-12">
                              <div className ="d-flex justify-content-end">
                                <div className="border-bottom ms-4">
                                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                                </div>
                              </div>
                          </Col>
                      </Row>


                      <Row>
              
              <Col className="col-12 mt-4">
                <p className="">Total Experience</p>
                <Card>
                  <CardBody>
                     {/* User list */}
                  
               <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Document Type</th>
                          <th scope="col" className="">Document</th>
                          <th scope="col" className="">Remarks</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="user-td">1</th>
                          <td>Aadhaar Card</td>
                          <td><a hrefLang="#" className="table-link">View File</a></td>
                          <td>Latet Aadhar Card</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-close-circle h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">2</th>
                          <td>Aadhaar Card</td>
                          <td><a hrefLang="#" className="table-link">View File</a></td>
                          <td>Latet Updated Resume</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-close-circle h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                       
                      </tbody>
                  </table>
               </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
                      
                      {/* <Row>
                        <Col lg={4}>
                        <div  className="form-check mb-3">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                        </Col>
                        <Col className="col-12 mt-4">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row> */}
                     
                               <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiProgressbar
