import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/userdefaultimage.jpg";
import otpicon from "../../assets/images/frontend-img/otpicon.png";


import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import axios from "axios";
import Commheader from "../frontend/header";



const manageprofile = () => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [DOB, setDOB] = useState('');
  const [Gender, setGender] = useState('');
  const [ProfileFileUrl, setProfileFileUrl] = useState(profileimg);

  const [MyMembershipName, setMyMembershipName] = useState('');
  const [MembershipExpiryDate, setMembershipExpiryDate] = useState('');
  const [MembershipDetail, setMembershipDetail] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);

  useEffect(() => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getProfileData();
      getProfileMembershipData();
    }

  }, []);

  const getProfileData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/MyProfileData', formData)
      .then(res => {
        setEmail(res.data.Result[0].Email);
        setName(res.data.Result[0].Name);
        setMobileNo(res.data.Result[0].MobileNo)
        setGender(res.data.Result[0].Gender)
        setDOB(res.data.Result[0].DateOfBirth)
        if (res.data.Result[0].ProfilePic != null) {
          if (res.data.Result[0].ProfilePic != '') {
            setProfileFileUrl(res.data.Result[0].ProfilePic);
          }
        }
      });
    setShowLoader(0);
  }

  const getProfileMembershipData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        setMyMembershipName(res.data.Result[0].MyMembershipName);
        setMembershipExpiryDate(res.data.Result[0].MembershipExpiryDate);
        setMembershipDetail(res.data.Result[0].MembershipDetail)
      });
    setShowLoader(0);
  }


  return (
    <React.Fragment>

      {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row">
              <div className="col-md-6">
                <p className="addservicetextform">Profile Details</p>

              </div>
              <div className="col-md-6 text-end">
                <a href="/edit-profile" > <p className="addnewpropertytext">  <i className="las la-edit"></i>Edit</p></a>

              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainboxmpnew">
                  <div className="row gx-4">
                    <div className="col-md-10">
                      <div className="row gx-4">
                        <div className="col-md-6">
                          <div className="registerinput mt-0">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                            <input type="email" className="form-control border-0" id="exampleFormControlInput1" placeholder="Martine Ferry" readOnly defaultValue={Name} />
                          </div>

                        </div>

                        <div className="col-md-6">
                          <div className="registerinput mt-0">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control border-0" placeholder="Buford.Gutmann@gmail.com" aria-label="Recipient's username" aria-describedby="button-addon2" readOnly defaultValue={Email} />

                            </div>



                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control border-0" placeholder="+91 7896589963" aria-label="Recipient's username" aria-describedby="button-addon2" readOnly defaultValue={MobileNo} />

                            </div>

                          </div>


                        </div>

                        <div className="col-md-6">
                          <div className="registerinput">
                            <label htmlFor="exampleFormControlInput1" className="form-label">DOB</label>
                            <input type="text" className="form-control border-0" id="exampleFormControlInput1" placeholder="19th January 2000" readOnly defaultValue={DOB} />
                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Gender</label>
                            <input type="text" className="form-control border-0" id="exampleFormControlInput1" placeholder="Male" readOnly defaultValue={Gender} />
                          </div>

                        </div>



                      </div>

                    </div>
                    <div className="col-md-2">
                      <div className="registerinput mt-0">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                        <div>
                          <img src={ProfileFileUrl} className="imgprofileedit"></img>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>

              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainboxmpnew">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="membershiptitaledirrq">Your Membership</p>

                    </div>
                    <div className="col-md-12">
                      <div className="maintablediveditep">
                        <table className="table ">
                          <thead>
                            <tr>
                              <th scope="col">Status</th>
                              <th scope="col">Subscription</th>
                              <th scope="col">Expiration</th>
                              <th scope="col">Actions</th>
                              <th scope="col">Invoice</th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <p className="mainactivetextep">Active</p>
                              </td>
                              <td>
                                <p className="subscriptiontextep">{MyMembershipName}</p>
                              </td>
                              <td>
                                <p className="mainactivetextep">{MembershipExpiryDate}</p>
                              </td>
                              <td>
                                <p className="actionstexteditep">16th July 2023</p>
                              </td>
                              <td>
                                <p className="invoicetextep"><i className="las la-download"></i>Download</p>
                              </td>


                            </tr>



                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p className="membershiptitaledirrqnweewe">Membership Details</p>

                    </div>
                    <div className="col-md-12">
                      <div className="mainboxmabershipdetailss">
                        {MembershipDetail.map((item, idx) => (
                          <div className="mebershipdetailsep" key={idx}>
                            <div>
                              <i className="fas fa-check "></i>
                            </div>
                            <div>
                              <p>{item.Description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>


                  </div>
                </div>

              </div>

            </div>


          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default manageprofile
