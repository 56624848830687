import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning" 
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        {
          icon: "bx bx-copy-alt",
          title: "Orders",
          value: "1,452",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-archive-in",
          title: "Revenue",
          value: "$ 28,452",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Average Price",
          value: "$ 16.2",
          badgeValue: "0%",
          color: "warning",
          desc: "From previous period",
        },
      ],
    }
  }

  render() {
    return (
      <React.Fragment>
           {/* country list */}
           <div className ="table-responsive mb-5">
                        <table className="table table-striped mb-0 table">
                            <tbody className="">
                                <tr>
                                    <th className="width-20" scope="row">1</th>
                                    <td className="width-100">India</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="width-50">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Australia</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>USA</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Canada</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>China</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>Japan</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                  </div>
      </React.Fragment>
    )
  }
}

export default Dashboard
