import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//actions
import { getEarningChartsData } from "../../store/actions";

import ReactApexChart from "react-apexcharts";

class Earning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "series1",
          data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
        },
      ],
      options: {
        chart: {
          toolbar: "false",
          dropShadow: {
            enabled: !0,
            color: "#000",
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        colors: ["#556ee6"],
        stroke: {
          curve: "smooth",
          width: 3,
        },
      },
      earningChartData: [],
      seletedMonth: "jan"
    };
  }

  componentDidMount() {
    const { onGetEarningChartsData } = this.props;
    onGetEarningChartsData("jan");
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, earningChartData: this.props.earningChartData });
    }
  }

  render() {
    const { earningChartData, seletedMonth } = this.state;

    const series = [
      {
        name: "Series 1",
        data: [...earningChartData]
      }
    ];
    return (
      <React.Fragment>
      
      </React.Fragment>
    );
  }
}


Earning.propTypes = {
  earningChartData: PropTypes.any,
  onGetEarningChartsData: PropTypes.func
};

const mapStateToProps = ({ DashboardSaas }) => ({
  earningChartData: DashboardSaas.earningChartData,
});

const mapDispatchToProps = dispatch => ({
  onGetEarningChartsData: (month) => dispatch(getEarningChartsData(month)),
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(Earning);