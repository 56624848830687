import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

import
{ Col, Container, Row, Card, CardBody, Input , CardTitle, Label,}
from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiColors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stages | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6 mb-2">Stages</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Stages</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiColors;
