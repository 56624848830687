import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledAlert,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";



class UiAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>User Type | KC Admin App | Vendor Registration</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Vendor Registration</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Vendor Registration</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
           
            <Row>
              
              <Col className="col-12 ">
                <Card>
                  <CardBody>
                     {/* country list */}
                   
                    <Row>
                    <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company name</label>
                            <select id="inputState" className="form-select">
                              <option selected>Company Name</option>
                              <option>...</option>
                            </select>
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company Address</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company Address"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company Area</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Area"
                            />
                          </div>
                      </Col>
                     </Row>
                    
                    <Row>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Zip Code</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Zip Code"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Display Cheque Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Display Cheque Name"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                      <label className="form-label">Company PAN</label>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company PAN"
                            />
                          </div>
                      </Col>
                    </Row>


                    <Row>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company TAN</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company TAN"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company GST</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company GST"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company Email</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company Email"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company Contact Number</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company Contact No."
                            />
                          </div>
                      </Col>
                    </Row>

                    <Row className="mb-3"></Row>


                    <Row>
                    <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Company Title</label>
                            <select id="inputState" className="form-select">
                            <label className="form-label">Company Title</label>
                              <option selected>Company Title</option>
                              <option>...</option>
                            </select>
                          </div>
                      </Col>
                    </Row>
                    
                    <Row>
                    <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Contact First Person</label>
                            <select id="inputState" className="form-select">
                              <option selected>Contact Person First Name</option>
                              <option>...</option>
                            </select>
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Contact Middle Name</label>
                            <select id="inputState" className="form-select">
                              <option selected>Contact Person Middle Name</option>
                              <option>...</option>
                            </select>
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Contact Last Name</label>
                            <select id="inputState" className="form-select">
                              <option selected>Contact Person Last Name</option>
                              <option>...</option>
                            </select>
                          </div>
                      </Col>
                      
                  </Row>
                    <Row>
                    <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Contact Email</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Contact Person Email"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Contact Number</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Contact Person Contact No"
                            />
                          </div>
                      </Col>
                  </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                          <p className="fw-bold">Product / Services</p>
                      <a href="#">    <span className="product_tag d-inline-block  text-black"> Tags<i className="fas fa-times text-end text-black ms-3"></i></span></a> 
                      <a href="#">  <span className="product_tag d-inline-block text-black"> Tags  <i className="fas fa-times text-end text-black ms-3"></i></span></a> 
                     <a href="#">   <span className="product_tag d-inline-block text-black"> Tags  <i className="fas fa-times text-end text-black ms-3"></i></span></a> 
                       <a href="#"> <span className="product_tag d-inline-block text-black"> Tags  <i className="fas fa-times text-end text-black ms-3"></i></span></a> 
                      </Col>
                    </Row>

                    <Row className="mt-4"></Row>

                    <Row>
                      <Col md={12}>
                      <label className="form-label">Add Notes</label>
                      <textarea className="form-control mb-3" placeholder="Notes"></textarea>
                    </Col>
                    </Row>

                    <Row>
                    <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Business Category</label>
                            <select id="inputState" className="form-select">
                              <option selected>Main Business Category</option>
                              <option>...</option>
                            </select>
                          </div>
                      </Col>
                    <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">IFSCI Code</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="IFSCI Code"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Bank Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Bank Name"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Branch Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Branch Name"
                            />
                          </div>
                      </Col>
                      <Col md={4}>
                          <div className="mb-3">
                          <label className="form-label">Account Number</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Account Number"
                            />
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <input className="form-check-input mt-2 me-3" type="checkbox" value="" />
                          <a className="btn btn_success btn-sm d-inline-block" href="#">Save</a>
                          <a className="btn btn_danger btn-sm ms-2 d-inline-block" href="#">Clear</a>
                      </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiAlert;