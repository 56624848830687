import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  Label
} from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

//import action
import { getChartsData } from "../../store/actions"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import classNames from "classnames";

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly"
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    onGetChartsData("yearly");
  }


  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | KC Admin Group</title> 
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <CardTitle className="mb-3">Dashboard</CardTitle>
            <Row>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Current Openings</h5>
                                  <h1 className="mb-0">10</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                      <i className="bx bx-walk font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">New Job Application</h5>
                                  <h1 className="mb-0">20</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                      <i className="bx bxs-id-card font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">New Leave Application</h5>
                                  <h1 className="mb-0">100</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                      <i className="bx bx-file font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            </Row>

            <Row>
            <Card>
              <CardBody>
              <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium"> Add To Do</h5>
                    </div>
                    <div className="todo-btn">
                      <a href="/kc-todo" className="btn btn-success btn-sm me-3">Add New Task</a>
                      <a href="/kc-todo1" className="btn btn-primary btn-sm">View All</a>
                    </div>
                </div>
               
              </div>
              <Row>
              <div className="col-lg-6">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">Some task Related text </div>
                                </div>
                                <div className="btn-box">
                                <a href="/kc-todo3" className="btn btn-success btn-sm">Add To Do</a>
                                </div></div>
                                <p className="mt-3"><span className="h6">Description: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            <div className="box-2"></div>
                            <div className="m-16">
                            <div className="form-check mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                New Note With Attachment By 12th June2020<br></br> <span className="color-grey">TO Me & Kaushal</span>
                              </Label>
                            </div>
                            <p className="mt-2"><span className="h6">Notes: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex lead-box-1 p-7">
                                              <div className="ms-1 mt-1 mb-1">
                                              <p className="mb-0 font-25">2<i className="mdi mdi-attachment"></i></p>
                                              </div>
                                              <div className="ms-3">
                                                <p className="mb-1">KChistory.PDF</p>
                                                <p className="mb-0">1.26MB</p>
                                              </div>
                            </div>
                            <div className="ms-1 mt-1 mb-1  lead-box-1 p-18">
                                              <p className="mb-0">Over Due</p>
                                              </div>
                          </div>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">Some task Related text </div>
                                </div>
                                <div className="btn-box">
                                <a href="/kc-todo3" className="btn btn-success btn-sm">Add To Do</a>
                                </div></div>
                                <p className="mt-3"><span className="h6">Description: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            <div className="box-2"></div>
                            <div className="m-16">
                            <div className="form-check mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                New Note With Attachment By 12th June2020<br></br> <span className="color-grey">TO Me & Kaushal</span>
                              </Label>
                            </div>
                            <p className="mt-2"><span className="h6">Notes: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            </div>
                              </div>
                             
                              
                </div>
              </Row>
              </CardBody>
            </Card>
         
            </Row>

            <Row>
              <Col xl="4">
              <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                    <h5 className="font-weight-medium mb-4">Leave Application</h5>
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Kushal Patel</div>
                                              <p className="mb-2">#KC0001</p>
                                              <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p>
                                          </div>
                                          <button className="position-relative btn btn-primary">CL
                                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success font-size-12"> 1 <span className="visually-hidden">unread messages</span> </span>
                                          </button>
                                          
                                        </div>
                                      <div className="mt-2">
                                      <a href="" className="me-3"><span className="badge bg-primary font-size-11">Manger Status</span></a>
                                      <a href="" className="me-3"><span className="badge bg-success font-size-11">Approve</span></a>
                                      <a href="" className=""><span className="badge bg-danger font-size-11">Reject</span></a>
                                      </div>
                                    </div>
                  </div>
                  <div className="mini-stats-wid">
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Utsav Shah</div>
                                              <p className="mb-2">#KC0001</p>
                                              <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p>
                                          </div>
                                          <button className="position-relative btn btn-primary">LWP
                                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success font-size-12"> 0.5 <span className="visually-hidden">unread messages</span> </span>
                                          </button>
                                          
                                        </div>
                                      <div className="mt-2">
                                      <a href="" className="me-3"><span className="badge bg-primary font-size-11">Manger Status</span></a>
                                      <a href="" className="me-3"><span className="badge bg-success font-size-11">Approve</span></a>
                                      <a href="" className=""><span className="badge bg-danger font-size-11">Reject</span></a>
                                      </div>
                                    </div>
                  </div>
                  <div className="mini-stats-wid">
                    <div className="leave-sub-box">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Hiren Suthar</div>
                                              <p className="mb-2">#KC0001</p>
                                              <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p>
                                          </div>
                                          <button className="position-relative btn btn-primary">CL
                                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success font-size-12"> 1 <span className="visually-hidden">unread messages</span> </span>
                                          </button>
                                          
                                        </div>
                                      <div className="mt-2">
                                      <a href="" className="me-3"><span className="badge bg-primary font-size-11">Manger Status</span></a>
                                      <a href="" className="me-3"><span className="badge bg-success font-size-11">Approve</span></a>
                                      <a href="" className=""><span className="badge bg-danger font-size-11">Reject</span></a>
                                      </div>
                                    </div>
                  </div>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col xl="5">
              <Card>
                  <CardBody>
                  <h5 className="font-weight-medium mb-4"> Followups</h5>
                  <div className="leave-sub-box mb-3">
                  <span className="badge rounded-pill bg-warning mb-2 float-end">Due Today</span>
                                      <div className="leave-sub-box-1">
                                     
                                        <div className="name-box">
                                            <p className="mb-0 mt-3">when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        {/* <button className="position-relative btn btn-primary ms-3">Due Today</button> */}
                                        
                                      </div>
                                     <div className="m-21">
                                     <button className="btn btn-success btn-sm me-3">Followup Date</button>
                                     <button className="btn btn-primary btn-sm">Followup Mode</button>
                                     </div>
                                  </div>
                                  <div className="leave-sub-box mb-3">
                                  <span className="badge rounded-pill bg-danger mb-2 float-end">Over Due</span>
                                      <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <p className="mb-0 mt-3">when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                      </div>
                                     <div className="m-21">
                                     <button className="btn btn-success btn-sm me-3">Followup Date</button>
                                     <button className="btn btn-primary btn-sm">Followup Mode</button>
                                     </div>
                                  </div>
                                  <div className="leave-sub-box mb-3">
                                  <span className="badge rounded-pill bg-warning mb-2 float-end">Due Today</span>
                                      <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <p className="mb-0 mt-3">when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        
                                      </div>
                                     <div className="m-21">
                                     <button className="btn btn-success btn-sm me-3">Followup Date</button>
                                     <button className="btn btn-primary btn-sm">Followup Mode</button>
                                     </div>
                                  </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3">
              <Card>
                  <CardBody>
                  <div className="heading-box">
                                          <div className="heading-box-1 mb-3 border-bottom">
                                            <div className="h5">
                                              Holiday List
                                          </div>
                                          <div className="">
                                            <a className="font-14" href="">View All</a>
                                          </div>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between mb-3">
                                                    
                                      <div className="h6 mb-0 font-size-16">Rath Yatra</div>
                                      <span className="badge rounded-pill badge-soft-danger font-12">10 July</span>
                                      {/* <button className="btn btn-danger ms-3">10 July</button> */}
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                                    
                                      <div className="h6 mb-0 font-size-16">Dussehra</div>
                                      <span className="badge rounded-pill badge-soft-danger font-12">20 July</span>
                                      </div>
                                                      
                                                  
                                    </div>
                                    <div className="heading-box mt-5">
                                          <div className="heading-box-1 mb-3 border-bottom">
                                            <div className="h5">
                                            Birthday List
                                          </div>
                                          <div className="">
                                            <a className="font-14" href="">View All</a>
                                          </div>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between mb-3">
                                     <div className="">               
                                      <div className="h6 mb-1 font-size-16">Achal Patel</div>
                                    <div className="font-12 mb-0">KC00009</div></div>
                                    <span className="badge rounded-pill badge-soft-success font-12">10 July</span>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between mb-3">
                                     <div className="">               
                                      <div className="h6 mb-1 font-size-16">Kushal Patel</div>
                                    <div className="font-12 mb-0">KC00029</div></div>
                                    <span className="badge rounded-pill badge-soft-success font-12">10 July</span>
                                      </div>
                                                      
                                                  
                                    </div>
                                    <div className="heading-box mt-5">
                                          <div className="heading-box-1 mb-3 border-bottom">
                                            <div className="h5">
                                            Work Anniversary
                                          </div>
                                          <div className="">
                                            <a className="font-14" href="">View All</a>
                                          </div>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between mb-3">
                                     <div className="">               
                                      <div className="h6 mb-1 font-size-16">Achal Patel</div>
                                    <div className="font-12 mb-0">KC00009</div></div>
                                    <span className="badge rounded-pill badge-soft-info font-12">20 March</span>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between mb-3">
                                     <div className="">               
                                      <div className="h6 mb-1 font-size-16">Kushal Patel</div>
                                    <div className="font-12 mb-0">KC00029</div></div>
                                    <span className="badge rounded-pill badge-soft-info font-12">10 July</span>
                                      </div>
                                                      
                                                  
                                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
       
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))

