import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Tooltip,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiGeneral extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Popover
      popovertop1: false,
      popoverleft: false,
      popoverright: false,
      popoverbottom: false,
    }
    this.toggletop1 = this.toggletop1.bind(this)
    this.toggleright = this.toggleright.bind(this)
    this.toggleleft = this.toggleleft.bind(this)
    this.togglebottom = this.togglebottom.bind(this)
    this.toggledismiss = this.toggledismiss.bind(this)
    this.toggledismissclose = this.toggledismissclose.bind(this)
  }

  toggletop1() {
    this.setState({ popovertop1: !this.state.popovertop1 })
  }

  toggleleft() {
    this.setState({ popoverleft: !this.state.popoverleft })
  }

  toggleright() {
    this.setState({ popoverright: !this.state.popoverright })
  }

  togglebottom() {
    this.setState({ popoverbottom: !this.state.popoverbottom })
  }

  toggledismiss() {
    this.setState({ popoverdismiss: !this.state.popoverdismiss })
  }

  toggledismissclose() {
    this.setState({ popoverdismiss: false })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Job Application | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6">Job Application</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Job Application</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                   
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiGeneral
