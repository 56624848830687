import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";


import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";
import Commheader from "../frontend/header";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import { Link } from "react-router-dom";


const propertyList = () => {

  const [PropertyList, setPropertyList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0); 

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      setShowLoader(1);
      getPriorityList();
    }
  }, []);

  const getPriorityList = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: 1
    }
    axios.post(global.APIURL + '/PropertyList', PropListData)
      .then(res => {
        setPropertyList(res.data.Result);

      });
      setShowLoader(0);
  }

  const EditPropertyDetails = async (e, PEditId) => {
    setShowLoader(1);
    localStorage.setItem("EditPropertyId", PEditId);
    console.log("EditPropertyId", PEditId);
    window.location.href = '/property-details';
  }

  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader" >
        <div className="mainloadernew">
          <div className="spinner-border text-primary mainloderedit" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      : ''}
    <div className="modal fade" id="addnewpropertymodal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">

          <div className="modal-body">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <p className="addservicetextform">Add New Properties</p>

                  </div>

                  <div className="col-md-12">
                    <p className="mainsteptextform">Step 3 : Documents</p>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="propertysteptwomainbox">
                      <div className="d-flex">
                        <div>
                          <div id="carouselExampleIndicators" className="carousel slide propertyimgslider" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                              </div>
                              <div className="carousel-item ">
                                <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                              </div>
                              <div className="carousel-item ">
                                <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                              </div>

                            </div>

                          </div>


                        </div>
                        <div className="w-100">
                          <div className="d-flex w-100">
                            <p className="mainpropertytitlelist">Property Name </p>
                            <div>

                              <img className="verifiedgreenstep2" src={verifiedgreen} />
                            </div>
                          </div>
                          <div>
                            <p className="propertycatageoried">Category</p>
                          </div>
                          <div className="d-flex">
                            <div>
                              <img src={locationpropertyedit} className="locationpropertyedit" />
                            </div>
                            <div>
                              <p className="propertytextlocation">Prohaska Squares 05943 Harvey Mill</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="deletprpertyadd">
                          <i className="las la-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty ">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="maintitlereuestservicenee">Documents</p>
                        </div>
                        <div className="col-md-6 text-end">
                          <a href="#"> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New Document</p></a>
                        </div>
                      </div>
                      <div className="row mainaddnewinsightalldiv">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-8">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                        </div>
                      </div>
                      <div className="row mainaddnewinsightalldiv">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-8">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <form>
                        <div className="form-group newwform-group">
                          <input type="checkbox" id="html" />
                          <label htmlFor="html">By ticking, you are confirming that you have read, understood and agree to the <a href="#" className="mainlineatagee">Terms and conditions</a>  and <a className="mainlineatagee" href="#">privacy policy</a> </label>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <a href="#" > <button type="button" className="btn btn-primary btnnextedit " >Finish <i className="fas fa-long-arrow-alt-right"></i></button></a>
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                    <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Save as Draft </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="addnewpropertymodal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-body">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <p className="addservicetextform">Add New Properties</p>
                  </div>
                  <div className="col-md-12">
                    <p className="mainsteptextform">Step 2 : Land Area & Insights</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="propertysteptwomainbox">
                      <div className="d-flex">
                        <div>
                          <div id="carouselExampleIndicators" className="carousel slide propertyimgslider" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                              </div>
                              <div className="carousel-item ">
                                <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                              </div>
                              <div className="carousel-item ">
                                <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="d-flex w-100">
                            <p className="mainpropertytitlelist">Property Name </p>
                            <div>
                              <img className="verifiedgreenstep2" src={verifiedgreen} />
                            </div>
                          </div>
                          <div>
                            <p className="propertycatageoried">Category</p>
                          </div>
                          <div className="d-flex">
                            <div>
                              <img src={locationpropertyedit} className="locationpropertyedit" />
                            </div>
                            <div>
                              <p className="propertytextlocation">Prohaska Squares 05943 Harvey Mill</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="deletprpertyadd">
                          <i className="las la-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row ">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="maintitlereuestservicenee">Land Area</p>
                          </div>
                          <div className="col-md-8">
                            <div className="registerinputnewedirr form-controlsustomeditnew">
                              <input type="text" className="form-control formcontrollplaceholder" id="exampleFormControlInput1" placeholder="Enter Land Area" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <select className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example">
                              <option selected>Sq. ft</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="maintitlereuestservicenee">Super Built Up</p>
                          </div>
                          <div className="col-md-8">
                            <div className="registerinputnewedirr form-controlsustomeditnew">
                              <input type="text" className="form-control formcontrollplaceholder" id="exampleFormControlInput1" placeholder="Enter" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <select className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example">
                              <option selected>Sq. ft</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty mb-0">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="maintitlereuestservicenee">Property Insight (Optional)</p>
                        </div>
                        <div className="col-md-6 text-end">
                          <a href="#"> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New Property</p></a>
                        </div>
                      </div>
                      <div className="row mainaddnewinsightalldiv">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                <div className="maininputdefulttext">
                                  <p>W</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                <div className="maininputdefulttext">
                                  <p>L</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                <div className="maininputdefulttext">
                                  <p>H</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                        </div>
                      </div>
                      <div className="row mainaddnewinsightalldiv">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                <div className="maininputdefulttext">
                                  <p>W</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                <div className="maininputdefulttext">
                                  <p>L</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                <div className="maininputdefulttext">
                                  <p>H</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <form>
                              <div className="form-group newwform-group">
                                <input type="checkbox" id="html" />
                                <label htmlFor="html">is Property Furnished ?</label>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="maindivederaddpropertyinsight"></div>
                        </div>
                      </div>
                      <div className="row mainaddnewinsightalldiv align-items-center ">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-3">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                <option selected>Room 1</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <a href="#"> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New </p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#addnewpropertymodal3" data-bs-dismiss="modal" aria-label="Close"> <button type="button" className="btn btn-primary btnnextedit " >Next <i className="fas fa-long-arrow-alt-right"></i></button></a>
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                    <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Save as Draft </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="addnewpropertymodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          {/* <div className="modal-header">
    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div> */}
          <div className="modal-body">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <p className="addservicetextform">Add New Properties</p>
                  </div>
                  <div className="col-md-12">
                    <p className="mainsteptextform">Step 1 : Property Detail</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty">
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Property Title </p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Property Title" />
                              </div>
                            </div>
                          </div>
                          <div className="mainredioboxeditap">
                            <p className="maintitlereuestservicenee">Property Type</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                  <label className="form-check-label commonblackcolor" htmlFor="inlineRadio1">Flat / Apartment</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                  <label className="form-check-label commonblackcolor" htmlFor="inlineRadio2">Villa</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                  <label className="form-check-label commonblackcolor" htmlFor="inlineRadio3">Land / Plot</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" />
                                  <label className="form-check-label commonblackcolor" htmlFor="inlineRadio4">Other</label>
                                </div>
                              </div>
                              <div>
                                <div className="registerinputnewedirr form-controlsustomeditnew">
                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Write Here" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="row mainsideanpimgdiv">
                            <div className="col-4">
                              <div className="mainanpimg">
                                <img src={anp1}></img>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mainanpimg">
                                <img src={anp2}></img>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mainanpimg">
                                <img src={anp3}></img>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mainanpimg">
                                <img src={anp4}></img>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mainanpimg">
                                <img src={anp5}></img>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mainanpimg">
                                <a href="#">  <img src={addanp}></img></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty mb-0">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <p className="blutextheadingeditt">Location Information</p>
                        </div>
                        <div className="col-md-7">
                          <div className="row mainlocationinfodivv">
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Property Number </p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter House / Property Number" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Near By Address</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Near by Address" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Street</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Street Name" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Pin Code</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Pin Code" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                <p className="maintitlereuestservicenee">City</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Your City" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                <p className="maintitlereuestservicenee">State</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Your State" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                <p className="maintitlereuestservicenee">Country</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Your Country" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <p className="maintitlereuestservicenee">Location  </p>
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4079037565034!2d72.50534231495733!3d23.04550252125371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bb51d412b01%3A0x4c5338c9649c6eab!2sZion%20Z1!5e0!3m2!1sen!2sin!4v1661162103882!5m2!1sen!2sin" width="100%" height="191" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="mainlocationpreview"></iframe>
                          <div className="registerinputnewedirr form-controlsustomeditnew">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Your Address" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#addnewpropertymodal2" data-bs-dismiss="modal" aria-label="Close"> <button type="button" className="btn btn-primary btnnextedit " >Next <i className="fas fa-long-arrow-alt-right"></i></button></a>
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                    <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Save as Draft </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Commheader />
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row mainitalpropertylist">
            <div className="col-md-6">
              <p className="addservicetextform">Property List</p>
            </div>
            <div className="col-md-6 text-end">
              <a href="/add-new-property"> <p className="addnewpropertytext">  <i className="las la-plus-circle"></i>Add New Property</p></a>
            </div>
          </div>
          <div className="row">
            {PropertyList.map((propItems) => (<div className="col-md-6" key={propItems.PropertyId}>
              <div className="mainpropertylistbox">
                <div className="d-flex align-items-center">
                  <div>
                    <div id="carouselExampleIndicators" className="carousel slide propertyimgslider propertyimgsliderpl" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                        </div>
                        <div className="carousel-item ">
                          <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                        </div>
                        <div className="carousel-item ">
                          <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between w-100">

                      <a onClick={(e) => EditPropertyDetails(e, propItems.PropertyId)}>   <p className="mainpropertytitlelist">{propItems.PropertyName}</p></a>
                    </div>
                    <div>
                      <p className="propertycatageoried">Category {propItems.PropertyType} </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation mb-0"> {propItems.FullAddress}</p>
                      </div>
                    </div>
                    <div className="maindividerpd"> </div>
                    <div>
                      <p className="landareatext">Land Area :</p>
                      <div className="d-flex">
                        <div className="margineditlandarea">
                          <p className="mainlandareatextdescriptionnew2"> <img src={landarea1} />  {propItems.LandArea}Sq. ft</p>
                        </div>
                        <div>
                          <p className="mainlandareatextdescriptionnew2"> <img src={landarea2} /> {propItems.BuiltUpaArea} Sq. ft</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>))}
            {/* <div className="col-md-6">
          <div className="mainpropertylistbox">
            <div className="d-flex align-items-center">
              <div>
                <div id="carouselExampleIndicators" className="carousel slide propertyimgslider propertyimgsliderpl" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>
                    <div className="carousel-item ">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>
                    <div className="carousel-item ">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>

                  </div>

                </div>


              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between w-100">
                  <p className="mainpropertytitlelist">Property Name</p>

                </div>
                <div>
                  <p className="propertycatageoried">Category </p>
                </div>
                <div className="d-flex">
                  <div>
                    <img src={locationpropertyedit} className="locationpropertyedit" />
                  </div>
                  <div>
                    <p className="propertytextlocation mb-0">Walter Parkways 1932 Easter Gateway</p>
                  </div>

                </div>
                <div className="maindividerpd"> </div>
                <div>
                  <p className="landareatext">Land Area :</p>
                  <div className="d-flex">
                    <div className="margineditlandarea">
                      <p className="mainlandareatextdescriptionnew2"> <img src={landarea1} /> 888.53 Sq. ft</p>
                    </div>
                    <div>
                      <p className="mainlandareatextdescriptionnew2"> <img src={landarea2} /> 250 Sq. ft</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-6">
          <div className="mainpropertylistbox">
            <div className="d-flex align-items-center">
              <div>
                <div id="carouselExampleIndicators" className="carousel slide propertyimgslider propertyimgsliderpl" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>
                    <div className="carousel-item ">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>
                    <div className="carousel-item ">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>

                  </div>

                </div>


              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between w-100">
                  <p className="mainpropertytitlelist">Property Name</p>

                </div>
                <div>
                  <p className="propertycatageoried">Category </p>
                </div>
                <div className="d-flex">
                  <div>
                    <img src={locationpropertyedit} className="locationpropertyedit" />
                  </div>
                  <div>
                    <p className="propertytextlocation mb-0">Walter Parkways 1932 Easter Gateway</p>
                  </div>

                </div>
                <div className="maindividerpd"> </div>
                <div>
                  <p className="landareatext">Land Area :</p>
                  <div className="d-flex">
                    <div className="margineditlandarea">
                      <p className="mainlandareatextdescriptionnew2"> <img src={landarea1} /> 888.53 Sq. ft</p>
                    </div>
                    <div>
                      <p className="mainlandareatextdescriptionnew2"> <img src={landarea2} /> 250 Sq. ft</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-6">
          <div className="mainpropertylistbox">
            <div className="d-flex align-items-center">
              <div>
                <div id="carouselExampleIndicators" className="carousel slide propertyimgslider propertyimgsliderpl" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>
                    <div className="carousel-item ">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>
                    <div className="carousel-item ">
                      <img src={mainpropertiseimages} className="mainpropertysliderimg" />
                    </div>

                  </div>

                </div>


              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between w-100">
                  <p className="mainpropertytitlelist">Property Name</p>

                </div>
                <div>
                  <p className="propertycatageoried">Category </p>
                </div>
                <div className="d-flex">
                  <div>
                    <img src={locationpropertyedit} className="locationpropertyedit" />
                  </div>
                  <div>
                    <p className="propertytextlocation mb-0">Walter Parkways 1932 Easter Gateway</p>
                  </div>

                </div>
                <div className="maindividerpd"> </div>
                <div>
                  <p className="landareatext">Land Area :</p>
                  <div className="d-flex">
                    <div className="margineditlandarea">
                      <p className="mainlandareatextdescriptionnew2"> <img src={landarea1} /> 888.53 Sq. ft</p>
                    </div>
                    <div>
                      <p className="mainlandareatextdescriptionnew2"> <img src={landarea2} /> 250 Sq. ft</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div> */}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
          </div>
          <div className="d-flex">
            <p className="footercopytextedit">T&C</p>
            <p className="footercopytextedit2">Privacy</p>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>);
}


export default propertyList
