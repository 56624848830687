import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";


import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";
import teamassign from "../../assets/images/frontend-img/teamassign.jpg";
import paymentsuccessfully from "../../assets/images/frontend-img/paymentsuccessfully.png";
import cancel from "../../assets/images/frontend-img/cancel.png";













import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import mainpaymentloadingimg from "../../assets/images/frontend-img/mainpaymentloadingimg.png";
import failed from "../../assets/images/frontend-img/failed.png";

import axios from "axios";
import Commheader from "../frontend/header";





const servicedetail = () => {
  const [PropertyName, setPropertyName] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [FullAddress, setFullAddress] = useState("");
  const [PropertyImageList, setPropertyImageList] = useState([]);

  const [LandArea, setLandArea] = useState("");
  const [LandAreaIn, setLandAreaIn] = useState("");
  const [SuperBuiltUP, setSuperBuiltUP] = useState("");
  const [SuperBuiltUpIn, setSuperBuiltUpIn] = useState("");

  const [ServiceFilter, setServiceFilter] = useState([]);
  const [ServiceDescriptionList, setServiceDescriptionList] = useState([]);
  const [ServiceMediaList, setServiceMediaList] = useState([]);

  const [ServiceName, setServiceName] = useState('');
  const [ServiceTypeName, setServiceTypeName] = useState('');
  const [KeyStatus, setKeyStatus] = useState('');

  const [PetsInstruction, setPetsInstruction] = useState('');
  const [OtherInstructions, setOtherInstructions] = useState('');


  useEffect(() => {
    getServiceType();
    getPropertyDetail();
    getServiceChange()
    getServiceDetail()
  }, []);

  const getServiceType = () => {
    var AUrl = global.APIURL + '/Servicetype';
    axios.get(AUrl)
      .then(res => {

        for (let sl = 0; sl < res.data.Result.length; sl++) {
          const VServiceList = res.data.Result[sl].ServiceList;

          for (let sd = 0; sd < VServiceList.length; sd++) {
            const Ditem = {
              ServiceTypeId: res.data.Result[sl].ServiceTypeId,
              ServiceId: VServiceList[sd].ServiceId,
              ServiceName: VServiceList[sd].ServiceName,
              Description: VServiceList[sd].Description,
              Media: VServiceList[sd].Media,
            };
            ServiceFilter.push(Ditem)
          }
        }
      });
  }

  const getPropertyDetail = async () => {
    let SPId=JSON.parse(localStorage.getItem('DetailPropertyId'));
    let VSelectPropertyId = parseInt(SPId == null ? "0" : SPId == "" ? "0" : SPId);
    var LoginUserId = localStorage.getItem("RegisterId") == null ? "0" : localStorage.getItem("RegisterId") == "" ? "0" : localStorage.getItem("RegisterId");
    const PropertyDetailArr = {
      RegisterId: LoginUserId,
      PropertyId: VSelectPropertyId
    };
    axios.post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {
        setPropertyName(res.data.Result[0].PropertyTitle);
        setPropertyType(res.data.Result[0].PropertyTypeName);
        setFullAddress(res.data.Result[0].FullAddress)
        setLandArea(res.data.Result[0].LandArea)
        setLandAreaIn(res.data.Result[0].LandAreaIn)
        setSuperBuiltUP(res.data.Result[0].SuperBuiltUP)
        setSuperBuiltUpIn(res.data.Result[0].SuperBuiltUpIn)
        var PropImageList = res.data.Result[0].Media;
        var PIArr = [];
        for (let pi = 0; pi < PropImageList.length; pi++) {
          const PropImageUrl = PropImageList[pi].Url;
          if (PropImageUrl != null) {
            if (PropImageUrl != '') {
              const PropImageitem = {
                RId: pi,
                SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
                SliderName: 'Slider ' + (pi + 1),
                PropImageUrl: PropImageUrl
              };
              PIArr.push(PropImageitem);
            }
          }
        }
        setPropertyImageList(PIArr);
      })

  }

  const getServiceChange = () => {
    let SId=JSON.parse(localStorage.getItem('DetailServiceId'));
    let VSelectId = parseInt(SId == null ? "0" : SId == "" ? "0" : SId);
    var SF = ServiceFilter.filter(x => x.ServiceId == VSelectId);
    if (SF.length > 0) {
      setServiceId(SId)
      setServiceName(SF[0].ServiceName);
      setServiceDescriptionList(SF[0].Description);
      setServiceMediaList(SF[0].Media);
    }
  }

  const getServiceDetail = () => {
    let SId=JSON.parse(localStorage.getItem('DetailServiceId'));
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VSelectId = parseInt(SId == null ? "0" : SId == "" ? "0" : SId);

    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    formData.append('ServiceId', VSelectId);

    axios.post(global.APIURL + "/SeviceDetail", formData)
      .then(res => {
        setServiceName(res.data.Result[0].ServiceName);
        setServiceTypeName(res.data.Result[0].ServiceTypeName);
        setKeyStatus(res.data.Result[0].KeyStatus);
        setPetsInstruction(res.data.Result[0].PetsInstruction == null ? '' : res.data.Result[0].PetsInstruction == '0' ? '' : res.data.Result[0].PetsInstruction);
        setOtherInstructions(res.data.Result[0].OtherInstructions == null ? '' : res.data.Result[0].OtherInstructions == '0' ? '' : res.data.Result[0].OtherInstructions);
      })
  }

  return (
    <React.Fragment>
      <div className="modal fade" id="paymentmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body">
              <div className="mainpaymentmodalbox">
                <div className="text-center">
                  <img src={mainpaymentloadingimg} className="paymentloadingimg"></img>
                  <p className="mainaymentbluetext">Hang Tight!</p>
                  <p className="mainpaymentmodaldetails">You are bring redirected to another page, it may takes upto 10 seconds</p>

                </div>
                <div className="text-center">
                  <div className="mainpaymentamountbox">
                    <p className="mainpaymentamounttext">Payment Of <span>1000$ </span> </p>
                    <p className="paymentiserviceid">Service Id #SR00000010</p>


                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="modal fade" id="paymentsuccessfully" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body">
              <div className="mainpaymentmodalbox">
                <div className="text-center">
                  <img src={paymentsuccessfully} className="paymentloadingimg"></img>
                  <p className="mainaymentbluetext">Payment Successful</p>
                  <p className="mainpaymentmodaldetails">Thank you for your billing</p>

                </div>
                <div className="text-center">
                  <div className="mainpaymentamountboxnew">
                    <p className="paymentsuccessfullyamount">1000$ </p>
                    <p className="paymentsuuceefullytext">Has Been Paid</p>
                    <p className="maininvoiceidname">Invoice : <span>#18061258831</span></p>


                  </div>
                </div>
                <hr className="mainhrmodapayment"></hr>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <p className="mainpaymentdetailstitle">Mode of Payment</p>

                    </div>

                  </div>
                  <div className="col-md-6">
                    <p className="mainpaymentsuccessdetails">Net Banking</p>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <p className="mainpaymentdetailstitle">Service ID</p>

                    </div>

                  </div>
                  <div className="col-md-6">
                    <p className="mainpaymentsuccessdetails">#SR0000010</p>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <p className="mainpaymentdetailstitle">Property ID</p>

                    </div>

                  </div>
                  <div className="col-md-6">
                    <p className="mainpaymentsuccessdetails">#261hs89348</p>
                  </div>
                  <div className="col-md-6 mainbtnfooterpayment">
                    <button type="button" className="btn btn-primary btnnexteditpm w-100 d-block m-0" >Back to Service</button>
                  </div>
                  <div className="col-md-6 mainbtnfooterpayment">
                    <button type="button" className="btn btn-primary btnnexteditlineblue w-100 d-block m-0">Dashboard </button>

                  </div>





                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="modal fade" id="cancelapayment" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body">
              <div className="mainpaymentmodalbox">
                <div className="text-center">
                  <img src={cancel} className="paymentloadingimg"></img>
                  <p className="mainaymentbluetext">Cancel Payment</p>
                  <p className="mainpaymentmodaldetails">Please confirm that you wish to cancel this transaction and will be charged. </p>

                </div>

                <div className="row">

                  <div className="col-md-6 mainbtnfooterpayment">
                    <button type="button" className="btn btn-primary btnnexteditpm w-100 d-block m-0" >Pay Now</button>
                  </div>
                  <div className="col-md-6 mainbtnfooterpayment">
                    <button type="button" className="btn btn-primary btnnexteditlinepm w-100 d-block m-0">Cancel Payment </button>

                  </div>





                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="modal fade" id="paymentfailed" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body">
              <div className="mainpaymentmodalbox">
                <div className="text-center">
                  <img src={failed} className="paymentloadingimg"></img>
                  <p className="mainaymentbluetext">Oh No, Your Payment Failed</p>
                  <p className="mainpaymentmodaldetails">Sorry, We are not able to process your payment at the moment </p>

                </div>

                <div className="row">

                  <div className="col-md-6 mainbtnfooterpayment">
                    <button type="button" className="btn btn-primary btnnexteditpm w-100 d-block m-0" >Try Again</button>
                  </div>
                  <div className="col-md-6 mainbtnfooterpayment">
                    <button type="button" className="btn btn-primary btnnexteditlineblue w-100 d-block m-0" data-bs-dismiss="modal">Close </button>

                  </div>





                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist align-items-center">
              <div className="col-md-6">
                <p className="addservicetextform">Service Details</p>
                <p className="mainsteptextform">Service Id: <span className="mainidsrvicepagetext">#SR0000001</span> </p>

              </div>
              <div className="col-md-6 text-end">
                <button type="button" className="btn btn-warning readmorebtnew border-0 mt-0 mb-0">Back</button>

              </div>


            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mainpropertylistboxsdedit">
                      <div className="d-flex align-items-center">
                        <div>
                          <div id="carouselExampleIndicators" className="carousel slide propertyimgslider propertyimgsliderpl" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                              {PropertyImageList.map((item, idx) => (
                                idx == 0 ?
                                  <button type="button" data-bs-target="#carouselExampleIndicators" key={idx} data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                                  :
                                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>
                              ))}
                            </div>
                            <div className="carousel-inner">
                              {PropertyImageList.map((item, idx) => (
                                <div className={item.SclassName} key={idx}>
                                  <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                                </div>
                              ))}
                            </div>

                          </div>


                        </div>
                        <div className="w-100">
                          <div className="d-flex justify-content-between w-100">
                            <p className="mainpropertytitlelist">{PropertyName}</p>
                            <div className="">
                              <a href="#"> <p className="mainviewpropertytext">View</p></a>

                            </div>

                          </div>
                          <div>
                            <p className="propertycatageoried">{PropertyType} </p>
                          </div>
                          <div className="d-flex">
                            <div>
                              <img src={locationpropertyedit} className="locationpropertyedit" />
                            </div>
                            <div>
                              <p className="propertytextlocation mb-0">{FullAddress}</p>
                            </div>

                          </div>
                          <div className="maindividerpd"> </div>
                          <div>
                            <p className="landareatext">Land Area :</p>
                            <div className="d-flex">
                              <div className="margineditlandarea">
                                <p className="mainlandareatextdescriptionnew2"> <img src={landarea1} /> {LandArea} {LandAreaIn}</p>
                              </div>
                              <div>
                                <p className="mainlandareatextdescriptionnew2"> <img src={landarea2} /> {SuperBuiltUP} {SuperBuiltUpIn}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mianservicedetailseditboxe">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="maintitlereuestservicenee">Service Detail</p>

                        </div>
                        <div className="col-md-12">
                          <div className="d-flex">
                            <div>
                              {ServiceMediaList.map((item, idx) => (
                                idx == 0 ?
                                  <img key={idx} src={item.Url} className="mainnewwwimgsericedetails" />
                                  : ''
                              ))}
                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between w-100">
                                <p className="mainpropertytitlelist">{ServiceName}</p>

                              </div>
                              <div>
                                <p className="propertycatageoried">{ServiceTypeName}</p>
                              </div>
                              <div className="servicedetailstagesedit">
                                <button className="greenbtn btn">{KeyStatus}</button>
                              </div>

                            </div>
                          </div>

                        </div>
                        <div className="col-md-12">
                          <hr />

                        </div>
                        <div className="col-md-12">
                          <div>
                            {ServiceDescriptionList.map((item, idx) => (
                              <div className="maindetailcheckmemberstepsdnew2" key={idx}>
                                <div>
                                  <i className="fas fa-check text-success"></i>
                                </div>
                                <div>
                                  <p> {item.Bullets}</p>
                                </div>
                              </div>
                            ))}
                          </div>

                        </div>
                        <div className="col-md-12">
                          <hr />

                        </div>
                        <div className="col-md-12">
                          <div>
                            <p className="smallblutextedirsd">Property Specification</p>
                            {PetsInstruction != '' ? <div className=" maindetailcheckmemberstep2sd dotseditline">
                              <div>
                                <i className="fas fa-circle"></i>
                              </div>
                              <div>
                                <p>{PetsInstruction}</p>
                              </div>
                            </div> : ''}
                            {OtherInstructions != '' ? <div className=" maindetailcheckmemberstep2sd dotseditline">
                              <div>
                                <i className="fas fa-circle"></i>
                              </div>
                              <div>
                                <p>{OtherInstructions}</p>
                              </div>
                            </div> : ''}

                          </div>

                        </div>


                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mianservicedetailseditboxe">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="maintitlereuestservicenee">Service Detail</p>

                        </div>
                        <div className="col-md-12">
                          <div className="rightbox">
                            <div className="rb-container">
                              <ul className="rb">
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Your Service Request has been initiated
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>

                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Accepted By XXX
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>

                                </li>

                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Ready For Inspection
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>

                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Query Generated
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>
                                  <div className="mainquerygeneratedbox">
                                    <p className="mainquetboxtext">Autem tempora repellat ullam. Cum explicabo qui. Hic vel consequatur ut id totam.</p>
                                    <div className="item-title text-end"> 01:00 PM</div>

                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <button type="button" className="btn btn-primary mt-0 mb-0 btnnextedit d-block w-100 mainbtnnewfilee" ><i className="las la-file-alt"></i>Testing File.pdf
                                        <div className="maindeltefileboxx"><i className="las la-download"></i></div>
                                      </button>

                                    </div>

                                  </div>


                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Inspection Done
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>

                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <div className="timestamp">
                                        Advance Payment
                                      </div>
                                      <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>
                                    </div>
                                    <div className="text-end w-100">
                                      <button type="button" className="btn btn-success btnmainpaynow" data-bs-toggle="modal" data-bs-target="#paymentsuccessfully">Pay Now</button>
                                      <button type="button" data-bs-toggle="modal" data-bs-target="#cancelapayment" className="btn btn-outline-success cancelgreenbtn">Cancel</button>
                                    </div>
                                  </div>


                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Team Assigned
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mainteamassignbtn">
                                        <img src={teamassign} className="mainteamassignimg"></img>
                                        Omer Fadel
                                      </div>

                                    </div>

                                  </div>

                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    In Progress
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>

                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="timestamp">
                                    Job Completed
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>

                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <div className="timestamp">
                                        Balance Payment
                                      </div>
                                      <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>
                                    </div>
                                    <div className="text-end w-100">
                                      <button type="button" className="btn btn-success btnmainpaynow">Pay Now</button>
                                      <button type="button" className="btn btn-outline-success cancelgreenbtn" data-bs-toggle="modal" data-bs-target="#paymentfailed">Cancel</button>
                                    </div>
                                  </div>


                                </li>
                                <li className="rb-item complatedactive" ng-repeat="itembx">

                                  <div className="timestamp">
                                    Review Services
                                  </div>
                                  <div className="item-title">Mar 19, 2022 <span>|</span> 01:00 PM</div>
                                  <div className="rating mainservicedetailstar" data-vote="0">

                                    <div className="star hidden">
                                      <span className="full" data-value="0"></span>
                                      <span className="half" data-value="0"></span>
                                    </div>

                                    <div className="star">

                                      <span className="full" data-value="1"></span>
                                      <span className="half" data-value="0.5"></span>
                                      <span className="selected"></span>

                                    </div>

                                    <div className="star">

                                      <span className="full" data-value="2"></span>
                                      <span className="half" data-value="1.5"></span>
                                      <span className="selected"></span>

                                    </div>

                                    <div className="star">

                                      <span className="full" data-value="3"></span>
                                      <span className="half" data-value="2.5"></span>
                                      <span className="selected"></span>

                                    </div>

                                    <div className="star">

                                      <span className="full" data-value="4"></span>
                                      <span className="half" data-value="3.5"></span>
                                      <span className="selected"></span>

                                    </div>

                                    <div className="star">

                                      <span className="full" data-value="5"></span>
                                      <span className="half" data-value="4.5"></span>
                                      <span className="selected"></span>

                                    </div>


                                  </div>
                                  <div className="mb-0 mainwritereviewbox">

                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                                    <i className="las la-paper-plane mainreviewsendbtn"></i>

                                  </div>



                                </li>


                              </ul>

                            </div>
                          </div>
                        </div>



                      </div>
                    </div>

                  </div>

                </div>

              </div>


            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default servicedetail
