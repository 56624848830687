import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Input,
  InputGroup,
  Label,
} from "reactstrap"

// Carousel
import Slide from "./CarouselTypes/slide"
import Slidewithcontrol from "./CarouselTypes/slidewithcontrol"
import Slidewithindicator from "./CarouselTypes/slidewithindicator"
import Slidewithcaption from "./CarouselTypes/slidewithcaption"
import Slidewithfade from "./CarouselTypes/slidewithfade"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>Company Add/Edit | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-2">Company - Add/Edit </CardTitle>
                 </div>
                  <div className ="d-flex logo-main">
                   <div className="logo-box">
                      <p className="mb-0">LOGO</p>
                   </div>
                    <div className="icon-box">
                  <a href="">  <i className="mdi mdi-pencil"></i></a>
                    </div>
                  </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6 mb-4">Main Branch Detail</CardTitle>
                     <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Address Line 1"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Address Line 2"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Near By"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Area"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Zipcode"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>City</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        
                        <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>State</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Country</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Primary Email"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Alternet Email"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Primary Contact Number"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Alternet Contact No."
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Official Website"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                      <Col xl={12} sm={6}>
                        <div className="mt-4 mb-4 d-flex align-items-center">
                        <h5 className="font-size-14">Employer Contribution</h5>
                          <div className="form-check ms-5">
                          
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                             Yes
                            </label>
                          </div>
                          <div className="form-check ms-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                             No
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company PF No"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Company ESI No"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                        <div  className="form-check mb-3">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                        </Col>
                        <Col className="col-12 mt-4">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>
                     
                               <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiCarousel
