import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "../frontend/header";
import { ToastProvider, useToasts } from 'react-toast-notifications';


const addPropertyPage3 = () => {

  const { addToast } = useToasts();
  const [PropertyDocumentList, setPropertyDocumentList] = useState([]);
  const [DocumentMedia1, setDocumentMedia1] = useState([]);
  let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
  var VPropertyId = JSON.parse(localStorage.getItem('PropertyId'));
  const [PropertyImageList, setPropertyImageList] = useState([]);
  const [PropertyName, setPropertyName] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [FullAddress, setFullAddress] = useState("");

  const addPropertyDocuments = () => {

    var VDocumentName = document.getElementById('txtDocumentName').value;
    var IsInsert = 1;
    if (DocumentMedia1 == "") {
      addToast("Add Document", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (VDocumentName == "") {
      addToast("Add Document Name", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert > 0) {
      const formData = new FormData();

      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('TotalMediaCount', PropertyDocumentList.length + 1);
      formData.append('PropertyId', VPropertyId);
      formData.append('DocumentMedia1', DocumentMedia1);
      formData.append('DocumentName1', VDocumentName);

      for (let di = 0; di < PropertyDocumentList.length; di++) {

        let RCount = di + 2;
        let VDocumentName = PropertyDocumentList[di].DocumentName;
        let VDocumentMedia = PropertyDocumentList[di].DocumentFile;

        let CD = 'DocumentName' + RCount;
        let CM = 'DocumentMedia' + RCount;

        formData.append(CD, VDocumentName);
        formData.append(CM, VDocumentMedia);


      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }

      axios.post(global.APIURL + '/PropertyDocuments', formData)
        .then(res => {
          console.log("add3-res", res);
          alert(res.data.StatusMessage)
          window.location.href = "/property-list";
        });
    }

  }

  const propertyDocumentsHandleAddRow = () => {

    var LRNo = PropertyDocumentList.length == 0 ? 1 : (PropertyDocumentList[PropertyDocumentList.length - 1].PId + 1);
    var VDocumentName = document.getElementById("txtDocumentName").value;

    const Ditem = {
      PId: LRNo,
      DocumentName: VDocumentName,
      DocumentNameTextBoxControlId: 'txtDocumentName' + LRNo,
      DocumentFile: DocumentMedia1,
      DocumentFileName: DocumentMedia1.name
    }
    setPropertyDocumentList([...PropertyDocumentList, Ditem]);
    document.getElementById("txtDocumentName").value = "";
  }

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var VPropertyId = JSON.parse(localStorage.getItem('PropertyId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    }
    else if (VPropertyId == null) {
      window.location.href = "/add-new-property";
    }
    else {
      getPropertyDetail();
    }

  }, []);

  
  const getPropertyDetail = async () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var PId = JSON.parse(localStorage.getItem('PropertyId'));
    let VSelectPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    const PropertyDetailArr = {
      RegisterId: VRegisterId,
      PropertyId: VSelectPropertyId
    };

    axios.post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {
        setPropertyName(res.data.Result[0].PropertyTitle);
        setPropertyType(res.data.Result[0].PropertyTypeName);
        setFullAddress(res.data.Result[0].FullAddress)
        var PropImageList = res.data.Result[0].Media;
        var PIArr = [];
        for (let pi = 0; pi < PropImageList.length; pi++) {
          const PropImageUrl = PropImageList[pi].Url;
          const PropImageitem = {
            RId: pi,
            SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
            SliderName: 'Slider ' + (pi + 1),
            PropImageUrl: PropImageUrl
          };
          PIArr.push(PropImageitem);
        }
        setPropertyImageList(PIArr);
      })
  }


  return (
    <React.Fragment>

      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row">
              <div className="col-md-12">
                <p className="addservicetextform">Add New Properties</p>

              </div>

              <div className="col-md-12">
                <p className="mainsteptextform">Step 3 : Documents</p>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="propertysteptwomainbox">
                  <div className="d-flex">
                    <div>
                    <div id="carouselExampleIndicators" className="carousel slide propertyimgslider  propertyimgslidernewimgedit" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        {PropertyImageList.map((item, idx) => (
                          idx == 0 ?
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                            :
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>
                        ))}
                        {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                      </div>
                      <div className="carousel-inner">
                        {PropertyImageList.map((item, idx) => (
                          <div className={item.SclassName} key={idx}>
                            <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                          </div>
                        ))}
                      </div>
                    </div>
                    </div>
                    <div className="w-100">
                    <div className="d-flex w-100">
                      <p className="mainpropertytitlelist">{PropertyName} </p>
                      <div>

                        <img className="verifiedgreenstep2" src={verifiedgreen} />
                      </div>
                    </div>
                    <div>
                      <p className="propertycatageoried">{PropertyType}</p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation">{FullAddress}</p>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div>
                    <div className="deletprpertyadd">
                      <i className="las la-trash-alt"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mainboxnewaddproperty ">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="maintitlereuestservicenee">Documents</p>
                    </div>
                    <div className="col-md-6 text-end">
                      <a onClick={propertyDocumentsHandleAddRow}  className="addnewpropertytextneww"> <i className="las la-plus-circle"></i>Add New Document</a>
                    </div>
                  </div>
                  {PropertyDocumentList.map((item, idx) => (<div className="row mainaddnewinsightalldiv" key={idx}>
                    <div className="col-md-10">
                      <div className="row align-items-center ">
                        <div className="col-md-1">
                          <img src={deletedash} className="deletedashimg"></img>
                        </div>
                        <div className="col-md-8">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id={item.DocumentNameTextBoxControlId} defaultValue={item.DocumentName} placeholder="write here" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="">
                            <input type="file" name="file" id="file" className="file" />
                            <div className="mainfileuploadimgwith">
                              <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" value={item.DocumentFileName} />
                              <div className="maindivdeletprpertyaddnewsmall">
                                <div className="deletprpertyaddnewsmall">
                                  <i className="las la-trash-alt"></i>
                                </div>
                              </div>
                              <div className="filnamewithiconmain">
                                <i className="las la-file"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="col-md-2 text-end">
                      <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                    </div>

                  </div>))}
                  <div className="row mainaddnewinsightalldiv">
                    <div className="col-md-10">
                      <div className="row align-items-center ">
                        <div className="col-md-1">
                          <img src={deletedash} className="deletedashimg"></img>
                        </div>
                        <div className="col-md-8">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id="txtDocumentName" placeholder="write here" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="">
                            <input type="file" name="file" id="file" className="file" />
                            <div className="mainfileuploadimgwith">
                              <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                              <div className="maindivdeletprpertyaddnewsmall">
                                <div className="deletprpertyaddnewsmall">
                                  <i className="las la-trash-alt"></i>
                                </div>
                              </div>
                              <div className="filnamewithiconmain">
                                <i className="las la-file"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 text-end">
                      <a href="#" id="upfile1"> <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button></a>
                      <input type="file" id="file1" className="d-none" onChange={(e) => setDocumentMedia1(e.target.files[0])} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <form>
                    <div className="form-group newwform-group">
                      <input type="checkbox" id="html" />
                      <label htmlFor="html">By ticking, you are confirming that you have read, understood and agree to the <a href="#" className="mainlineatagee">Terms and conditions</a>  and <a className="mainlineatagee" href="#">privacy policy</a> </label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12">
                <button type="button" className="btn btn-primary btnnextedit " onClick={addPropertyDocuments}>Next <i className="fas fa-long-arrow-alt-right"></i></button>
                <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Save as Draft </button></a>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}


export default addPropertyPage3
