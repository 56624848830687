import React, { Component, useEffect, useState } from "react"
import axios from "axios";
import Commheader from "../frontend/header";
import referfriendimg from "../../assets/images/frontend-img/referfriendimg.png";
import { ToastProvider, useToasts } from 'react-toast-notifications';

const referralslist = () => {
  const [ReferList, setReferList] = useState([]);
  const { addToast } = useToasts();
  const [Name, setName] = useState('');
  const [EmailAddress, setEmailAddress] = useState('');
  const [MobileNumber, setMobileNumber] = useState('');
  const [Message, setMessage] = useState('');
  const [ShowLoader, setShowLoader] = useState(0);

  useEffect(() => {
    setShowLoader(1);
    getMyReferralsList();
  }, []);

  const getMyReferralsList = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/ReferedHistory', formData)
      .then(LPres => {
        let LCD = LPres.data.Result.map((lcdeatail) => {
          return lcdeatail
        })
        setReferList(LCD);
      });

      setShowLoader(0);
  }

  const DeleteDetail = (e, Id) => {
    setShowLoader(1);
    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    formData.append('RegisterId', VRegisterId);
    formData.append('ReferId', Id);

    axios.post(global.APIURL + '/DeleteReferedFriend', formData).then(res => {
      alert(res.data.StatusMessage);
      getMyReferralsList();
    });
  };

  const SaveNewReferrals = async () => {
    let IsInsert = 1;
    if (Name == null) {
      addToast("Please input name.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (Name == '') {
      addToast("Please input name.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (EmailAddress == null) {
      addToast("Please input email address.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (EmailAddress == '') {
      addToast("Please input email address.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (MobileNumber == null) {
      addToast("Please input mobile number.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (MobileNumber == '') {
      addToast("Please input mobile number.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (Message == null) {
      addToast("Please input message.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (Message == '') {
      addToast("Please input message.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert > 0) {
      const formData = new FormData();
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
      formData.append('RegisterId', VRegisterId);
      formData.append('Name', Name);
      formData.append('EmailAddress', EmailAddress);
      formData.append('MobileNumber', MobileNumber);
      formData.append('Message', Message);

      axios.post(global.APIURL + '/ReferFriend', formData).then(res => {
        alert(res.data.StatusMessage);
        getMyReferralsList();
      });
    }
  }

  return (
    <React.Fragment>
       {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <div className="modal fade" id="referfriend" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mainrefermodal">
          <div className="modal-content">

            <div className="modal-body">
              <div className="maindivreferefriend">
                <div className="text-center">
                  <p className="lognmaintexteditnew">Refer a Friend</p>
                  <p className="maindescriptiontextlogin">Invite your friend, they hit the road and you make savings!</p>
                  <img className="mainreferfriengimgedit" src={referfriendimg}></img>

                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                      <input type="text" className="form-control" id="txtName" placeholder="Enter Name" onChange={e => setName(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-12">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                      <input type="text" className="form-control" id="txtEmailAddress" placeholder="Enter Email Address" onChange={e => setEmailAddress(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-12">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                      <input type="text" className="form-control" id="txtMobileNumber" placeholder="Enter Mobile Number" onChange={e => setMobileNumber(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-12">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                      <textarea type="textarea" rows={3} className="form-control" id="txtMessage" placeholder="Enter Message" onChange={e => setMessage(e.target.value)}></textarea>
                    </div>

                  </div>
                  <div className="col-md-12 mainreferbtn text-center">
                    <button type="button" className="btn btn-primary btnloginnew" data-bs-dismiss="modal" aria-label="Close" onClick={SaveNewReferrals}>Refer Now</button>
                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-6">
                <p className="addservicetextform">History of your Referrals</p>

              </div>
              <div className="col-md-6 text-end">
                <a href="#" data-bs-toggle="modal" data-bs-target="#referfriend"> <p className="addnewpropertytext"> <i className="las la-ticket-alt"></i>Refer a Friend Now</p></a>

              </div>


            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="maintabledivedit">
                  <table className="table rftable">
                    <thead>
                      <tr>
                        <th className="mainwidthtablerf2" scope="col">#</th>
                        <th className="mainwidthtablerf" scope="col">Name</th>
                        <th className="mainwidthtablerf" scope="col">Number</th>
                        <th className="mainwidthtablerf" scope="col">Email</th>
                        <th className="mainwidthtablerf" scope="col">Message</th>
                        <th className="mainwidthtablerf" scope="col">Date & Time</th>
                        <th className="mainwidthtablerf2" scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ReferList.map((item, idx) => (
                        <tr key={idx}>
                          <td>  <p className="mainpropertytitlelistms">{idx + 1}</p></td>
                          <td>  <p className="mainpropertytitlelistms">{item.Name}</p></td>
                          <td>  <p className="mainpropertytitlelistms">{item.MobileNumber}</p></td>
                          <td>  <p className="mainpropertytitlelistms">{item.EmailAddress}</p></td>
                          <td>  <p className="mainpropertytitlelistms">{item.Message}</p></td>
                          <td>  <p className="mainpropertytitlelistms">{item.Date} <span>|</span> {item.Time}</p></td>
                          <td>
                            <div className="maindeletelistrefer">
                              <div>
                                <a onClick={e => DeleteDetail(e, item.Id)}><i className="las la-trash-alt"></i></a>
                              </div>

                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )

}

export default referralslist
