import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { Card, CardBody, CardTitle, Col, Container, Row, Input, } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

class UiRangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: 15,
      min_max: 70,
      step: 25,
      prefix: 50,
      postfix: 85,
      custom_val: 5,
      handleLabel: 10,
      float_val: 55.5,
      extra: 52,
      hide: 5,
      labels: {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
    };
  }

  render() {
    const formatkg = value => "$ " + value;
    const formatdollar = value => value + " kg";
    const extra_age = value => value + " Age";
    return (
      <React.Fragment>
         <div className="page-content">
          <MetaTags>
            <title>User Account | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">  
          <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6">Leave Management</CardTitle>
                     
                      <Row className="border-bottom">
              
              <Col className="col-12 mt-3">
                <Card>
                  <CardBody>
                     {/* User list */}
                  
               <div className="table-responsive">
               <table className="table table-borderless">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Leave Type</th>
                          <th scope="col" className="">Current Credits</th>
                          <th scope="col" className="">Used Credits</th>
                          <th scope="col" className="">Adjustment</th>
                          <th scope="col" className=""></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="user-td">1</th>
                          <td>Casual Leave(CL)</td>
                          <td>10</td>
                          <td>2</td>
                          <td>-</td>
                          <td>
                          <a className ="btn btn-primary btn-sm" href="/">Save</a>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>

            <Row className="mt-4">
                          <Col className="col-12">
                              <div className ="d-flex justify-content-end">
                                <div className="border-bottom">
                                        From - To<i className="mdi mdi-calendar-month h4 ms-3"></i>
                                </div>
                                <div className="border-bottom ms-4">
                                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                                </div>
                              </div>
                          </Col>
                      </Row>


                      <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                  
               <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Leave Type</th>
                          <th scope="col" className="">Total Duration</th>
                          <th scope="col" className="">Date</th>
                          <th scope="col" className="">Status</th>
                          <th scope="col" className="">HR Status</th>
                          <th scope="col" className="">Manager Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="user-td">1</th>
                          <td>CL 
                            <p className="mt-1 mb-0">(Full)</p>
                          </td>
                          <td>1</td>
                          <td>1/12/2020</td>
                          <td>Pending For Approval</td>
                          <td>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Pending</option>
                              <option>...</option>
                            </select>
                          </div>
                          </td>
                          <td>Approved</td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">2</th>
                          <td>LWP<p className="mt-1 mb-0">(Half)</p></td>
                          <td>0.5</td>
                          <td>1/12/2020
                          <p className="mt-1 mb-0">2nd Half</p>
                          </td>
                          <td>Pending For Approval</td>
                          <td>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Pending</option>
                              <option>...</option>
                            </select>
                          </div>
                          </td>
                          <td>Approved</td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">3</th>
                          <td>CL 
                            <p className="mt-1 mb-0">(Full)</p>
                          </td>
                          <td>1</td>
                          <td>1/12/2020
                            <p className="mb-1 mt-1">1/12/2020</p>
                            <p className="mb-1">1/12/2020</p>
                          </td>
                          <td>Pending For Approval</td>
                          <td>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Pending</option>
                              <option>...</option>
                            </select>
                          </div>
                          </td>
                          <td>Rejected<td>
                            <p className="mt-1 mb-0">(Remarks)</p>
                          </td></td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
                      
                      {/* <Row>
                        <Col lg={4}>
                        <div  className="form-check mb-3">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                        </Col>
                        <Col className="col-12 mt-4">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row> */}
                     
                               <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiRangeSlider;
