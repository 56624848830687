import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";


import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";

import referfriendimg from "../../assets/images/frontend-img/referfriendimg.png";
import axios from "axios";
import Commheader from "../frontend/header";
import { useToasts } from 'react-toast-notifications';




const DashboardNew = () => {
  const [MembershipDetailsList, setMembershipDetailsList] = useState([]);
  const [TopPropertiesList, setTopPropertiesList] = useState([]);
  const [RecentServicesList, setRecentServicesList] = useState([]);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [EmailVerified, setEmailVerified] = useState('No');
  const [MobileVerified, setMobileVerified] = useState('No');
  const [PropertiesCount, setPropertiesCount] = useState('');
  const [ServiceCount, setServiceCount] = useState('');
  const [TotalSpentAmount, setTotalSpentAmount] = useState('');
  const [ShowLoader, setShowLoader] = useState(0);
  const { addToast } = useToasts();

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getProfileData();
    }
  }, []);

  const getProfileData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        setEmail(res.data.Result[0].UserEmail);
        setName(res.data.Result[0].UserName);
        setMobileNo(res.data.Result[0].UserNumber)
        setEmailVerified(res.data.Result[0].IsEmailVerified)
        setMobileVerified(res.data.Result[0].IsNumberVerified)
        setPropertiesCount(res.data.Result[0].PropertiesCount)
        setServiceCount(res.data.Result[0].ServiceCount)
        setTotalSpentAmount(res.data.Result[0].TotalSpentAmount)
        setMembershipDetailsList(res.data.Result[0].MembershipDetail)
        setRecentServicesList(res.data.Result[0].RecentService)

        var RecentProperty = res.data.Result[0].RecentProperty;
        let TopPropertiesArr=[];
        for (let rp = 0; rp < RecentProperty.length; rp++) {
          const ProImgArr = RecentProperty[rp].Images;
          var PropertyImagesArr = [];
          for (let pi = 0; pi < ProImgArr.length; pi++) {
            const Url = ProImgArr[pi].Url;
            const PropImageitem = {
              RId: pi,
              SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
              SliderName: 'Slider ' + (pi + 1),
              PropImageUrl: Url
            };
            PropertyImagesArr.push(PropImageitem);
          }
          const RecentPropertyitem = {
            PropertyId: RecentProperty[rp].PropertyId,
            PropertyName: RecentProperty[rp].PropertyName,
            PropertyType: RecentProperty[rp].PropertyType,
            FullAddress: RecentProperty[rp].FullAddress,
            Latititude: RecentProperty[rp].Latititude,
            Longitude: RecentProperty[rp].Longitude,
            LandArea: RecentProperty[rp].LandArea,
            BuiltUpaArea: RecentProperty[rp].BuiltUpaArea,
            PropertyImages: PropertyImagesArr
          };
          TopPropertiesArr.push(RecentPropertyitem);
        }
        setTopPropertiesList(TopPropertiesArr);
      });
  }
  const sendMobileOTP = async () => {
    setShowLoader(1);
    const formData = new FormData();

    formData.append('MobileNumber', MobileNo);
    formData.append('Email', "");
    formData.append('OTPType', "Mobile");
    formData.append('DeviceInfo', "");

    axios.post(global.APIURL + '/SendOTP', formData)
      .then(res => {
        console.log("send-mobile-api", res.data.OTP);
      });
    setShowLoader(0);
  }

  const sendEmailOTP = async () => {
    setShowLoader(1);
    const formData = new FormData();

    formData.append('MobileNumber', "");
    formData.append('Email', Email);
    formData.append('OTPType', "Email");
    formData.append('DeviceInfo', "");

    axios.post(global.APIURL + '/SendOTP', formData)
      .then(res => {
        console.log("Send-email-resapi", res.data.OTP);
      });
    setShowLoader(0);
  }
  const verifyEmailOTP = async () => {

    setShowLoader(1);
    let VOTP1 = document.getElementById("OTP5").value;
    let VOTP2 = document.getElementById("OTP6").value;
    let VOTP3 = document.getElementById("OTP7").value;
    let VOTP4 = document.getElementById("OTP8").value;

    const OTP = VOTP1 + VOTP2 + VOTP3 + VOTP4;
    const formData = new FormData();

    formData.append('MobileNumber', "");
    formData.append('Email', Email);
    formData.append('OTPType', "Email");
    formData.append('OTP', OTP);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ':' + pair[1]);
    }

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        console.log("verifyemailotp", res);
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $('#MPEmailAddressVerification').modal('hide');
          setEmailVerified('Yes');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          setEmailVerified('No');
        }
      });
    setShowLoader(0);
  }

  const verifyMobileOTP = async () => {
    setShowLoader(1);
    let VOTP1 = document.getElementById("OTP1").value;
    let VOTP2 = document.getElementById("OTP2").value;
    let VOTP3 = document.getElementById("OTP3").value;
    let VOTP4 = document.getElementById("OTP4").value;

    const OTP = VOTP1 + VOTP2 + VOTP3 + VOTP4;

    const formData = new FormData();
    formData.append('MobileNumber', MobileNo);
    formData.append('Email', "");
    formData.append('OTPType', "Mobile");
    formData.append('OTP', OTP);

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        console.log("verifymobile", res);
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $('#MPMobileVerification').modal('hide');
          setMobileVerified('Yes');
        }
        else{
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          setMobileVerified('Yes');
        }
      });
    setShowLoader(1);
  }


  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader" >
        <div className="mainloadernew">
          <div className="spinner-border text-primary mainloderedit" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      : ''}
    <div className="modal fade" id="referfriend" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg mainrefermodal">
        <div className="modal-content">

          <div className="modal-body">
            <div className="maindivreferefriend">
              <div className="text-center">
                <p className="lognmaintexteditnew">Refer a Friend</p>
                <p className="maindescriptiontextlogin">Invite your friend, they hit the road and you make savings!</p>
                <img className="mainreferfriengimgedit" src={referfriendimg}></img>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Name" />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Email Address" />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Mobile Number" />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Write Here" />
                  </div>

                </div>
                <div className="col-md-12 mainreferbtn text-center">
                  <button type="button" className="btn btn-primary btnloginnew">Refer Now</button>
                </div>

              </div>


            </div>

          </div>

        </div>
      </div>
    </div>
    <div className="modal fade" id="MPMobileVerification" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="verificationeditext">
              Verification
            </p>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={otpicon} ></img>
            </div>
            <div>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Mobile Number Verification</p>
                </div>
                <input className="otp mr1vw" type="text" maxLength='1' id="OTP1" />
                <input className="otp mr1vw" type="text" on maxLength='1' id="OTP2" />
                <input className="otp mr1vw" type="text" maxLength='1' id="OTP3" />
                <input className="otp" type="text" maxLength='1' id="OTP4" />

              </form>

              <div className="text-center">
                <button type="button" className="btn btn-primary btnnextedit " onClick={verifyMobileOTP} >Continue <i className="fas fa-long-arrow-alt-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="MPEmailAddressVerification" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="verificationeditext">
              Verification
            </p>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={otpicon} ></img>
            </div>
            <div>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Email Address Verification</p>
                </div>
                <input className="otp mr1vw" onInput='digitValidate(this)' onKeyUp='tabChange(1)' type="text" maxLength='1' id="OTP5" />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(2)' on maxLength='1' id="OTP6" />
                <input className="otp mr1vw" onInput='digitValidate(this)' onKeyUp='tabChange(3)' type="text" maxLength='1' id="OTP7" />
                <input className="otp" onInput='digitValidate(this)' onKeyUp='tabChange(4)' type="text" maxLength='1' id="OTP8" />
              </form>
              <div className="text-center">
                <button type="button" className="btn btn-primary btnnextedit " onClick={verifyEmailOTP} >Continue <i className="fas fa-long-arrow-alt-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Commheader />
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-12">
              <p className="maindashboardtextedit">
                Dashboard
              </p>
            </div>
            <div className="col-md-3">
              <div className="mainbluboxdleft">
                <p className="hellomartinetext">Hello {Name}</p>
                <div className="dividerdashleft"></div>
                <div className="mainverifiedbox">
                  <div>
                    <p className="mainverifiedline"> <i className="las la-envelope"></i> {Email}</p>
                  </div>
                  {EmailVerified != 'No' ?
                    <div>
                      <img src={verified} className="verifiedimgedit" />
                    </div> : <a className="text-success" data-bs-toggle="modal" data-bs-target="#MPEmailAddressVerification" onClick={sendEmailOTP} >Verify<i className="las la-angle-right"></i></a>
                  }

                </div>
                <div className="mainverifiedbox mb-0">
                  <div>
                    <p className="mainverifiedline mb-0"><i className="las la-phone"></i>{MobileNo}</p>
                  </div>
                  {MobileVerified != 'No' ?
                    <div>
                      <img src={verified} className="verifiedimgedit" />
                    </div> : <a className="text-success" data-bs-toggle="modal" data-bs-target="#MPMobileVerification" onClick={sendMobileOTP} >Verify<i className="las la-angle-right"></i></a>

                  }


                </div>


              </div>
              <div className="maingrayboxedit">
                <p className="membershiptext">Membership Details</p>
                {MembershipDetailsList.map((item, idx) => (
                  <p className="maindetailcheckmember" key={idx}><i className="fas fa-check"></i>{item.Description}</p>
                ))}
                <button type="button" className="btn btn-danger loginbtnfooter mt-0">Upgrade</button>

              </div>
              <div className="mainbluboxdleft2">
                <p className="hellomartinetext2">Service Board</p>
                <div className="dividerdashleft2"></div>
                {/* {TopPropertiesList.map((item, idx) => (
                  <>
                  
                  </>
                ))} */}

              </div>
              <div className="mainrequestbox">
                <img src={shapleftrequest} className="shapleftrequest" />
                <img src={shaprightrequest} className="shaprightrequest" />

                <div className="d-flex">
                  <div>
                    <i className="las la-plus-circle mainrequesttitleicon"></i>
                  </div>
                  <div>
                    <p className="mainrequesttitle">Request New Service</p>
                    <p className="mainrequestdescription">Blanditiis harum assumenda amet in non quisquam eum voluptate.</p>
                  </div>
                </div>

              </div>

            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4">
                  <div className="mainboxshadowthreebox">
                    <div className="threeboxcontent">
                      <div className="text-center">
                        <p className="mainthrreboxcount">{PropertiesCount}</p>
                        <p className="maintitlethrrebox">Properties</p>
                      </div>
                      <div>
                        <img className="mainthreeboximg" src={propertyiconedit} />
                      </div>

                    </div>
                    <div className="threeboxboottomcontent">
                      <a href="/add-new-property">  <p className="textboxthreecontent"> <i className="las la-plus-circle bootomplusicon"></i>Add New Properties</p></a>
                    </div>

                  </div>

                </div>
                <div className="col-md-4">
                  <div className="mainboxshadowthreebox">
                    <div className="threeboxcontent">
                      <div className="text-center">
                        <p className="mainthrreboxcount">{ServiceCount}</p>
                        <p className="maintitlethrrebox">Services</p>
                      </div>
                      <div>
                        <img className="mainthreeboximg" src={serviceiconedit} />
                      </div>

                    </div>
                    <div className="threeboxboottomcontent">
                      <a href="/request-new-service">  <p className="textboxthreecontent"> <i className="las la-plus-circle bootomplusicon"></i>Add New Services Request</p></a>
                    </div>

                  </div>

                </div>
                <div className="col-md-4">
                  <div className="mainboxshadowthreebox">
                    <div className="threeboxcontent">
                      <div className="text-center">
                        <p className="mainthrreboxcount">$ {TotalSpentAmount}</p>
                        <p className="maintitlethrrebox">Spent</p>
                      </div>
                      <div>
                        <img className="mainthreeboximg" src={spenticonedit} />
                      </div>

                    </div>
                    <div className="threeboxboottomcontent">
                      {/* <a href="#">  <p className="textboxthreecontent"> <i className="las la-eye bootomplusicon"></i>View</p></a> */}
                      <p className="textboxthreecontent"> <i className="las la-eye bootomplusicon"></i>View</p>
                    </div>

                  </div>

                </div>

              </div>
              <div className="mainboxxshadoweditnew">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pointfiveedit">
                      <p className="maindashboardboxtitlecommon"> Top Properties</p>
                    </div>

                  </div>

                </div>
                <div className="mainboxespropertyedit">
                  <div className="row">
                    {TopPropertiesList.map((item, idx) => (
                      idx < 3 ?
                        <div className="col-md-4" key={idx}>
                          <div className="propertyboxeditindi">
                            <div>
                              <div id="carouselExampleIndicators" className="carousel slide propertyimgslider" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                  {item.PropertyImages.map((item, idx) => (
                                    idx == 0 ?
                                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                                      :
                                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>
                                  ))}
                                </div>
                                <div className="carousel-inner">
                                  {item.PropertyImages.map((item, idx) => (
                                    <div className={item.SclassName} key={idx}>
                                      <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                                    </div>
                                  ))}
                                </div>

                              </div>


                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between w-100">
                                <p className="mainpropertytitlelist">{item.PropertyName}</p>
                                <div>

                                  <img className="verifiedgreen" src={verifiedgreen} />
                                </div>
                              </div>
                              <div>
                                <p className="propertycatageoried">{item.PropertyType}</p>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <img src={locationpropertyedit} className="locationpropertyedit" />
                                </div>
                                <div>
                                  <p className="propertytextlocation">{item.FullAddress}</p>
                                </div>

                              </div>
                            </div>


                          </div>

                        </div>
                        : ''
                    ))}
                  </div>
                </div>

              </div>
              <div className="mainboxxshadoweditnew">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pointfiveedit">
                      <p className="maindashboardboxtitlecommon"> Recent Services</p>
                    </div>

                  </div>

                </div>
                <div className="mainboxespropertyedit">
                  {RecentServicesList.map((item, idx) => (
                    idx < 2 ?
                      <div className="row maindetaillistalldiv" key={idx}>
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-4">
                              <p className="servicelistdate">  {item.ServiceDate}</p>
                              <p className="servicelisttime"> {item.ServiceTime}</p>
                            </div>
                            <div className="col-md-5">
                              <p className="servicetitle">  {item.ServiceName}</p>
                              <p className="descriptionservicelist">Iure odit laudantium soluta dolores quos quaerat explicabo </p>

                            </div>
                            <div className="col-md-3">
                              <div className="servicetagdiv" data-bs-toggle="tooltip" data-bs-placement="top" title=" Civil Maintenance
                              Works">
                                <p>  Civil Maintenance
                                  Works</p>
                              </div>
                              <div className="servicetagdiv mb-0" data-bs-toggle="tooltip" data-bs-placement="top" title=" Civil Maintenance
                              Works">
                                <p> Property Fencing</p>
                              </div>

                            </div>

                          </div>

                        </div>
                        <div className="col-md-5">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="d-flex">
                                <div>
                                  <img src={mainpropertiseimages} className="mainpropertysliderimg2" />
                                </div>
                                <div className="w-100">
                                  <div className="d-flex justify-content-between w-100">
                                    <p className="mainpropertytitlelist">{item.PropertyName} </p>

                                  </div>
                                  <div>
                                    <p className="propertycatageoried">{item.PropertyType}</p>
                                  </div>
                                  <div className="d-flex">
                                    <div>
                                      <img src={item.PropertyfirstImage} className="locationpropertyedit" />
                                    </div>
                                    <div>
                                      <p className="propertytextlocation mb-0">{item.Address}</p>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-4">
                              <div className="mainpaidboxdashboard">
                                <div>
                                  <p className="paidamount">$ {item.TotalPaidAmount}</p>
                                </div>
                                <div className="maindivmaintextpaidnow">
                                  <p className="maintextpaidnow">Pay Now</p>
                                </div>

                              </div>

                            </div>
                          </div>


                        </div>

                      </div>
                      : ''
                  ))}
                </div>

              </div>


            </div>


          </div>


        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
          </div>
          <div className="d-flex">
            <p className="footercopytextedit">T&C</p>
            <p className="footercopytextedit2">Privacy</p>


          </div>

        </div>

      </div>
    </section>
  </React.Fragment>
  )
}


export default DashboardNew
