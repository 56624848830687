import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";

// Table data
const products = [
  {
    FullName: 1,
    Qualification: "B.com",
    Occupation: "Business",
    Country: "India",
    State: "Gujarat",
    MobileNo: "0123456789",
    EmailId: "email@gmail.com",
    action: (
      <>
        <i className="mdi mdi-eye h5 me-3"></i>
        <i className="mdi mdi-pencil h5 me-3"></i>
        <i className="mdi mdi-delete h5 me-3"></i>
      </>
    ),
  },
  {
    FullName: 2,
    Qualification: "B.com",
    Occupation: "Business",
    Country: "India",
    State: "Gujarat",
    MobileNo: "0123456789",
    EmailId: "email@gmail.com",
    action: (
      <>
        <i className="mdi mdi-eye h5 me-3"></i>
        <i className="mdi mdi-pencil h5 me-3"></i>
        <i className="mdi mdi-delete h5 me-3"></i>
      </>
    ),
  },
  {
    FullName: 3,
    Qualification: "B.com",
    Occupation: "Business",
    Country: "India",
    State: "Gujarat",
    MobileNo: "0123456789",
    EmailId: "email@gmail.com",
    action: (
      <>
        <i className="mdi mdi-eye h5 me-3"></i>
        <i className="mdi mdi-pencil h5 me-3"></i>
        <i className="mdi mdi-delete h5 me-3"></i>
      </>
    ),
  },
  {
    FullName: 4,
    Qualification: "B.com",
    Occupation: "Business",
    Country: "India",
    State: "Gujarat",
    MobileNo: "0123456789",
    EmailId: "email@gmail.com",
    action: (
      <>
        <i className="mdi mdi-eye h5 me-3"></i>
        <i className="mdi mdi-pencil h5 me-3"></i>
        <i className="mdi mdi-delete h5 me-3"></i>
      </>
    ),
  },
  {
    FullName: 5,
    Qualification: "B.com",
    Occupation: "Business",
    Country: "India",
    State: "Gujarat",
    MobileNo: "0123456789",
    EmailId: "email@gmail.com",
    action: (
      <>
        <i className="mdi mdi-eye h5 me-3"></i>
        <i className="mdi mdi-pencil h5 me-3"></i>
        <i className="mdi mdi-delete h5 me-3"></i>
      </>
    ),
  },
];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "FullName",

        text: "FullName",
        sort: true,
      },
      {
        dataField: "Qualification",

        text: "Qualification",
        sort: true,
      },
      {
        dataField: "Occupation",

        text: "Occupation",
        sort: true,
      },
      {
        dataField: "Country",

        text: "Country",
        sort: true,
      },
      {
        dataField: "State",

        text: "State",
        sort: true,
      },
      {
        dataField: "MobileNo",

        text: "Mobile No",
        sort: true,
      },
      {
        dataField: "EmailId",

        text: "Email Id",
        sort: true,
      },
      {
        dataField: "action",

        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                <CardTitle className="h4">Lead List</CardTitle>
                  <a href="/invoices-list" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Lead List</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14"
                  href="/"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a
                  className="btn btn-success btn-sm float-end font-14 me-3"
                  href="/maps-google"
                >
                  <i className="mdi mdi-plus align-middle me-1"></i>Add
                </a>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                  <div className="float-end">
                                    <Dropdown
                                      isOpen={this.state.info_dropup111}
                                      direction="left"
                                      className="btn-group dropstart"
                                      toggle={() =>
                                        this.setState({
                                          info_dropup111:
                                            !this.state.info_dropup111,
                                        })
                                      }
                                    >
                                      <DropdownToggle className="btn btn-primary-drop">
                                        <i className="mdi mdi-filter"></i>{" "}
                                        Filter
                                      </DropdownToggle>
                                      <DropdownMenu data-popper-placement="left-start">
                                        <DropdownItem header>
                                          Header
                                        </DropdownItem>
                                        <DropdownItem disabled>
                                          Action
                                        </DropdownItem>
                                        <DropdownItem>
                                          Another Action
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                          Another Action
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
