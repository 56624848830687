import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";

import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";



import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "../frontend/header";
import { ToastProvider, useToasts } from 'react-toast-notifications';


const requestService = () => {
  const [ShowLoader, setShowLoader] = useState(0);
  const { addToast } = useToasts();

  const [PropertyList, setPropertyList] = useState([]);
  const [PropertyDetails, setPropertyDetails] = useState([]);
  const [DocumentList, setDocumentList] = useState([]);
  const [PropertyInsights, setPropertyInsights] = useState([]);
  const [Media, setMedia] = useState("");

  const [PropertyName1, setPropertyName1] = useState([]);
  const [PropertyType1, setPropertyType1] = useState([]);
  const [FullAddress1, setFullAddress1] = useState([]);



  const [CountryId, setCountryId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [CountryList, setCountryList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [StateFilterList, setStateFilterList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [StateId, setStateId] = useState([]);
  const [PropertyType, setPropertyType] = useState([]);
  const [Media1, setMedia1] = useState([]);
  const [PropertyNumber, setPropertyNumber] = useState([]);
  const [NearByPlace, setNearByPlace] = useState([]);
  const [Street, setStreet] = useState([]);
  const [PinCode, setPinCode] = useState([]);
  const [PropertyTypeId, setPropertyTypeId] = useState([]);
  const [FullAddress, setFullAddress] = useState([]);
  const [PropertyTitle, setPropertyTitle] = useState([]);
  const [MediaList, setMediaList] = useState([]);
  const [OtherPropertyType, setOtherPropertyType] = useState([]);

  const [LandArea, setLandArea] = useState([]);
  const [LandAreaIn, setLandAreaIn] = useState("Sq.ft");
  const [SuperBuiltUP, setSuperBuiltUP] = useState([]);
  const [SuperBuiltUpIn, setSuperBuiltUpIn] = useState("Sq.ft");
  const [PropertyInsightsList, setPropertyInsightsList] = useState([]);
  const [InsightMedia1, setInsightMedia1] = useState([]);
  const [IsPropertyFurnished, setIsPropertyFurnished] = useState("");
  const [FurnishedMedia1, setFurnishedMedia1] = useState([]);
  const [FurnishedList, setFurnishedList] = useState([]);
  const [PropertyName, setPropertyName] = useState("");
  const [PropertyImageList, setPropertyImageList] = useState([]);

  const [PropertyDocumentList, setPropertyDocumentList] = useState([]);
  const [DocumentMedia1, setDocumentMedia1] = useState([]);

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var VPropertyId = JSON.parse(localStorage.getItem('ModalPropertyId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    }
    else {
      getPriorityList();
      getPropertyType();
      getSelectedPropertyDetails();
      getCountryList();
    }
  }, []);

  const getPriorityList = async () => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: 1

    }

    axios.post(global.APIURL + '/PropertyList', PropListData)
      .then(res => {
        setPropertyList(res.data.Result);
      });
    setShowLoader(0);
  }

  const getPropertyType = async () => {
    setShowLoader(1);
    axios.get(global.APIURL + '/PropertyType')
      .then(res => {
        setPropertyType(res.data.Result);
      });
    setShowLoader(0);
  }

  const getSelectedPropertyDetails = (e, propId) => {
    setShowLoader(1);
    localStorage.setItem("ServicePropertyId", JSON.stringify(propId));
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropDetailsData = {
      "RegisterId": VRegisterId,
      "PropertyId": propId
    }
    axios.post(global.APIURL + '/propertyDetail', PropDetailsData)
      .then(res => {
        setPropertyDetails(res.data.Result);
        if (res.data.Result) {
          setDocumentList(res.data.Result[0].Document);
          setPropertyInsights(res.data.Result[0].PropertyInsight);
          setMedia(res.data.Result[0].Media[0]);
          console.log(res.data.Result[0].Media[0])
        }
      });

      setShowLoader(0);
  }

  function handleChange(e) {

    setShowLoader(1);
    const MitemArr = {
      PId: MediaList.length + 1,
      MediaFile: e.target.files[0],
      MediaFileUrl: URL.createObjectURL(e.target.files[0])
    }
    setMediaList([...MediaList, MitemArr]);
    setShowLoader(0);
  }

  const addNewProperty = async () => {
    setShowLoader(0);
    let IsInsert = 1;
    if (PropertyTitle == "") {
      addToast("Add Property Title", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (MediaList.length <= 0) {
      addToast("Add Property Images", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (PropertyTypeId == "") {

      addToast("Select Property Type", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (PropertyTypeId == 0) {
      if (OtherPropertyType == "") {
        addToast("Add Property Type", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
        return;
      }
    }
    else if (PropertyNumber == "") {

      addToast("Add Property Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (CountryId == "") {
      addToast("Select Country", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (StateId == "") {
      addToast("Select State", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (CityId == "") {
      addToast("Select City", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert > 0) {
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));

      const formData = new FormData();
      formData.append('SaveAction', 'Save');
      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('TotalMediaCount', MediaList.length);
      formData.append('PropertyTypeID', PropertyTypeId);
      formData.append('OtherPropertyType', OtherPropertyType);
      formData.append('PropertyTitle', PropertyTitle);
      formData.append('PropertyNumber', PropertyNumber);
      formData.append('NearByPlace', NearByPlace);
      formData.append('Street', Street);
      formData.append('Pincode', PinCode);
      formData.append('City', CityId);
      formData.append('State', StateId);
      formData.append('Country', CountryId);
      formData.append('FullAddress', FullAddress);
      formData.append('Latitude', "");
      formData.append('Longitude', "");

      for (let di = 0; di < MediaList.length; di++) {

        let RCount = di + 1;
        let VMedia = MediaList[di].MediaFile;
        let CM = 'Media' + RCount;
        formData.append(CM, VMedia);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.setItem('ModalPropertyId', JSON.stringify(res.data.PropertyId));

            var PropertyId = res.data.PropertyId;
            getPropertyDetail(PropertyId);

            if (PropertyId != "") {
              $('#addnewpropertymodal2').modal('show');
              $('#addnewpropertymodal').modal('hide');
            }
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
           
        });
    }

    setShowLoader(0);
  }

  const addNewPropertyDraft = async () => {
    setShowLoader(1);
    let IsInsert = 1;
    if (PropertyTitle == "") {
      addToast("Add Property Title", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (MediaList.length <= 0) {
      addToast("Add Property Images", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (PropertyTypeId == "") {

      addToast("Select Property Type", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (PropertyTypeId == 0) {
      if (OtherPropertyType == "") {
        addToast("Add Property Type", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
      }
    }
    else if (PropertyNumber == "") {

      addToast("Add Property Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (CountryId == "") {
      addToast("Select Country", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (StateId == "") {
      addToast("Select State", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (CityId == "") {
      addToast("Select City", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }

    if (IsInsert > 0) {
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
      const formData = new FormData();
      formData.append('SaveAction', 'Draft');
      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('TotalMediaCount', MediaList.length);
      formData.append('PropertyTypeID', PropertyTypeId);
      formData.append('OtherPropertyType', OtherPropertyType);
      formData.append('PropertyTitle', PropertyTitle);
      formData.append('PropertyNumber', PropertyNumber);
      formData.append('NearByPlace', NearByPlace);
      formData.append('Street', Street);
      formData.append('Pincode', PinCode);
      formData.append('City', CityId);
      formData.append('State', StateId);
      formData.append('Country', CountryId);
      formData.append('FullAddress', FullAddress);
      formData.append('Latitude', "");
      formData.append('Longitude', "");

      for (let di = 0; di < MediaList.length; di++) {

        let RCount = di + 1;
        let VMedia = MediaList[di].MediaFile;
        let CM = 'Media' + RCount;
        formData.append(CM, VMedia);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.setItem('ModalPropertyId', JSON.stringify(res.data.PropertyId));

            var PropertyId = res.data.PropertyId;
            getPropertyDetail(PropertyId);



            if (PropertyId != "") {
              $('#addnewpropertymodal2').modal('show');
              $('#addnewpropertymodal').modal('hide');
            }
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
          // addToast(res.data.StatusMessage , { appearance: 'success',autoDismiss: true });        
        });
    }
    setShowLoader(1);

  }

  const addPropertyDocuments = () => {
    setShowLoader(1);
    var VDocumentName = document.getElementById('txtDocumentName').value;
    var IsInsert = 1;
    if (DocumentMedia1 == "") {
      addToast("Add Document", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (VDocumentName == "") {
      addToast("Add Document Name", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert > 0) {
      const formData = new FormData();

      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('TotalMediaCount', PropertyDocumentList.length + 1);
      formData.append('PropertyId', VPropertyId);
      formData.append('DocumentMedia1', DocumentMedia1);
      formData.append('DocumentName1', VDocumentName);

      for (let di = 0; di < PropertyDocumentList.length; di++) {

        let RCount = di + 2;
        let VDocumentName = PropertyDocumentList[di].DocumentName;
        let VDocumentMedia = PropertyDocumentList[di].DocumentFile;

        let CD = 'DocumentName' + RCount;
        let CM = 'DocumentMedia' + RCount;

        formData.append(CD, VDocumentName);
        formData.append(CM, VDocumentMedia);

      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      axios.post(global.APIURL + '/PropertyDocuments', formData)
        .then(res => {
          alert(res.data.StatusMessage);
          $('#addnewpropertymodal3').modal('hide');

        });
    }
    setShowLoader(0);

  }

  const propertyDocumentsHandleAddRow = () => {
    setShowLoader(1);
    var LRNo = PropertyDocumentList.length == 0 ? 1 : (PropertyDocumentList[PropertyDocumentList.length - 1].PId + 1);
    var VDocumentName = document.getElementById("txtDocumentName").value;

    const Ditem = {
      PId: LRNo,
      DocumentName: VDocumentName,
      DocumentNameTextBoxControlId: 'txtDocumentName' + LRNo,
      DocumentFile: DocumentMedia1

    }
    setPropertyDocumentList([...PropertyDocumentList, Ditem]);
    document.getElementById("txtDocumentName").value = "";
    setShowLoader(0);
  }

  const getCountryList = () => {
    setShowLoader(1);
    CountryList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        var CLA = [];
        var SLA = [];
        var CTA = [];
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CLA.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;

            const Stateitem = {
              CountryId: cl[cd].Id,
              StateId: Sl[sd].Id,
              StateName: Sl[sd].StateName
            };
            SLA.push(Stateitem);

            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                StateId: StateId,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CTA.push(Cityitem);
            }
          }
        }
        setCountryList(CLA);
        setStateFilterList(SLA);
        setCityFilterList(CTA);
      });
      setShowLoader(0);
  }

  const getCountryChange = (CountryId) => {
    setShowLoader(1);
    var SF = StateFilterList.filter(x => x.CountryId == CountryId);
    setStateList(SF);
    setShowLoader(0);
  }

  const getStateChange = (StateId) => {
    setShowLoader(1);
    var CF = CityFilterList.filter(x => x.StateId == StateId);
    setCityList(CF);
    setShowLoader(0);
  }

  const addLandAreaAndInsights = () => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var VInsightName1 = document.getElementById('txtInsightName1').value;
    var VInsightWidth1 = document.getElementById('txtInsightWidth1').value;
    var VInsightLength1 = document.getElementById("txtInsightLength1").value;
    var VInsightHeight1 = document.getElementById("txtInsightHeight1").value;
    var VFurnishedDetail1 = document.getElementById("txtFurnishedDetail").value;
    var VFurnishedIn = document.getElementById("txtFurnishedIn").value;
    var VPropertyId = JSON.parse(localStorage.getItem('ModalPropertyId'));

    let IsInsert = 1;
    if (LandArea == "") {
      addToast("Add Land Area", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (LandAreaIn == "") {
      addToast("Select Land Area Unit", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (SuperBuiltUP == "") {
      addToast("Add Super Built Up ", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (SuperBuiltUpIn == "") {
      addToast("Select Super Built Up Unit ", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (VInsightName1 == "") {
      addToast("Add Property Insight ", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (VInsightWidth1 == "") {
      addToast("Add Property Insight Width ", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (VInsightLength1 == "") {
      addToast("Add Property Insight Length", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }
    else if (VInsightHeight1 == "") {
      addToast("Add Property Insight Height", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;

    }
    else if (InsightMedia1 == "") {
      addToast("Add Property Insight Image ", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
    }

    else if (IsPropertyFurnished == "Yes") {

      if (VFurnishedIn == "") {
        addToast("Add Insight Furnished", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
      }
      else if (VFurnishedDetail1 == "") {
        addToast("Add Furnish Details", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
      }
      else if (FurnishedMedia1 == "") {
        addToast("Add Furnished Insight Image ", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
      }
    }


    if (IsInsert > 0) {
      const formData = new FormData();

      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('LandArea', LandArea);
      formData.append('LandAreaIn', LandAreaIn);
      formData.append('SuperBuiltUP', SuperBuiltUP);
      formData.append('SuperBuiltUpIn', SuperBuiltUpIn);
      formData.append('PropertyInsightCount', PropertyInsightsList.length + 1);
      formData.append('InsightName1', VInsightName1);
      formData.append('InsightWidth1', VInsightWidth1);
      formData.append('InsightLength1', VInsightLength1);
      formData.append('InsightHeight1', VInsightHeight1);
      formData.append('InsightMedia1', InsightMedia1);
      formData.append('IsPropertyFurnished', IsPropertyFurnished);
      formData.append('FurnishedCount', FurnishedList.length);
      formData.append('PropertyFurnishedDetail1', VFurnishedDetail1);
      formData.append('PropertyFurnishedIn1', VFurnishedIn);
      formData.append('FurnishedMedia1', FurnishedMedia1);
      formData.append('PropertyId', VPropertyId);

      for (let pi = 0; pi < PropertyInsightsList.length; pi++) {

        let RCount = pi + 2;
        let VInsightWidth = PropertyInsightsList[pi].InsightWidth;
        let VInsightLength = PropertyInsightsList[pi].InsightLength;
        let VInsightHeight = PropertyInsightsList[pi].InsightHeight;
        let VInsightName = PropertyInsightsList[pi].InsightName;
        let VInsightFile = PropertyInsightsList[pi].InsightFile;
        let CW = 'InsightWidth' + RCount;
        let CH = 'InsightHeight' + RCount;
        let CL = 'InsightLength' + RCount;
        let CN = 'InsightName' + RCount;
        let CF = 'InsightMedia' + RCount;
        formData.append(CW, VInsightWidth);
        formData.append(CH, VInsightHeight);
        formData.append(CL, VInsightLength);
        formData.append(CN, VInsightName);
        formData.append(CF, VInsightFile);
      }

      for (let fi = 0; fi < FurnishedList.length; fi++) {
        let RCount = fi + 2;
        let VFurnishedDetail = FurnishedList[fi].FurnishedDetail;
        let VFurnishedIn = FurnishedList[fi].FurnishedIn;
        let VFurnishedFile = FurnishedList[fi].FurnishedFile;

        let FI = 'PropertyFurnishedIn' + RCount;
        let FD = 'PropertyFurnishedDetail' + RCount;
        let FF = 'FurnishedMedia' + RCount;

        formData.append(FI, VFurnishedIn);
        formData.append(FD, VFurnishedDetail);
        formData.append(FF, VFurnishedFile);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      axios.post(global.APIURL + '/addEditDeleteLandAreaAndInsights', formData)
        .then(res => {
          if (res.data.Status == true) {
            alert(res.data.StatusMessage);
            $('#addnewpropertymodal3').modal('show');
            $('#addnewpropertymodal2').modal('hide');

            // window.location.href = "/add-new-property3";
          }
          else {
            alert(res.data.StatusMessage)
          }
        });
    }

    setShowLoader(0);
  }

  const getPropertyDetail = async (ModalPropId) => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));

    let VSelectPropertyId = parseInt(ModalPropId == null ? "0" : ModalPropId == "" ? "0" : ModalPropId);
    const PropertyDetailArr = {
      RegisterId: VRegisterId,
      PropertyId: VSelectPropertyId
    };
    axios.post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {
        if (res.data.Result != "") {
          setPropertyName1(res.data.Result[0].PropertyTitle);
          setPropertyType1(res.data.Result[0].PropertyTypeName);
          setFullAddress1(res.data.Result[0].FullAddress)
          var PropImageList = res.data.Result[0].Media;
          var PIArr = [];
          for (let pi = 0; pi < PropImageList.length; pi++) {
            const PropImageUrl = PropImageList[pi].Url;
            const PropImageitem = {
              RId: pi,
              SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
              SliderName: 'Slider ' + (pi + 1),
              PropImageUrl: PropImageUrl
            };
            PIArr.push(PropImageitem);
          }
          setPropertyImageList(PIArr);
        }
      })

      setShowLoader(0);
  }

  const insightsHandleAddRow = () => {
    setShowLoader(1);
    var LRNo = PropertyInsightsList.length == 0 ? 1 : (PropertyInsightsList[PropertyInsightsList.length - 1].PId + 1);
    var VInsightName1 = document.getElementById('txtInsightName1').value;
    var VInsightWidth1 = document.getElementById('txtInsightWidth1').value;
    var VInsightLength1 = document.getElementById("txtInsightLength1").value;
    var VInsightHeight1 = document.getElementById("txtInsightHeight1").value;

    const Pitem = {
      PId: LRNo,
      InsightName: VInsightName1,
      InsightWidth: VInsightWidth1,
      InsightLength: VInsightLength1,
      InsightHeight: VInsightHeight1,
      InsightName1TextBoxControlId: 'txtInsightName1' + LRNo,
      InsightWidth1TextBoxControlId: 'txtInsightWidth1' + LRNo,
      InsightLength1TextBoxControlId: 'txtInsightLength1' + LRNo,
      InsightHeight1TextBoxControlId: 'txtInsightHeight1' + LRNo,
      InsightFile: InsightMedia1,
      InsightFileName: InsightMedia1.name,

    }
    setPropertyInsightsList([...PropertyInsightsList, Pitem]);
    document.getElementById('txtInsightName1').value = '';
    document.getElementById('txtInsightWidth1').value = '';
    document.getElementById('txtInsightLength1').value = '';
    document.getElementById('txtInsightHeight1').value = '';

    setShowLoader(0);
  }
  const handleFurnishedChange = (e) => {
    setShowLoader(1);
    var checked = e.target.checked;
    if (checked) {
      setIsPropertyFurnished("Yes")
    }
    else if (!checked) {
      setIsPropertyFurnished("No")
    }
    setShowLoader(0);
  }

  const furnishedHandleAddRow = () => {
    setShowLoader(1);
    var LRNo = FurnishedList.length == 0 ? 1 : (FurnishedList[FurnishedList.length - 1].PId + 1);
    var VFurnishedDetail = document.getElementById('txtFurnishedDetail').value;
    var VFurnishedIn = document.getElementById("txtFurnishedIn").value;

    const Fitem = {
      PId: LRNo,
      FurnishedDetail: VFurnishedDetail,
      FurnishedIn: VFurnishedIn,
      FurnishedFile: FurnishedMedia1,
      FurnishedDetailTextBoxControlId: 'txtFurnishedDetail' + LRNo,
      FurnishedInTextBoxControlId: 'txtFurnishedIn' + LRNo,
      FurnishedFileName: FurnishedMedia1.name
    }
    setFurnishedList([...FurnishedList, Fitem]);
    document.getElementById('txtFurnishedDetail').value = '';
    document.getElementById('txtFurnishedIn').value = '';
    setShowLoader(0);
  }

  const SaveNext = async () => {
    setShowLoader(1);
    let PId = JSON.parse(localStorage.getItem('ServicePropertyId'));
    let VSelectPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    if (VSelectPropertyId > 0) {
      setShowLoader(0);
      window.location.href = "/request-new-service2";
    }
    else {
      setShowLoader(0);
      alert("Please select property after next click.");
    }
    setShowLoader(0);
  }


  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader" >
        <div className="mainloadernew">
          <div className="spinner-border text-primary mainloderedit" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      : ''}

    <div className="modal fade" id="addnewpropertymodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div> */}
          <div className="modal-body">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <p className="addservicetextform">Add New Properties</p>
                  </div>
                  <div className="col-md-12">
                    <p className="mainsteptextform">Step 1 : Property Detail</p>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty">
                      <div className="row align-items-center">
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Property Title </p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setPropertyTitle(e.target.value)} placeholder="Enter Property Title" />
                              </div>
                            </div>
                          </div>
                          <div className="mainredioboxeditap">
                            <p className="maintitlereuestservicenee">Property Type</p>
                            <div className="d-flex align-items-center">
                              <div>
                                {PropertyType.map((propItems) => <div className="form-check form-check-inline" key={propItems.Id}>
                                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={propItems.Id} onChange={(e) => setPropertyTypeId(e.target.value)} />
                                  <label className="form-check-label commonblackcolor" htmlFor="inlineRadio1">{propItems.PropertyType}</label>
                                </div>)}

                                <div className="form-check form-check-inline" >
                                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="0" onChange={(e) => setPropertyTypeId(e.target.value)} />
                                  <label className="form-check-label commonblackcolor" htmlFor="inlineRadio1">Other</label>
                                </div>
                              </div>
                              <div>
                                <div className="registerinputnewedirr form-controlsustomeditnew">
                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Write Here" onChange={(e) => setOtherPropertyType(e.target.value)} />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="col-md-5">
                          <div className="row mainsideanpimgdiv">
                            {MediaList.map((item, idx) => (
                              <div className="col-4" key={idx}>
                                <div className="mainanpimg">
                                  <img src={item.MediaFileUrl}></img>
                                </div>
                              </div>
                            ))}
                            {MediaList.length < 6 ?
                              <div className="col-4">
                                <div className="mainanpimg">

                                  <a href="#" id="btnUploadPropertyImage">   <img src={addanp}></img></a>

                                  <input type="file" accept="image/*" id="fuUploadPropertyImage" onChange={handleChange} className="d-none" />
                                </div>
                              </div>
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty mb-0">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <p className="blutextheadingeditt">Location Information</p>
                        </div>
                        <div className="col-md-7">
                          <div className="row mainlocationinfodivv">
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Property Number </p>
                                <input type="number" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setPropertyNumber(e.target.value)} placeholder="Enter House / Property Number" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Near By Address</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setNearByPlace(e.target.value)} placeholder="Enter Near by Address" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Street</p>
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setStreet(e.target.value)} placeholder="Enter Street Name" />
                              </div>

                            </div>
                            <div className="col-md-6">
                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <p className="maintitlereuestservicenee">Pin Code</p>
                                <input type="number" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setPinCode(e.target.value)} placeholder="Enter Pin Code" />
                              </div>

                            </div>
                            <div className="col-md-4">
                              <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                <p className="maintitlereuestservicenee">Country</p>
                                <select id="ddlCountry" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                                  <option selected>Select Country</option>
                                  {CountryList.map((CountryListItems) => (
                                    <option key={CountryListItems.CountryId} value={CountryListItems.CountryId}> {CountryListItems.CountryName} </option>
                                  ))}
                                </select>  </div>

                            </div>
                            <div className="col-md-4">
                              <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                <p className="maintitlereuestservicenee">State</p>
                                <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getStateChange(e.target.value); setStateId(e.target.value) }}>
                                  <option selected>Select State</option>
                                  {StateList.map((StateItems) => (
                                    <option key={StateItems.StateId} value={StateItems.StateId}> {StateItems.StateName} </option>
                                  ))}
                                </select>  </div>

                            </div>
                            <div className="col-md-4">
                              <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                <p className="maintitlereuestservicenee">City</p>
                                <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setCityId(e.target.value)}>
                                  <option selected>Select City</option>
                                  {CityList.map((CityItems) => (
                                    <option key={CityItems.CityId} value={CityItems.CityId}> {CityItems.CityName} </option>
                                  ))}
                                </select></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <p className="maintitlereuestservicenee">Location  </p>
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4079037565034!2d72.50534231495733!3d23.04550252125371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bb51d412b01%3A0x4c5338c9649c6eab!2sZion%20Z1!5e0!3m2!1sen!2sin!4v1661162103882!5m2!1sen!2sin" width="100%" height="191" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="mainlocationpreview"></iframe>
                          <div className="registerinputnewedirr form-controlsustomeditnew">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setFullAddress(e.target.value)} placeholder="Enter Your Address" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button type="button" className="btn btn-primary btnnextedit " onClick={addNewProperty}>Next <i className="fas fa-long-arrow-alt-right"></i></button>
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                    <a href="#">  <button type="button" className="btn btn-primary btnnexteditline" onClick={addNewPropertyDraft}>Save as Draft </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="addnewpropertymodal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-body">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <p className="addservicetextform">Add New Properties</p>
                  </div>
                  <div className="col-md-12">
                    <p className="mainsteptextform">Step 2 : Land Area & Insights</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="propertysteptwomainbox">
                      <div className="d-flex">
                        <div>
                          <div id="carouselExampleIndicators" className="carousel slide propertyimgslider  propertyimgslidernewimgedit" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                              {PropertyImageList.map((item, idx) => (
                                idx == 0 ?
                                  <button type="button" data-bs-target="#carouselExampleIndicators" key={idx} data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                                  :
                                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>
                              ))}
                              {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            </div>
                            <div className="carousel-inner">
                              {PropertyImageList.map((item, idx) => (
                                <div className={item.SclassName} key={idx}>
                                  <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                                </div>
                              ))}
                            </div>

                          </div>
                        </div>
                        <div className="w-100">
                          <div className="d-flex w-100">
                            <p className="mainpropertytitlelist">{PropertyName1} </p>
                            <div>
                              <img className="verifiedgreenstep2" src={verifiedgreen} />
                            </div>
                          </div>
                          <div>
                            <p className="propertycatageoried">{PropertyType1}</p>
                          </div>
                          <div className="d-flex">
                            <div>
                              <img src={locationpropertyedit} className="locationpropertyedit" />
                            </div>
                            <div>
                              <p className="propertytextlocation">{FullAddress1}</p>
                            </div>

                          </div>
                        </div>


                      </div>
                      <div>
                        <div className="deletprpertyadd">
                          <i className="las la-trash-alt"></i>
                        </div>

                      </div>

                    </div>

                  </div>
                  <div className="col-md-12">
                    <div className="row ">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="maintitlereuestservicenee">Land Area</p>
                          </div>
                          <div className="col-md-8">
                            <div className="registerinputnewedirr form-controlsustomeditnew">

                              <input type="number" className="form-control formcontrollplaceholder" id="exampleFormControlInput1" placeholder="Enter Land Area" onChange={(e) => setLandArea(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-4">

                            <select className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setLandAreaIn(e.target.value)}>
                              <option value="Sq.ft" selected>Sq. ft</option>
                              <option value="Sq.meter">Square meter</option>
                              <option value="Hectar">Hectare</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="maintitlereuestservicenee">Super Built Up</p>
                          </div>
                          <div className="col-md-8">
                            <div className="registerinputnewedirr form-controlsustomeditnew">

                              <input type="number" className="form-control formcontrollplaceholder" id="exampleFormControlInput1" placeholder="Enter" onChange={(e) => setSuperBuiltUP(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-md-4">

                            <select className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setSuperBuiltUpIn(e.target.value)}>
                              <option value="Sq.ft" selected>Sq. ft</option>
                              <option value="Sq.meter">Square meter</option>
                              <option value="Hectar">Hectare</option>
                            </select>

                          </div>


                        </div>

                      </div>

                    </div>

                  </div>
                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty mb-0">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="maintitlereuestservicenee">Property Insight (Optional)</p>
                        </div>
                        <div className="col-md-6 text-end">
                          <a onClick={insightsHandleAddRow} className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New Property</a>
                        </div>

                      </div>
                      {PropertyInsightsList.map((item, idx) => (<div className="row mainaddnewinsightalldiv" key={idx}>
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id={item.InsightName1TextBoxControlId} defaultValue={item.InsightName} placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="number" className="form-control formcontrollplaceholder border-0" id={item.InsightWidth1TextBoxControlId} defaultValue={item.InsightWidth} />
                                <div className="maininputdefulttext">
                                  <p>W</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="number" className="form-control formcontrollplaceholder border-0" id={item.InsightLength1TextBoxControlId} defaultValue={item.InsightLength} />
                                <div className="maininputdefulttext">
                                  <p>L</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="number" className="form-control formcontrollplaceholder border-0" id={item.InsightHeight1TextBoxControlId} defaultValue={item.InsightHeight} />
                                <div className="maininputdefulttext">
                                  <p>H</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" value={item.InsightFileName} />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <a href="#" id="upfile1"><button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt" ></i>Upload Files </button></a>
                          <input type="file" id="file1" className="d-none" />
                        </div>
                      </div>))}

                      <div className="row mainaddnewinsightalldiv">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="txtInsightName1" placeholder="write here" />
                              </div>

                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                <input type="number" className="form-control formcontrollplaceholder border-0" id="txtInsightWidth1" />
                                <div className="maininputdefulttext">
                                  <p>W</p>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                <input type="number" className="form-control formcontrollplaceholder border-0" id="txtInsightLength1" />
                                <div className="maininputdefulttext">
                                  <p>L</p>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-2">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                <input type="number" className="form-control formcontrollplaceholder border-0" id="txtInsightHeight1" />
                                <div className="maininputdefulttext">
                                  <p>H</p>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <a href="#" id="upfile2"><button type="button" className="btn uploadbtnmainaddproperty"  ><i className="las la-cloud-upload-alt" ></i>Upload Files </button></a>
                          <input type="file" id="file2" onChange={(e) => setInsightMedia1(e.target.files[0])} className="d-none" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <form>
                              <div className="form-group newwform-group">
                                <input type="checkbox" id="html" onChange={(e) => handleFurnishedChange(e)} />
                                <label htmlFor="html">is Property Furnished ?</label>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="maindivederaddpropertyinsight"></div>
                        </div>
                      </div>



                      {FurnishedList.map((item, idx) => (<div className="row mainaddnewinsightalldiv align-items-center" key={idx}>
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-3">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id={item.FurnishedDetailTextBoxControlId} defaultValue={item.FurnishedDetail} placeholder="write here" />
                              </div>

                            </div>
                            <div className="col-md-2">
                              <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example" id={item.FurnishedInTextBoxControlId} selected={item.FurnishedIn}>
                                <option value=""></option>
                                <option value="Room 1">Room 1</option>
                                <option value="One">Room 2</option>
                                <option value="Two">Room 3</option>
                                <option value="Two">Room 4</option>
                              </select>

                            </div>

                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" value={item.FurnishedFileName} />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                            </div>

                          </div>


                        </div>
                        <div className="col-md-2 text-end">
                          <a onClick={furnishedHandleAddRow}> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New </p></a>
                        </div>


                      </div>))}



                      <div className="row mainaddnewinsightalldiv align-items-center ">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-3">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="txtFurnishedDetail" placeholder="write here" />
                              </div>

                            </div>
                            <div className="col-md-2">
                              <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example" id="txtFurnishedIn">
                                <option value=""></option>
                                <option value="Room 1">Room 1</option>
                                <option value="One">Room 2</option>
                                <option value="Two">Room 3</option>
                                <option value="Two">Room 4</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 ">

                              <a href="#" id="upfile5"><button type="button" className="btn uploadbtnmainaddproperty"  ><i className="las la-cloud-upload-alt" ></i>Upload Files </button></a>
                              <input type="file" id="file5" onChange={(e) => setFurnishedMedia1(e.target.files[0])} className="d-none" />

                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <a onClick={furnishedHandleAddRow} className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* <a href="#" data-bs-toggle="modal" data-bs-target="#addnewpropertymodal3" data-bs-dismiss="modal" aria-label="Close"> */} <button type="button" className="btn btn-primary btnnextedit " onClick={addLandAreaAndInsights}>Next <i className="fas fa-long-arrow-alt-right"></i></button>
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                    <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Save as Draft </button></a>
                  </div>
                </div>



              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div className="modal fade" id="addnewpropertymodal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">

          <div className="modal-body">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <p className="addservicetextform">Add New Properties</p>
                  </div>
                  <div className="col-md-12">
                    <p className="mainsteptextform">Step 3 : Documents</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="propertysteptwomainbox">
                      <div className="d-flex">
                        <div>
                          <div id="carouselExampleIndicators" className="carousel slide propertyimgslider  propertyimgslidernewimgedit" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                              {PropertyImageList.map((item, idx) => (
                                idx == 0 ?
                                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                                  :
                                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>
                              ))}
                              {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            </div>
                            <div className="carousel-inner">
                              {PropertyImageList.map((item, idx) => (
                                <div className={item.SclassName} key={idx}>
                                  <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                                </div>
                              ))}
                            </div>
                          </div>


                        </div>
                        <div className="w-100">
                          <div className="d-flex w-100">
                            <p className="mainpropertytitlelist">{PropertyName1} </p>
                            <div>

                              <img className="verifiedgreenstep2" src={verifiedgreen} />
                            </div>
                          </div>
                          <div>
                            <p className="propertycatageoried">{PropertyType1}</p>
                          </div>
                          <div className="d-flex">
                            <div>
                              <img src={locationpropertyedit} className="locationpropertyedit" />
                            </div>
                            <div>
                              <p className="propertytextlocation">{FullAddress1}</p>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div>
                        <div className="deletprpertyadd">
                          <i className="las la-trash-alt"></i>
                        </div>

                      </div>

                    </div>

                  </div>

                  <div className="col-md-12">
                    <div className="mainboxnewaddproperty ">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="maintitlereuestservicenee">Documents</p>
                        </div>
                        <div className="col-md-6 text-end">
                          <a onClick={propertyDocumentsHandleAddRow} className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New Document</a>
                        </div>

                      </div>

                      {PropertyDocumentList.map((item, idx) => (<div className="row mainaddnewinsightalldiv" key={idx}>
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-8">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id={item.DocumentNameTextBoxControlId} defaultValue={item.DocumentName} placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" value={DocumentMedia1.name} />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div className="col-md-2 text-end">
                          <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                        </div>

                      </div>))}
                      <div className="row mainaddnewinsightalldiv">
                        <div className="col-md-10">
                          <div className="row align-items-center ">
                            <div className="col-md-1">
                              <img src={deletedash} className="deletedashimg"></img>
                            </div>
                            <div className="col-md-8">
                              <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                <input type="text" className="form-control formcontrollplaceholder border-0" id="txtDocumentName" placeholder="write here" />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="">
                                <input type="file" name="file" id="file" className="file" />
                                <div className="mainfileuploadimgwith">
                                  <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                  <div className="maindivdeletprpertyaddnewsmall">
                                    <div className="deletprpertyaddnewsmall">
                                      <i className="las la-trash-alt"></i>
                                    </div>
                                  </div>
                                  <div className="filnamewithiconmain">
                                    <i className="las la-file"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <a href="#" id="upfile4"> <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button></a>
                          <input type="file" id="file4" className="d-none" onChange={(e) => setDocumentMedia1(e.target.files[0])} />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-md-12">
                    <a href="#" > <button type="button" className="btn btn-primary btnnextedit " onClick={addPropertyDocuments}>Finish <i className="fas fa-long-arrow-alt-right"></i></button></a>
                    <a href="#" data-bs-dismiss="modal" aria-label="Close">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
                    <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Save as Draft </button></a>
                  </div>

                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>





    <Commheader />
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-6">
              <p className="addservicetextform">Add New Service Request</p>
            </div>
            <div className="col-md-6 text-end">
              <a href="#" data-bs-toggle="modal" data-bs-target="#addnewpropertymodal" className="addnewpropertytext">  <i className="las la-plus-circle"></i>Add New Property</a>
            </div>
            <div className="col-md-12">
              <p className="mainsteptextform">Step 1 : Select Property</p>
            </div>

          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="mainleftsideserverequest">
                <p className="maintitlereuestservicenee">Select Property</p>
                <div>

                  {PropertyList.map((propItems, idx) => (
                    <div className="mainrsdivv" key={idx}>
                      <div className="form-check form-checkneww ">

                        <input className="form-check-input" type="radio" name="exampleRadios" value="option1" onChange={e => getSelectedPropertyDetails(e, propItems.PropertyId)} />

                        <div className="mainserviceselectradiobox" id="exampleRadios1">

                          <div className="d-flex">
                            <div>
                              <img src={sr1} className="serviceselectimgjjk" />
                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between w-100">
                                <p className="mainpropertytitlelist">{propItems.PropertyName} </p>

                              </div>
                              <div>
                                <p className="propertycatageoried">Category {propItems.PropertyType}</p>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <img src={locationpropertyedit} className="locationpropertyedit" />
                                </div>
                                <div>
                                  <p className="propertytextlocation mb-0">{propItems.FullAddress}</p>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>))}

                </div>
              </div>
            </div>

            {PropertyDetails?.map((propDetailItems) => (<div className="col-md-7" key={propDetailItems.PropertyId}>
              <div className="mainrightsideboxrequest">
                <p className="maintitlereuestservicenee">Property Details</p>
                <div className="d-flex align-items-center">
                  <div>
                    <img src={Media.Url} className="propertydetailsimgmainn" />
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between w-100">
                      <p className="mainpropertytitlelist">{propDetailItems.PropertyTitle} </p>

                    </div>
                    <div>
                      <p className="propertycatageoried">Category {propDetailItems.PropertyTypeName} </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation mb-0">{propDetailItems.FullAddress}</p>
                      </div>

                    </div>
                    <div className="maindividerpd"> </div>
                    <div>
                      <p className="landareatext">Land Area :</p>
                      <div className="d-flex">
                        <div className="margineditlandarea">
                          <p className="mainlandareatextdescription"> <img src={landarea1} /> {propDetailItems.LandArea} Sq. ft</p>
                        </div>
                        <div>
                          <p className="mainlandareatextdescription"> <img src={landarea2} /> 250 Sq. ft</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <p className="maintitlereuestserviceneebew2">Documents :</p>
                <div className="row">

                  {DocumentList?.map((docItems) => (<div className="col-md-6" key={docItems.DocumentId}>
                    <div className="mainpdfdownloadsevice">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="downloadpdftext"><i className="las la-file"></i>{docItems.DocumentName}</p>
                        </div>
                        <div>
                          <i className="las la-download downloadiconsrl"></i>
                        </div>
                      </div>
                    </div>
                  </div>))}

                </div>
                <p className="maintitlereuestserviceneebew2">Property Insights :</p>

                <div className="row">

                  {PropertyInsights?.map((insightItems) => (<div className="col-md-4" key={insightItems.Id}>
                    <div className="propertyinsideboxes">
                      <div>
                        <div id="carouselExampleIndicators4" className="carousel slide propertyimgsliderneww" data-bs-ride="carousel">
                          {/* <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                          </div> */}
                          <div className="carousel-inner">
                            <div className="maintaginsightbox">{insightItems.InsightName}</div>
                            <div className="carousel-item active">
                              <img src={insightItems.InsightMedia} />
                            </div>

                          </div>
                        </div>
                        <div className="mainpiboxgap">
                          <p className="maintextdescriptiongdhs"><img className="mainroomsizeicon" src={roomsizeicon} />
                            <span className="textroomsize">{insightItems.InsightLength}</span>
                            <span className="textroomsizediver">&times;</span>
                            <span className="textroomsize">{insightItems.InsightWidth}</span>
                            <span className="textroomsizediver">&times;</span>
                            <span className="textroomsize">{insightItems.InsightHeight}</span>
                            <span className="textroomsize sqfttextedit">Sq. ft</span>
                          </p>
                          <p className="maintextdescriptiongdhs mb-0"><img className="mainroomsizeiconcorrect" src={correct} /><span className="textroomsize">Furnish Property</span></p>
                          <div className="maindvidertextdescription">
                          </div>
                          <p className="mainfooterboxspinsight">Chairs and Seating, Tables, Cabinets</p>
                        </div>


                      </div>

                    </div>

                  </div>))}

                </div>

              </div>

            </div>))}

            <div className="col-md-12"><a id="lnkNext" onClick={() => SaveNext()}><button className="btn btn-primary btnnextedit ">Next <i className="fas fa-long-arrow-alt-right"></i></button></a></div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
          </div>
          <div className="d-flex">
            <p className="footercopytextedit">T&C</p>
            <p className="footercopytextedit2">Privacy</p>


          </div>

        </div>

      </div>
    </section>
  </React.Fragment>);
}


export default requestService
