import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
// import images
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";

import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class UiImages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
      <div className="page-content">
          <MetaTags>
            <title>Branch Add/Edit | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Col className="col-12 mb-3">
                <div className="breadcrumb align-items-center justify-content-end">
               <a href="/dashboard" className="me-2">Home</a> <span>/</span><span className="ms-2">Add/Edit Branch</span>
                </div>
              </Col>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-2">Branch - Add/Edit </CardTitle>
                 </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6 mb-4">Branch Detail</CardTitle>
                     <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Branch Title"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Address Line 1"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Address Line 2"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Near By"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Area"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Zipcode"
                            />
                          </div>
                        </Col>
                      </Row>
                    
                      <Row>
                      <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>City</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>State</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Country</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Primary Email"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Alternet Email"
                            />
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Primary Contact Number"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Alternet Contact No."
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Official Website"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                        <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                        </Col>
                        <Col className="col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>
                     
                               <nav className="mt-3" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiImages;
