import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "./header";


const requestService2 = () => {
  const [ShowLoader, setShowLoader] = useState(0);
  const [ServiceType, setServiceType] = useState([]);
  const [ServiceFilter, setServiceFilter] = useState([]);
  const [ServiceList, setServiceList] = useState([]);
  const [ServiceDescriptionList, setServiceDescriptionList] = useState([]);
  const [ServiceMediaList, setServiceMediaList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [PropertyImageList, setPropertyImageList] = useState([]);
  const [PropertyImageFilterList, setPropertyImageFilterList] = useState([]);

  const [PropertyId, setPropertyId] = useState(0);
  const [ServiceTypeId, setServiceTypeId] = useState(0);
  const [ServiceId, setServiceId] = useState('0');
  const [Date, setDate] = useState('');
  const [Time, setTime] = useState('');
  const [KeyStatus, setKeyStatus] = useState("");
  const [PartyNumber, setPartyNumber] = useState('');
  const [CountryId, setCountryId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [IsSpecializedSecurity, setIsSpecializedSecurity] = useState(0);
  const [SecurityDetail, setSecurityDetail] = useState('');
  const [IsPets, setIsPets] = useState(0);
  const [PetsInstruction, setPetsInstruction] = useState('');
  const [OtherInstructions, setOtherInstructions] = useState('');

  const [PropertyName, setPropertyName] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [FullAddress, setFullAddress] = useState("");

  const [ServiceDetail, setServiceDetail] = useState([]);
  const [IsSpecializedSecuritySelected, setIsSpecializedSecuritySelected] = useState('no');
  const [IsAnyPetsSelected, setIsAnyPetsSelected] = useState('no');

  const [PetsList, setPetsList] = useState([]);


  useEffect(() => {
    getServiceType();
    getCountryList();
    getPropertyDetail();
    getServiceDetail();
    getPetList();
  }, []);

  const getPetList = () => {
    setShowLoader(1);
    var AUrl = global.APIURL + '/PaidListdata';
    axios.get(AUrl)
      .then(res => {
        setPetsList(res.data.Result);
      });
      setShowLoader(0);
  }

  const getServiceType = () => {
    setShowLoader(1);
    var AUrl = global.APIURL + '/Servicetype';
    axios.get(AUrl)
      .then(res => {
        setServiceType(res.data.Result);
        for (let sl = 0; sl < res.data.Result.length; sl++) {
          const VServiceList = res.data.Result[sl].ServiceList;

          for (let sd = 0; sd < VServiceList.length; sd++) {
            const Ditem = {
              ServiceTypeId: res.data.Result[sl].ServiceTypeId,
              ServiceId: VServiceList[sd].ServiceId,
              ServiceName: VServiceList[sd].ServiceName,
              Description: VServiceList[sd].Description,
              Media: VServiceList[sd].Media,
            };
            ServiceFilter.push(Ditem)
          }
        }
      });
      setShowLoader(0);
  }

  const getServiceTypeChange = (STypeId) => {
    setShowLoader(1);
    var SF = ServiceFilter.filter(x => x.ServiceTypeId == STypeId);
    ServiceList.length == 0;
    setServiceList(SF);
    setServiceTypeId(STypeId)
    setShowLoader(0);
  }

  const getServiceChange = (SId) => {
    setShowLoader(1);
    var SF = ServiceFilter.filter(x => x.ServiceId == SId);
    if (SF.length > 0) {
      setServiceId(SId)
      setServiceDescriptionList(SF[0].Description);
      setServiceMediaList(SF[0].Media);
    }
    setShowLoader(0);
  }

  const getCountryList = () => {
    setShowLoader(1);
    CountryList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CountryList.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;
            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                CountryId: cl[cd].Id,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CityFilterList.push(Cityitem);
            }
          }
        }
      });
      setShowLoader(0);
  }

  const getCountryChange = (CountryId) => {
    setShowLoader(1);
    var CF = CityFilterList.filter(x => x.CountryId == CountryId);
    setCityList(CF);
    setShowLoader(0);
  }

  const getPropertyDetail = async () => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let PId = JSON.parse(localStorage.getItem('EditPropertyId'));
    let VEditPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    const PropertyDetailArr = {
      RegisterId: VRegisterId,
      PropertyId: VEditPropertyId
    };
    axios.post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {
        setPropertyName(res.data.Result[0].PropertyTitle);
        setPropertyType(res.data.Result[0].PropertyTypeName);
        setFullAddress(res.data.Result[0].FullAddress)
        var PropImageList = res.data.Result[0].Media;
        var PIArr = [];
        for (let pi = 0; pi < PropImageList.length; pi++) {
          const PropImageUrl = PropImageList[pi].Url;
          if (PropImageUrl != null) {
            if (PropImageUrl != '') {
              const PropImageitem = {
                RId: pi,
                SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
                SliderName: 'Slider ' + (pi + 1),
                PropImageUrl: PropImageUrl
              };
              PIArr.push(PropImageitem);
            }
          }
        }
        setPropertyImageList(PIArr);
      })
      setShowLoader(0);
  }

  const SpecializedSecurityChange = (e) => {
    setShowLoader(1);
    setIsSpecializedSecurity(e.target.checked == true ? 1 : 0);
    setShowLoader(0);
  }

  const getServiceDetail = () => {
    setShowLoader(1);
    var VLoginUserId = JSON.parse(localStorage.getItem("RegisterId") == null ? "0" : localStorage.getItem("RegisterId") == "" ? "0" : localStorage.getItem("RegisterId"));
    var VServiceId = JSON.parse(localStorage.getItem("EditServiceId") == null ? "0" : localStorage.getItem("EditServiceId") == "" ? "0" : localStorage.getItem("EditServiceId"));

    const SDetailArr = { "ServiceId": VServiceId }
    axios.post(global.APIURL + '/serviceEditList', SDetailArr)
      .then(res => {

        setServiceTypeId(res.data.Result[0].ServiceTypeId);
        setServiceId(res.data.Result[0].ServiceId);
        var SF = ServiceFilter.filter(x => x.ServiceTypeId === res.data.Result[0].ServiceTypeId);
        ServiceList.length == 0;
        setServiceList(SF);
        document.getElementById("ddlService").value = res.data.Result[0].ServiceId;
        getServiceChange(res.data.Result[0].ServiceId);
        setDate(res.data.Result[0].Date);
        setTime(res.data.Result[0].Time);
        setKeyStatus(res.data.Result[0].KeyStatus);
        setPartyNumber(res.data.Result[0].PartyNumber);
        setCountryId(res.data.Result[0].CountryId);
        setCityId(res.data.Result[0].CityId);

        var CF = CityFilterList.filter(x => x.CountryId == res.data.Result[0].CountryId);
        setCityList(CF);

        setIsSpecializedSecurity(res.data.Result[0].IsSpecializedSecurity);
        if (res.data.Result[0].IsSpecializedSecurity == 1) {
          document.getElementById("ckSpecialisedSecurity").checked = true;
        }
        else {
          document.getElementById("ckSpecialisedSecurity").checked = false;
        }
        setSecurityDetail(res.data.Result[0].SecurityDetail);

        setIsPets(res.data.Result[0].IsPets);
        if (res.data.Result[0].IsPets == 1) {
          document.getElementById("ckAnyPets").checked = true;
        }
        else {
          document.getElementById("ckAnyPets").checked = false;
        }
        setPetsInstruction(res.data.Result[0].PetsInstruction);
        setOtherInstructions(res.data.Result[0].OtherInstructions);
        if (res.data.Result[0].OtherInstructions != null) {
          if (res.data.Result[0].OtherInstructions != '') {
            document.getElementById("ckOthers").checked = true;
          }
          else {
            document.getElementById("ckOthers").checked = false;
          }
        }
        else {
          document.getElementById("ckOthers").checked = false;
        }

      });
      setShowLoader(0);
  }

  const SaveRequestNewService = async () => {
    setShowLoader(1);
    var LoginUserId = JSON.parse(localStorage.getItem("RegisterId") == null ? "0" : localStorage.getItem("RegisterId") == "" ? "0" : localStorage.getItem("RegisterId"));
    var VServiceId = parseInt(JSON.parse(localStorage.getItem("EditServiceId") == null ? "0" : localStorage.getItem("EditServiceId") == "" ? "0" : localStorage.getItem("EditServiceId")));
    let PId = JSON.parse(localStorage.getItem('EditPropertyId'));
    let VEditPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    let IsInsert = 1;

    if (VEditPropertyId == 0) {
      alert('Please select property.');
      IsInsert = 0;
      return;
    }
    if (ServiceTypeId == 0) {
      alert('Please select service type.');
      IsInsert = 0;
      return;
    }

    if (ServiceId == 0) {
      alert('Please select service.');
      addToast('Please select service.', { appearance: 'warn', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (Date == null) {
      alert('Please select date.');
      IsInsert = 0;
      return;
    }
    else {
      if (Date == '') {
        alert('Please select date.');
        addToast('Please select date.', { appearance: 'warn', autoDismiss: true });
        IsInsert = 0;
        return;
      }
    }

    if (Time == null) {
      alert('Please select time.');
      IsInsert = 0;
      return;
    }
    else {
      if (Time == '') {
        alert('Please select time.');
        IsInsert = 0;
        return;
      }
    }

    if (KeyStatus == null) {
      alert('Please select key status.');
      IsInsert = 0;
      return;
    }
    else {
      if (KeyStatus == '') {
        alert('Please select key status.');
        IsInsert = 0;
        return;
      }
    }

    if (CountryId == 0) {
      alert('Please select country.');
      IsInsert = 0;
      return;
    }

    if (CityId == 0) {
      alert('Please select city.');
      IsInsert = 0;
      return;
    }
    console.log(IsInsert);

    if (IsInsert > 0) {
      const formData = new FormData();
      formData.append('RegisterId', LoginUserId);
      formData.append('ServiceReqestId', VServiceId);
      formData.append('Action', 'Edit');
      formData.append('PropertyId', VEditPropertyId);
      formData.append('ServiceTypeId', ServiceTypeId);
      formData.append('ServiceId', ServiceId);
      formData.append('Date', Date);
      formData.append('Time', Time);
      formData.append('KeyStatus', KeyStatus);
      formData.append('PartyNumber', PartyNumber);
      formData.append('CountryId', CountryId);
      formData.append('CityId', CityId);
      formData.append('IsSpecializedSecurity', IsSpecializedSecurity);
      formData.append('SecurityDetail', SecurityDetail);
      formData.append('IsPets', IsPets);
      formData.append('PetsInstruction', PetsInstruction);
      formData.append('OtherInstructions', OtherInstructions);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ':' + pair[1]);
      // }
      axios.post(global.APIURL + "/addEditDeleteService", formData)
        .then(res => {
          alert(res.data.StatusMessage);
          localStorage.removeItem("EditServiceId");
          localStorage.removeItem("EditPropertyId");
          window.location.href = "/manage-service";
        });
    }

    setShowLoader(1);
  };

  return (<React.Fragment>
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="verificationeditext">
              Verification

            </p>

            <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close">Skip</button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={otpicon} ></img>
            </div>
            <div>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Mobile Number Verification</p>
                </div>
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(1)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(2)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(3)' maxLength='1' />
                <input className="otp" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(4)' maxLength='1' />
              </form>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Email Address Verification</p>
                </div>
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(1)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(2)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(3)' maxLength='1' />
                <input className="otp" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(4)' maxLength='1' />
              </form>
              <div className="text-center">
                <button type="button" className="btn btn-primary btnnextedit " >Continue <i className="fas fa-long-arrow-alt-right"></i></button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
    <Commheader />
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-12">
              <p className="addservicetextform">Edit Service Request</p>

            </div>

            <div className="col-md-12">
              <p className="mainsteptextform">Step 2 : Select Service</p>

            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="propertysteptwomainbox">
                <div className="d-flex">
                  <div>
                    <div id="carouselExampleIndicators" className="carousel slide propertyimgslider  propertyimgslidernewimgedit" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        {PropertyImageList.map((item, idx) => (

                          item.PropImageUrl == '' ? '' :
                            idx == 0 ?
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                              :
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>

                        ))}
                        {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                      </div>
                      <div className="carousel-inner">
                        {PropertyImageList.map((item, idx) => (
                          item.PropImageUrl == null ? '' : item.PropImageUrl == '' ? '' :
                            <div className={item.SclassName} key={idx}>
                              <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                            </div>
                        ))}
                      </div>

                    </div>


                  </div>
                  <div className="w-100">
                    <div className="d-flex w-100">

                      <p className="mainpropertytitlelist">{PropertyName} </p>
                      <div>

                        <img className="verifiedgreenstep2" src={verifiedgreen} />
                      </div>
                    </div>
                    <div>
                      <p className="propertycatageoried">{PropertyType}</p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation">{FullAddress}</p>
                      </div>

                    </div>
                  </div>


                </div>
                <div>
                  <div className="deletprpertyadd">
                    <a href="/request-new-service"><i className="las la-trash-alt selectservicebackbutton"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-4">
                  <p className="maintitlereuestservicenee">Service Type</p>
                  <select id="ddlServiceType" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={e => getServiceTypeChange(e.target.value)}>
                    <option selected>Select Service Type</option>
                    {ServiceType.map((ServiceTypeItems) => (
                      <option key={ServiceTypeItems.ServiceTypeId} value={ServiceTypeItems.ServiceTypeId} selected={ServiceTypeItems.ServiceTypeId === ServiceTypeId}> {ServiceTypeItems.ServiceTypeName} </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <p className="maintitlereuestservicenee">Service</p>
                  <select id="ddlService" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={e => getServiceChange(e.target.value)}>
                    <option selected>Select Service</option>
                    {ServiceList.map((ServiceItems) => (
                      <option key={ServiceItems.ServiceId} value={ServiceItems.ServiceId} selected={ServiceId === ServiceItems.ServiceId}> {ServiceItems.ServiceName} </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mianservicedetailseditboxe">
                <div className="row">
                  <div className="col-md-12">
                    <p className="maintitlereuestservicenee">Service Detail</p>
                  </div>
                  <div className="col-md-9">
                    <div>
                      {ServiceDescriptionList.map((item, idx) => (
                        <div className="maindetailcheckmemberstep2" key={idx}>
                          <div>
                            <i className="fas fa-check"></i>
                          </div>
                          <div>
                            <p> {item.Bullets}</p>
                          </div>
                        </div>
                      ))}

                    </div>

                  </div>
                  <div className="col-md-3">
                    <div id="carouselExampleIndicatorsnewsideservice" className="carousel slide propertyimgslider propertyimgslidernewwer" data-bs-ride="carousel">
                      <div className="carousel-indicators">
                        {ServiceMediaList.map((item, idx) => (
                          idx == 0 ?
                            <button type="button" data-bs-target="#carouselExampleIndicatorsnewsideservice" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" key={idx}></button>
                            :
                            <button type="button" data-bs-target="#carouselExampleIndicatorsnewsideservice" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        ))}
                      </div>

                      <div className="carousel-inner">
                        {ServiceMediaList.map((item, idx) => (
                          idx == 0 ?
                            <div className="carousel-item active">
                              <img src={item.Url} className="serviceimgrigtsideslider" />
                            </div>
                            :
                            <div className="carousel-item ">
                              <img src={item.Url} className="serviceimgrigtsideslider" />
                            </div>
                        ))}
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <label htmlFor="txtDate" className="form-label">Select Date </label>
                      <input type="date" className="form-control " id="txtDate" onChange={(e) => setDate(e.target.value)} defaultValue={Date} />
                    </div>

                  </div>

                </div>
                <div className="col-md-2">
                  <div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <label htmlFor="txtTime" className="form-label">Select Time</label>
                      <input type="time" className="form-control " id="txtTime" onChange={(e) => setTime(e.target.value)} defaultValue={Time} />
                    </div>

                  </div>

                </div>
                <div className="col-md-2">
                  <div>
                    <div className="mainrediocheckcustome">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Key Status</label>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="rbKeyStatus" id="rbKeyStatusWithCompany" checked={KeyStatus === 'with_company'} onChange={(e) => setKeyStatus("with_company")} />
                        <label className="form-check-label" htmlFor="rbKeyStatusWithCompany">
                          With Company
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="rbKeyStatus" id="rbKeyStatusWith3rdParty" checked={KeyStatus === 'with_third_party'} onChange={(e) => setKeyStatus("with_third_party")} />
                        <label className="form-check-label" htmlFor="rbKeyStatusWith3rdParty">
                          With 3<sup>rd</sup> Party
                        </label>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="maintitlereuestservicenee">Please message to user & Share their number</p>
                    </div>
                    <div className="col-md-4">
                      <div className="registerinputnewedirr form-controlsustomeditnew">
                        <input type="number" className="form-control formcontrollplaceholder" id="txtShareNumber" defaultValue={PartyNumber} placeholder="Enter Number" onChange={(e) => setPartyNumber(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-4">

                      <select id="ddlCountry" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                        <option selected>Select Country</option>
                        {CountryList.map((CountryListItems) => (
                          <option key={CountryListItems.CountryId} value={CountryListItems.CountryId} selected={CountryListItems.CountryId === CountryId}> {CountryListItems.CountryName} </option>
                        ))}
                      </select>

                    </div>
                    <div className="col-md-4">

                      <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setCityId(e.target.value)}>
                        <option selected>Select City</option>
                        {CityList.map((CityItems) => (
                          <option key={CityItems.CityId} value={CityItems.CityId} selected={CityItems.CityId === CityId}> {CityItems.CityName} </option>
                        ))}
                      </select>

                    </div>

                  </div>

                </div>


              </div>

            </div>
            <div className="col-md-12">
              <div className="mianservicedetailseditboxenewq">
                <div className="row">
                  <div className="col-md-12">
                    <p className="maintitlereuestservicenee">Service Detail</p>

                  </div>
                  <div className="col-md-3">
                    <div>
                      <form>
                        <div className="form-group newwform-group">
                          <input type="checkbox" id="ckSpecialisedSecurity" onChange={(e) => setIsSpecializedSecurity(e.target.checked == true ? 1 : 0)} />
                          <label htmlFor="ckSpecialisedSecurity">Specialised Security</label>
                        </div>

                      </form>
                    </div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <input type="text" className="form-control formcontrollplaceholder" id="txtSpecialisedSecurity" defaultValue={SecurityDetail} placeholder="Write Here" onChange={(e) => setSecurityDetail(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group newwform-group">
                          <input type="checkbox" id="ckAnyPets" onChange={(e) => setIsPets(e.target.checked == true ? 1 : 0)} />
                          <label htmlFor="ckAnyPets">Any Pets </label>
                        </div>

                      </div>
                      <div className="col-md-4">
                        <select id="ddlAnyPets" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" >
                          <option selected>Select Type</option>
                          {PetsList.map((item, idx) => (
                            <option key={idx} value={item.Id}>{item.Name}</option>
                          ))}
                        </select>

                      </div>
                      <div className="col-md-8">

                        <div className="registerinputnewedirr form-controlsustomeditnew">

                          <input type="text" className="form-control formcontrollplaceholder" id="txtAnyPets" defaultValue={PetsInstruction} placeholder="Write Your Instruction Here" onChange={(e) => setPetsInstruction(e.target.value)} />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="col-md-3">
                    <div className="form-group newwform-group">
                      <input type="checkbox" id="ckOthers" />
                      <label htmlFor="ckOthers">Others</label>
                    </div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">

                      <input type="text" className="form-control formcontrollplaceholder" id="txtOthers" placeholder="Write Here" defaultValue={OtherInstructions} onChange={(e) => setOtherInstructions(e.target.value)} />
                    </div>

                  </div>

                </div>

              </div>

            </div>
            <div className="col-md-12">
              <div className="form-group newwform-group2">
                <input type="checkbox" id="ckticking" />
                <label htmlFor="ticking">By ticking, you are confirming that you have read, understood and agree to the Terms and conditions and privacy policy</label>
              </div>

            </div>
            <div className="col-md-12">
              <button type="button" onClick={() => SaveRequestNewService()} className="btn btn-primary btnnextedit">Finish <i className="fas fa-long-arrow-alt-right"></i></button>
              <a href="/request-new-service">  <button type="button" className="btn btn-primary btnnexteditline">Back </button></a>
            </div>

          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
          </div>
          <div className="d-flex">
            <p className="footercopytextedit">T&C</p>
            <p className="footercopytextedit2">Privacy</p>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>);
}



export default requestService2
