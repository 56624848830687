import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import { registerField } from "redux-form";
import axios from "axios";
import { ToastProvider, useToasts } from 'react-toast-notifications';

const Login = () => {

  const { addToast } = useToasts();

  const login = async () => {

    let EmailMo = document.getElementById("txtEmail/Mo").value;
    let txtPassword = document.getElementById("txtPassword").value;

    const formData = new FormData();

    formData.append('Email/Mobile', EmailMo);
    formData.append('Password', txtPassword);

    axios.post(global.APIURL + '/Login', formData)
      .then(res => {
        console.log("res", res);
        console.log("res.data.Result.RegisterId", res.data.Result.RegisterId)

        let arrlen = Object.keys(res.data.Result).length;

        if (arrlen > 0) {
          //addToast(res.data.message, { appearance: 'success',autoDismiss: true });            
          localStorage.setItem("RegisterId", JSON.stringify(res.data.Result.RegisterId));
          localStorage.setItem("Name", JSON.stringify(res.data.Result.Name));
          localStorage.setItem("Email", JSON.stringify(res.data.Result.Email));
          localStorage.setItem("Phone", JSON.stringify(res.data.Result.Phone));
          localStorage.setItem("EmailVerified", JSON.stringify(res.data.Result.EmailVerified));
          localStorage.setItem("MobileVerified", JSON.stringify(res.data.Result.MobileVerified));

          window.location.href = "/dashboard-new";
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          document.getElementById("txtEmail/Mo").value = "";
          document.getElementById("txtPassword").value = "";
        }
      });
  }
  return (
    <React.Fragment>
      <section>
        <div className="container">
          <div className="loginboxnew loginnewboxresponsive">
            <img className="loginlogo" src={mianheaderimg}></img>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="mainloginboxcontent">
                  <div className="text-center">
                    <img className="loginboximg" src={loginmainimg}></img>
                    <p className="lognmaintexteditnew">Login</p>
                    <p className="maindescriptiontextlogin">Welcome, Please put your login credentials below to start using the website</p>
                  </div>
                  <div className="maininputboxlogin">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Email / Phone Number</label>
                      <input type="email" className="form-control" id="txtEmail/Mo" placeholder="Enter Your Email / Phone Number" />
                    </div>
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Password</label>
                      <input type="password" className="form-control" id="txtPassword" placeholder="Enter Your Password" />
                    </div>
                    <div className="forgottextloginnew">
                      <a href="#">Forgot Password ?</a>
                    </div>
                    <div>
                      <button type="button" className="btn btn-primary btnloginnew" onClick={login}>Sign in</button>
                      <p className="footerlogintext" >Already Have an Account? <a href="register-your-profile"><span>Sign Up</span></a></p>
                    </div>
                  </div>
                </div>
                <div className="d-md-flex justify-content-between">
                  <div>
                    <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
                  </div>
                  <div className="d-flex">
                    <p className="footercopytextedit">T&C</p>
                    <p className="footercopytextedit2">Privacy</p>


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>

  );
}

export default Login
