import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";





class UiVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>

        <section>
          <div className="container">
            <div className="loginboxnew">
              <img className="loginlogo" src={mianheaderimg}></img>
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="mainloginboxcontent">
                    <div className="text-center">
                      <img className="loginboximg" src={loginmainimg}></img>
                      <p className="lognmaintexteditnew">Change Password</p>
                      <p className="maindescriptiontextlogin">Your new password must be different from previous used password</p>

                    </div>
                    <div className="maininputboxlogin">

                      <div className="mainformlogininput">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Current Password</label>
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Password" />
                      </div>
                      <div className="mainformlogininput">
                        <label htmlFor="exampleFormControlInput1" className="form-label">New Password</label>
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Password" />
                      </div>
                      <div className="mainformlogininput">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Confirm New Password</label>
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Password" />
                      </div>
                      <div>
                        <button type="button" className="btn btn-primary btnloginnew mb-0">Confirm Password</button>


                      </div>


                    </div>

                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
                    </div>
                    <div className="d-flex">
                      <p className="footercopytextedit">T&C</p>
                      <p className="footercopytextedit2">Privacy</p>


                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>

        </section>

      </React.Fragment>
    )
  }
}

export default UiVideo
