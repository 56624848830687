import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { size } from "lodash";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  CardTitle,
  CardBody,
  Label,
} from "reactstrap";
import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import images from "../../assets/images";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../store/actions";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
    };
    this.messageBox = null;
  }

  componentDidMount() {
    const { onGetChats, onGetGroups, onGetContacts, onGetMessages } =
      this.props;
    const { currentRoomId } = this.state;
    onGetChats();
    onGetGroups();
    onGetContacts();
    onGetMessages(currentRoomId);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props;
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }));
  };

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }));
  };

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  };

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }));
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props;
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId });
    onGetMessages(roomId);
  };

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props;
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    };
    this.setState({ curMessage: "" });
    onAddMessage(message);
  };

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
    }
  };

  onKeyPress = e => {
    const { key, value } = e;
    const { currentRoomId, currentUser } = this.state;
    if (key === "Enter") {
      this.setState({ curMessage: value });
      this.addMessage(currentRoomId, currentUser.name);
    }
  };

  //serach recent user
  searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  render() {
    const { chats, groups, contacts, messages } = this.props;
    const { currentRoomId, currentUser } = this.state;

    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>HR Dashboard | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <CardTitle className="mb-3">Dashboard</CardTitle>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              
              <Card>
                  <CardBody>
                      <Row>
                        <Col lg="8" md="12">
                          <Row>
                            <Col sm="4">
                              <a href="/ui-dropdowns">
                              <div className="d-main-box margin-bottom-20">
                                <div className="number-box">
                                  <div className="h5 mb-0 font-40">10</div>
                                </div>
                                <div className="number-box-text">
                                  <div className="h6 mb-0 font-15">Current Job <br></br>Openings</div>
                                </div>
                              </div>
                              </a>
                            </Col>
                            <Col sm="4">
                              <a href="/ui-buttons">
                              <div className="d-main-box margin-bottom-20">
                                <div className="number-box">
                                  <div className="h5 mb-0 font-40">20</div>
                                </div>
                                <div className="number-box-text">
                                  <div className="h6 mb-0 font-15">New Job <br></br>Application</div>
                                </div>
                              </div>
                              </a>
                            </Col>
                            <Col sm="4">
                             <a href="">
                             <div className="d-main-box">
                                <div className="number-box">
                                  <div className="h5 mb-0 font-40">100</div>
                                </div>
                                <div className="number-box-text">
                                  <div className="h6 mb-0 font-15">New Leave<br></br>Application</div>
                                </div>
                              </div>
                             </a>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col sm="12" md="6" className="margin-top-20">
                                <div className="leave-box">
                                  <div className="h5 mb-4">
                                    Leave Application
                                  </div>
                                  <div className="leave-sub-box">
                                      <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <div className="h6 font-16">Kushal Patel</div>
                                            <p className="mb-2">#KC0001</p>
                                            <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p>
                                        </div>
                                        <div className="cl-box">
                                            <div className=""><span className="cl-bg">CL</span><span className="num-bg">1</span></div>
                                        </div>
                                      </div>
                                     <div className="mt-4">
                                     <a href="" className="add-btn">Manager Status</a>
                                        <a href="" className="ms-2 add-btn">Approve</a>
                                        <a href="" className="ms-2 add-btn">Reject</a>
                                     </div>
                                  </div>
                                  <div className="leave-sub-box mt-3">
                                      <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <div className="h6 font-16">Utsav Shah</div>
                                            <p className="mb-2">#KC0001</p>
                                            <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p>
                                        </div>
                                        <div className="cl-box">
                                            <div className=""><span className="cl-bg">LWP</span><span className="num-bg">0.5</span></div>
                                        </div>
                                      </div>
                                      <div className="mt-4">
                                     <a href="" className="add-btn">Manager Status</a>
                                        <a href="" className="ms-2 add-btn">Approve</a>
                                        <a href="" className="ms-2 margin-top-10 add-btn">Reject</a>
                                     </div>
                                  </div>
                                  <div className="leave-sub-box mt-3">
                                      <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <div className="h6 font-16">Utsav Shah</div>
                                            <p className="mb-2">#KC0001</p>
                                            <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p>
                                        </div>
                                        <div className="cl-box">
                                            <div className=""><span className="cl-bg">CL</span><span className="num-bg">1</span></div>
                                        </div>
                                      </div>
                                      <div className="mt-4">
                                     <a href="" className="add-btn">Manager Status</a>
                                        <a href="" className="ms-2 add-btn">Approve</a>
                                        <a href="" className="ms-2 margin-top-10 add-btn">Reject</a>
                                     </div>
                                  </div>
                                </div>
                            </Col>
                            <Col sm="12" md="6" className="margin-top-20">
                                <div className="leave-box">
                                  <div className="heading-box mb-3">
                                        <div className="heading-box-1">
                                          <div className="h5">
                                            Followups
                                        </div>
                                        <div className="h5 font-15">
                                           <a href="">Add</a>
                                           <a href="" className="ms-2">View</a>
                                        </div>
                                    </div>
                                  </div>
                                  <div className="leave-sub-box">
                                      <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <p className="mb-0">when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div className="due-box ms-3">
                                            <div className=""><span className="due-bg">Due Today</span></div>
                                        </div>
                                      </div>
                                     <div className="m-21">
                                     <a href="" className="add-btn">Followup Date</a>
                                        <a href="" className="ms-2 add-btn">Followup Mode</a>
                                     </div>
                                  </div>
                                  <div className="leave-sub-box mt-3">
                                  <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <p className="mb-0">when an unknown took printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div className="due-box ms-3">
                                            <div className=""><span className="due-bg">Over Due</span></div>
                                        </div>
                                      </div>
                                     <div className="m-21">
                                     <a href="" className="add-btn">Followup Date</a>
                                        <a href="" className="ms-2 add-btn">Followup Mode</a>
                                     </div>
                                  </div>
                                  <div className="leave-sub-box mt-3">
                                  <div className="leave-sub-box-1">
                                        <div className="name-box">
                                            <p className="mb-0">when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div className="due-box ms-3">
                                            <div className=""><span className="due-bg">Due Today</span></div>
                                        </div>
                                      </div>
                                     <div className="m-21">
                                     <a href="" className="add-btn">Followup Date</a>
                                        <a href="" className="ms-2 add-btn">Followup Mode</a>
                                     </div>
                                  </div>
                                </div>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                          <Col sm={4} className="margin-top-20">
                              <div className="holiday-box">
                                <div className="heading-box">
                                          <div className="heading-box-1">
                                            <div className="font-15">
                                              Holiday List
                                          </div>
                                          <div className="">
                                            <a className="font-14" href="">View All</a>
                                          </div>
                                      </div>
                                      <div className="line-box"></div>
                                <div className="d-main-box-1 mt-3">
                                  <div className="number-box-1 text-center">
                                    <div className="h3 mb-1">10</div>
                                    <div className="h5 mb-0">July</div>
                                  </div>
                                  <div className="number-box-text">
                                    <div className="h6 mb-0 font-25">Rath Yatra</div>
                                  </div>
                                </div>
                                <div className="line-box"></div>
                                <div className="d-main-box-1 mt-3">
                                  <div className="number-box-1 text-center">
                                    <div className="h3 mb-1">20</div>
                                    <div className="h5 mb-0">Oct</div>
                                  </div>
                                  <div className="number-box-text">
                                    <div className="h6 mb-0 font-25">Dussehra</div>
                                  </div>
                                </div>
                                </div>
                               
                              </div>
                            </Col>
                            <Col sm={4} className="margin-top-20">
                              <div className="holiday-box">
                                <div className="heading-box">
                                          <div className="heading-box-1">
                                            <div className="font-15">
                                              Birthday List
                                          </div>
                                          <div className="">
                                            <a className="font-14" href="">View All</a>
                                          </div>
                                      </div>
                                      <div className="line-box"></div>
                                <div className="d-main-box-1 mt-3">
                                  <div className="number-box-1 text-center">
                                    <div className="h3 mb-1">20</div>
                                    <div className="h5 mb-0">Sep</div>
                                  </div>
                                  <div className="number-box-1 icon-box-hr text-center">
                                   <i className="mdi mdi-account h1"></i>
                                  </div>
                                  <div className="number-box-text">
                                  <div className="h6 mb-1">Achal Patel</div>
                                    <div className="font-14 mb-0">KC00009</div>
                                  </div>
                                </div>
                                <div className="line-box"></div>
                                <div className="d-main-box-1 mt-3">
                                  <div className="number-box-1 text-center">
                                    <div className="h3 mb-1">27</div>
                                    <div className="h5 mb-0">Sep</div>
                                  </div>
                                  <div className="number-box-1 icon-box-hr text-center">
                                   <i className="mdi mdi-account h1"></i>
                                  </div>
                                  <div className="number-box-text">
                                  <div className="h6 mb-1">Kushal Patel</div>
                                    <div className="font-14 mb-0">KC00029</div>
                                  </div>
                                </div>
                                </div>
                               
                              </div>
                            </Col>
                            <Col sm={4} className="margin-top-20">
                              <div className="holiday-box">
                                <div className="heading-box">
                                          <div className="heading-box-1">
                                            <div className="font-15">
                                              Work Anniversary
                                          </div>
                                          <div className="">
                                            <a className="font-14" href="">View All</a>
                                          </div>
                                      </div>
                                      <div className="line-box"></div>
                                <div className="d-main-box-1 mt-3">
                                  <div className="number-box-1 text-center">
                                    <div className="h3 mb-1">20</div>
                                    <div className="h5 mb-0">Sep</div>
                                  </div>
                                  <div className="number-box-1 icon-box-hr text-center">
                                   <i className="mdi mdi-account h1"></i>
                                  </div>
                                  <div className="number-box-text">
                                  <div className="h6 mb-1">Achal Patel</div>
                                    <div className="font-14 mb-0">KC00009</div>
                                  </div>
                                </div>
                                <div className="line-box"></div>
                                <div className="d-main-box-1 mt-3">
                                  <div className="number-box-1 text-center">
                                    <div className="h3 mb-1">27</div>
                                    <div className="h5 mb-0">Sep</div>
                                  </div>
                                  <div className="number-box-1 icon-box-hr text-center">
                                   <i className="mdi mdi-account h1"></i>
                                  </div>
                                  <div className="number-box-text">
                                  <div className="h6 mb-1">Kushal Kumar</div>
                                    <div className="font-14 mb-0">KC00029</div>
                                  </div>
                                </div>
                                </div>
                               
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="12" lg="4" className="margin-top-20">
                          <div className="hr-dashboard-box">
                            <div className="box-1 text-end">
                              <a href="/kc-todo" className="add-btn">Add New Task</a>
                              <a href="/kc-todo1" className="ms-2 add-btn">View All</a>
                            </div>
                            <div className="box-2">
                              <div className="box-2-text">
                                <div className="text-box">
                                  <div className="h6 mb-0">Some task Related text </div>
                                </div>
                                <div className="btn-box">
                                <a href="/kc-todo3" className="add-btn">Add To Do</a>
                                </div>
                              </div>
                            </div>
                            <p className="mt-2"><span className="h6">Description: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            <div className="box-2"></div>
                            <div className="m-16">
                            <div className="form-check mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                New Note With Attachment By 12th June2020<br></br> <span className="color-grey">TO Me & Kaushal</span>
                              </Label>
                            </div>
                            <p className="mt-2"><span className="h6">Notes: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex lead-box-1 p-7">
                                              <div className="ms-1 mt-1 mb-1">
                                              <p className="mb-0 font-25">2<i className="mdi mdi-attachment"></i></p>
                                              </div>
                                              <div className="ms-3">
                                                <p className="mb-1">KChistory.PDF</p>
                                                <p className="mb-0">1.26MB</p>
                                              </div>
                            </div>
                            <div className="ms-1 mt-1 mb-1  lead-box-1 p-18">
                                              <p className="mb-0">Over Due</p>
                                              </div>
                          </div>
                          <div className="box-2"></div>
                          <div className="m-16">
                            <div className="form-check mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                New Note With Attachment By 30th June2020<br></br> <span className="color-grey">TO Me</span>
                              </Label>
                            </div>
                            <p className="mt-2"><span className="h6">Notes: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            </div>
                            <div className="box-2">
                              <div className="box-2-text">
                                <div className="text-box">
                                  <div className="h6 mb-0">Some task Related text </div>
                                </div>
                                <div className="btn-box">
                                <a href="/kc-todo3" className="add-btn">Add To Do</a>
                                </div>
                              </div>
                            </div>
                            <p className="mt-2"><span className="h6">Description: </span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy
                            </p>
                            </div>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
});

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
