import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const options = [
    { value: 'luna', label: 'Moon' },
    { value: 'phobos', label: 'Phobos' },
    { value: 'deimos', label: 'Deimos' },
    { value: 'io', label: 'Io' },
    { value: 'europa', label: 'Europa' },
    { value: 'ganymede', label: 'Ganymede' },
    { value: 'callisto', label: 'Callisto' },
    { value: 'mimas', label: 'Mimas' },
    { value: 'enceladus', label: 'Enceladus' },
    { value: "tethys", label: 'Tethys' },
    { value: 'rhea', label: 'Rhea' },
    { value: 'titan', label: 'Titan' },
];

const Optgroup = [
    {
        label: 'Earth',
        options: [
            { value: 'luna', label: 'Moon' },
        ],
    },
    {
        label: 'Mars',
        options: [
            { value: 'phobos', label: 'Phobos' },
            { value: 'deimos', label: 'Deimos' },
        ],
    },
    {
        label: 'Jupiter',
        options: [
            { value: 'io', label: 'Io' },
            { value: 'europa', label: 'Europa' },
            { value: 'ganymede', label: 'Ganymede' },
            { value: 'callisto', label: 'Callisto' },
        ],
    },
];

const available = ['io', 'europa', 'ganymede', 'callisto'];

class DualListbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: ['phobos', 'titan', 'deimos'],
            selectedOptGroup: ['luna'],
            selectedFilter: ['luna'],
            selectedFilterAlign: ['luna'],
            selectedPreserve: ['luna'],
            selectedRestrict: ['luna'],
            selectedDuplicate: ['luna'],
        }
    }
    onChange = (selected) => {
        this.setState({ selected });
    };

    onOptGroupChange = (selectedOptGroup) => {
        this.setState({ selectedOptGroup });
    };

    onFilterChange = (selectedFilter) => {
        this.setState({ selectedFilter });
    };

    onFilterAlignChange = (selectedFilterAlign) => {
        this.setState({ selectedFilterAlign });
    };

    onPreserveChange = (selectedPreserve) => {
        this.setState({ selectedPreserve });
    };

    onRestrictChange = (selectedRestrict) => {
        this.setState({ selectedRestrict });
    };

    onDuplicateChange = (selectedDuplicate) => {
        this.setState({ selectedDuplicate });
    };

    render() {
        const { selected, selectedOptGroup, selectedFilter, selectedFilterAlign, selectedPreserve, selectedDuplicate, selectedRestrict } = this.state;
        const available = ['io', 'europa', 'ganymede', 'callisto'];
        return (
            <React.Fragment>
                   {/* country list */}
                   <div className ="table-responsive mb-5">
                        <table className="table table-striped mb-0 table">
                            <tbody className="">
                                <tr>
                                    <th className="width-20" scope="row">1</th>
                                    <td className="width-100">India</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="width-50">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Australia</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>USA</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Canada</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>China</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>Japan</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                  </div>
            </React.Fragment>
        )
    }
}

export default DualListbox