import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { size } from "lodash";

import { chatData } from "../../common/data";
import Reciver from "./Reciver";
import Sender from "./Sender";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

class ChatBox extends Component {
  constructor(props) {
    super(props);
    (this.state = {
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      messages: [...chatData],
      text: "",
    }),
      (this.messageBox = null);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.toggleOther = this.toggleOther.bind(this);
    this.onSendMessage = this.onSendMessage.bind(this);
  }

  //Toggle Chat Box Menus
  toggleSearch() {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }));
  }

  toggleSettings() {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  }

  toggleOther() {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }));
  }

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
    }
  };

  onSendMessage() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const name = obj && obj.username ? obj.username : "Admin";

    var modifiedMessages = [...this.state.messages];
    const lastItem = modifiedMessages.length
      ? modifiedMessages[modifiedMessages.length - 1]
      : { id: 1 };
    const today = new Date();
    const hour = today.getHours();
    const minute = today.getMinutes();
    const senderObj = {
      id: lastItem["id"] + 1,
      name: name,
      msg: this.state.text,
      time: `${hour}.${minute}`,
      isSender: true,
    };
    modifiedMessages.push({ ...senderObj });
    this.setState({ messages: modifiedMessages });
    this.setState({ text: "" });
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.state;
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  render() {
    return (
      <React.Fragment>
       
      </React.Fragment>
    );
  }
}

ChatBox.propTypes = {
  messages: PropTypes.any,
};

export default ChatBox;
