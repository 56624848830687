import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/userdefaultimage.jpg";
import otpicon from "../../assets/images/frontend-img/otpicon.png";



import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import axios from "axios";
import Commheader from "../frontend/header";

const EditProfile = () => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [DOB, setDOB] = useState('');
  const [Gender, setGender] = useState('');

  const [ProfileFileArr, setProfileFileArr] = useState([]);
  const [IsSelectProfileFile, setIsSelectProfileFile] = useState(0);
  const [ProfileFileUrl, setProfileFileUrl] = useState(profileimg);
  const [ShowLoader, setShowLoader] = useState(0);

  const getProfileData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/MyProfileData', formData)
      .then(res => {
        setEmail(res.data.Result[0].Email);
        setName(res.data.Result[0].Name);
        setMobileNo(res.data.Result[0].MobileNo)
        setGender(res.data.Result[0].Gender)
        setDOB(res.data.Result[0].EditDateOfBirth)
      });

      setShowLoader(0);
  }

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      setShowLoader(1);
      getProfileData();
    }
  }, []);

  const UpdateProfile = async () => {
    setShowLoader(1);
    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    formData.append('RegisterId', VRegisterId);
    formData.append('Name', Name);
    formData.append('MobileNumber', MobileNo);
    formData.append('Email', Email);
    formData.append('OTPType', '');
    formData.append('DeviceInfo', '');
    formData.append('Gender', Gender);
    formData.append('ProfilePhoto', IsSelectProfileFile==1?ProfileFileArr:'');
    formData.append('DOB', DOB);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ':' + pair[1]);
    }
    axios.post(global.APIURL + '/EditProfileData', formData).then(res => {
      setShowLoader(0);
      alert(res.data.StatusMessage);
      setIsSelectProfileFile(0);
      window.location.href = "/manage-profile";
    });
    setShowLoader(0);
  }

  function handleChange(e) {
    setShowLoader(1);
    setProfileFileArr(e.target.files[0]);
    setProfileFileUrl(URL.createObjectURL(e.target.files[0]));
    setIsSelectProfileFile(1);
    setShowLoader(0);
  }

  return (
    <React.Fragment>
{ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row">
              <div className="col-md-12">
                <p className="addservicetextform">Edit Profile</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainboxmpnew">
                  <div className="row gx-4">
                    <div className="col-md-10">
                      <div className="row gx-4">
                        <div className="col-md-6">
                          <div className="registerinput mt-0">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                            <input type="email" className="form-control " id="exampleFormControlInput1" placeholder="Martine Ferry" defaultValue={Name} onChange={e => setName(e.target.value)} />
                          </div>

                        </div>

                        <div className="col-md-6">
                          <div className="registerinput mt-0">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control " placeholder="Buford.Gutmann@gmail.com" aria-label="Recipient's username" aria-describedby="button-addon2" defaultValue={Email} onChange={e => setEmail(e.target.value)} />

                            </div>



                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control " placeholder="+91 7896589963" aria-label="Recipient's username" aria-describedby="button-addon2" defaultValue={MobileNo} onChange={e => setMobileNo(e.target.value)} />

                            </div>

                          </div>


                        </div>

                        <div className="col-md-6">
                          <div className="registerinput">
                            <label htmlFor="exampleFormControlInput1" className="form-label">DOB</label>
                            <input type="date" className="form-control " id="exampleFormControlInput1" placeholder="19th January 2000" onChange={e => setDOB(e.target.value)} defaultValue={DOB} />
                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">

                            <label htmlFor="exampleFormControlInput1" className="form-label">Gender</label>
                            <select className="form-select" aria-label="Default select example" onChange={e => setGender(e.target.value)}>
                              <option selected>Select Gender</option>
                              <option value="Male" selected={Gender==='Male'}>Male</option>
                              <option value="Female" selected={Gender==='Female'}>Female</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="registerinput mt-0">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                        <div>
                          <img src={ProfileFileUrl} className="imgprofileedit"></img>
                        </div>

                      </div>
                      <div >
                        <button type="button" className="btn btn-primary btnuploadnew" id="btnProfileImage" >Upload </button>
                        <input type="file" accept="image/*" id="fuProfileImage" onChange={handleChange} className="d-none" />
                      </div>

                    </div>
                    <div className="col-md-12">
                      <button type="button" className="btn btn-primary btnnextedit " onClick={UpdateProfile}>Save </button>

                    </div>

                  </div>
                </div>

              </div>

            </div>



          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )


}

export default EditProfile
