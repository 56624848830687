import React, { Component, useEffect, useState } from "react"
import axios from "axios";
import Commheader from "../frontend/header";
import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";

const transactionhistory = () => {
  const [TransactionHistoryList, setTransactionHistoryList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);
  useEffect(() => {
    setShowLoader(1);
    getMyReferralsList();
  }, []);

  const getMyReferralsList = () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/TransactionHistory', formData)
      .then(LPres => {
        let LCD = LPres.data.Result.map((lcdeatail) => {
          return lcdeatail
        })
        setTransactionHistoryList(LCD);
      });
      setShowLoader(0);
  }
  return (
    <React.Fragment>
      {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-6">
                <p className="addservicetextform">Transaction History</p>
              </div>
              <div className="col-md-6 text-end">
                <div className="d-flex justify-content-end">
                  <div>
                    <select className="form-select formdropdown form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                      <option selected>Last 7 Days</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div>
                    <button type="button" className="btn btn-warning btnpaymentdownload"><i className="las la-download"></i></button>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="registerinput registerinputnewwed">
                  <div className="d-flex align-items-center">
                    <div>
                      <label htmlFor="exampleFormControlInput1" className="form-label">Name : </label>
                    </div>
                    <div>
                      <input type="email" className="form-control" id="exampleFormControlInput1" readOnly />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="maintabledivedit">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="mainwidthtable" scope="col">Date</th>
                        <th className="mainwidthtable" scope="col">Payment Status</th>
                        <th className="mainwidthtable" scope="col">Payment Details</th>
                        <th className="mainwidthtable2" scope="col">Payment Mode</th>
                        <th className="mainwidthtable2" scope="col">Amount</th>

                      </tr>
                    </thead>
                    <tbody>
                      {TransactionHistoryList.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            <p className="mainpropertytitlelistms">{item.Date} <span>|</span> {item.Time}</p>
                          </td>
                          <td>
                            <p className="mainpropertytitlelistms ap-text">{item.PaymentFor}</p>
                          </td>
                          <td>
                            <p className="mainpropertytitlelistms">Payment Against #SR{item.PaymentFor.length==1?'000000'+item.PaymentFor:item.PaymentFor.length==2?'00000'+item.PaymentFor:item.PaymentFor.length==3?'0000'+item.PaymentFor:item.PaymentFor.length==4?'000'+item.PaymentFor:item.PaymentFor.length==5?'00'+item.PaymentFor:item.PaymentFor.length==6?'0'+item.PaymentFor:item.PaymentFor}</p>
                          </td>
                          <td>
                            <p className="mainpropertytitlelistms">{item.Type}</p>
                          </td>
                          <td>
                            <p className="mainpropertytitlelistms maininrtext">{item.Amount}</p>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default transactionhistory
