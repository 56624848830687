import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { isEmpty, size } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  CardTitle,
 
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  getCustomers,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
} from "store/e-commerce/actions";

class EcommerceCustomers extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      customers: [],
      customer: "",
      deleteModal: false,
      EcommerceCustomerColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "username",
          text: "Username",
          sort: true,
        },
        {
          text: "Phone / Email",
          dataField: "phone",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.phone}</p>
              <p className="mb-0">{row.email}</p>
            </>
          ),
        },
        {
          dataField: "address",
          text: "Address",
          sort: true,
        },
        {
          dataField: "rating",
          text: "Rating",
          sort: true,
          formatter: (cellContent, row) => (
            <Badge color="success" className="bg-success font-size-12">
              <i className="mdi mdi-star me-1" />
              {row.rating}
            </Badge>
          ),
        },
        {
          dataField: "walletBalance",
          text: "Wallet Balance",
          sort: true,
        },
        {
          dataField: "joiningDate",
          text: "Joining Date",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.joiningDate),
        },
        {
          dataField: "menu",
          isDummyField: true,
          text: "Action",
          formatter: (cellContent, customer) => (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => this.handleCustomerClick(customer)}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  Edit
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => this.onClickDelete(customer)}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
    };
    this.handleCustomerClick = this.handleCustomerClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleCustomerClicks = this.handleCustomerClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const { customers, onGetCustomers } = this.props;
    if (customers && !customers.length) {
      onGetCustomers();
    }
    this.setState({ customers });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { customers } = this.props;
    if (!isEmpty(customers) && size(prevProps.customers) !== size(customers)) {
      this.setState({ customers: {}, isEdit: false });
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleCustomerClicks = arg => {
    this.setState({ customer: arg });
    this.toggle();
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (customer) => {
    this.setState({ customer: customer });
    this.setState({ deleteModal: true });
  };

  handleDeleteCustomer = () => {
    const { onDeleteCustomer } = this.props;
    const { customer } = this.state;
    if (customer.id !== undefined) {
      onDeleteCustomer(customer);
      this.onPaginationPageChange(1);
      this.setState({ deleteModal: false });
    }
  };

  handleCustomerClick = arg => {
    const customer = arg;

    this.setState({
      customer: {
        id: customer.id,
        username: customer.username,
        phone: customer.phone,
        email: customer.email,
        address: customer.address,
        rating: customer.rating,
        walletBalance: customer.walletBalance,
        joiningDate: customer.joiningDate,
      },
      isEdit: true,
    });

    this.toggle();
  };

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  /* Insert,Update Delete data */

  render() {
    const { customers } = this.props;

    const customer = this.state.customer;

    const { isEdit,deleteModal } = this.state;

    const { onAddNewCustomer, onUpdateCustomer } = this.props;

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: customers.length, // replace later with size(customers),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const { SearchBar } = Search;

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>GST Report Generator | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">GST 1/2a/2b/3b Report Generator</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">GST</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
             
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  className: PropTypes.any,
};

const mapStateToProps = ({ ecommerce }) => ({
  customers: ecommerce.customers,
});

const mapDispatchToProps = dispatch => ({
  onGetCustomers: () => dispatch(getCustomers()),
  onAddNewCustomer: customer => dispatch(addNewCustomer(customer)),
  onUpdateCustomer: customer => dispatch(updateCustomer(customer)),
  onDeleteCustomer: customer => dispatch(deleteCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceCustomers);