import React, { Component } from "react"
import { Row, Col, Card } from "reactstrap"

//Import Images
import profileImg from "../../assets/images/profile-img.png"

class CardWelcome extends Component {
  render() {
    return (
      <React.Fragment>
          {/* country list */}
          <div className ="table-responsive mb-5">
                        <table className="table table-striped mb-0 table">
                            <tbody className="">
                                <tr>
                                    <th className="width-20" scope="row">1</th>
                                    <td className="width-100">India</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="width-50">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Australia</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>USA</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Canada</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>China</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>Japan</td>
                                    <td><a hrefLang="#" className="table-link">StateList</a></td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                  </div>
      </React.Fragment>
    )
  }
}

export default CardWelcome
