import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Commheader from "../frontend/header";
import { GoogleComponent } from 'react-google-location';


const addPropertyPage = () => {

  const { addToast } = useToasts();
  const [PropertyNumber, setPropertyNumber] = useState([]);
  const [NearByPlace, setNearByPlace] = useState([]);
  const [Street, setStreet] = useState([]);
  const [PinCode, setPinCode] = useState([]);
  const [PropertyType, setPropertyType] = useState([]);
  const [PropertyTypeId, setPropertyTypeId] = useState([]);
  const [FullAddress, setFullAddress] = useState([]);
  const [PropertyTitle, setPropertyTitle] = useState([]);
  const [MediaList, setMediaList] = useState([]);
  const [CountryId, setCountryId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [CountryList, setCountryList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [StateFilterList, setStateFilterList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [StateId, setStateId] = useState([]);
  const [OtherPropertyType, setOtherPropertyType] = useState([]);


  const addNewProperty = async () => {

    let IsInsert = 1;
    if (PropertyTitle == "") {
      addToast("Add Property Title", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (MediaList.length <= 0) {
      addToast("Add Property Images", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (PropertyTypeId == "") {

      addToast("Select Property Type", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (PropertyTypeId == 0) {
      if (OtherPropertyType == "") {
        addToast("Add Property Type", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
        return;
      }
    }
    else if (PropertyNumber == "") {

      addToast("Add Property Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (CountryId == "") {
      addToast("Select Country", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (StateId == "") {
      addToast("Select State", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (CityId == "") {
      addToast("Select City", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }


    if (IsInsert > 0) {
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));

      const formData = new FormData();

      formData.append('SaveAction', 'Save');
      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('TotalMediaCount', MediaList.length);
      formData.append('PropertyTypeID', PropertyTypeId);
      formData.append('OtherPropertyType', OtherPropertyType);
      formData.append('PropertyTitle', PropertyTitle);
      formData.append('PropertyNumber', PropertyNumber);
      formData.append('NearByPlace', NearByPlace);
      formData.append('Street', Street);
      formData.append('Pincode', PinCode);
      formData.append('City', CityId);
      formData.append('State', StateId);
      formData.append('Country', CountryId);
      formData.append('FullAddress', FullAddress);
      formData.append('Latitude', "");
      formData.append('Longitude', "");

      for (let di = 0; di < MediaList.length; di++) {

        let RCount = di + 1;
        let VMedia = MediaList[di].MediaFile;
        let CM = 'Media' + RCount;
        formData.append(CM, VMedia);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }

      axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
        .then(res => {
          console.log("add-res", res);
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.setItem('PropertyId', JSON.stringify(res.data.PropertyId));
            window.location.href = "/add-new-property2";
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });
    }
  }

  const addNewPropertyInDraft = async () => {

    let IsInsert = 1;
    if (PropertyTitle == "") {
      addToast("Add Property Title", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (MediaList.length <= 0) {
      addToast("Add Property Images", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (PropertyTypeId == "") {
      addToast("Select Property Type", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (PropertyTypeId == 0) {
      if (OtherPropertyType == "") {
        addToast("Add Property Type", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
        return;
      }
    }
    else if (PropertyNumber == "") {

      addToast("Add Property Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (CountryId == "") {
      addToast("Select Country", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (StateId == "") {
      addToast("Select State", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    else if (CityId == "") {
      addToast("Select City", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }


    if (IsInsert > 0) {
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));

      const formData = new FormData();

      formData.append('SaveAction', 'Draft');
      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('TotalMediaCount', MediaList.length);
      formData.append('PropertyTypeID', PropertyTypeId);
      formData.append('OtherPropertyType', OtherPropertyType);
      formData.append('PropertyTitle', PropertyTitle);
      formData.append('PropertyNumber', PropertyNumber);
      formData.append('NearByPlace', NearByPlace);
      formData.append('Street', Street);
      formData.append('Pincode', PinCode);
      formData.append('City', CityId);
      formData.append('State', StateId);
      formData.append('Country', CountryId);
      formData.append('FullAddress', FullAddress);
      formData.append('Latitude', "");
      formData.append('Longitude', "");

      for (let di = 0; di < MediaList.length; di++) {

        let RCount = di + 1;
        let VMedia = MediaList[di].MediaFile;
        let CM = 'Media' + RCount;
        formData.append(CM, VMedia);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }

      axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
        .then(res => {
          console.log("add-res", res);
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.setItem('PropertyId', JSON.stringify(res.data.PropertyId));
            window.location.href = "/add-new-property2";

          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }

        });

    }



  }

  const getPropertyType = async () => {

    axios.get(global.APIURL + '/PropertyType')
      .then(res => {
        setPropertyType(res.data.Result);
      });
  }

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getPropertyType();

    }
    getCountryList();

  }, []);

  const getCountryList = () => {
    CountryList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        var CLA = [];
        var SLA = [];
        var CTA = [];
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CLA.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;

            const Stateitem = {
              CountryId: cl[cd].Id,
              StateId: Sl[sd].Id,
              StateName: Sl[sd].StateName
            };
            SLA.push(Stateitem);

            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                StateId: StateId,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CTA.push(Cityitem);
            }
          }
        }
        setCountryList(CLA);
        setStateFilterList(SLA);
        setCityFilterList(CTA);
      });
  }
  const getCountryChange = (CountryId) => {
    var SF = StateFilterList.filter(x => x.CountryId == CountryId);
    setStateList(SF);
  }

  const getStateChange = (StateId) => {
    var CF = CityFilterList.filter(x => x.StateId == StateId);
    setCityList(CF);
  }


  function handleChange(e) {


    const MitemArr = {
      PId: MediaList.length + 1,
      MediaFile: e.target.files[0],
      MediaFileUrl: URL.createObjectURL(e.target.files[0])
    }
    setMediaList([...MediaList, MitemArr]);
  }
  return (<React.Fragment>
    <Commheader />
    <section>
      <div className="container addnewpropertymaindiv">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-12">
              <p className="addservicetextform">Add New Properties</p>
            </div>
            <div className="col-md-12">
              <p className="mainsteptextform">Step 1 : Property Detail</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mainboxnewaddproperty">
                <div className="row align-items-center">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <p className="maintitlereuestservicenee">Property Title </p>
                          <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setPropertyTitle(e.target.value)} placeholder="Enter Property Title" required />
                        </div>
                      </div>
                    </div>
                    <div className="mainredioboxeditap">
                      <p className="maintitlereuestservicenee">Property Type</p>
                      <div className="d-flex align-items-center mainpropertyaddvalue">
                        <div>
                          {PropertyType.map((propItems) => <div className="form-check form-check-inline" key={propItems.Id}>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={propItems.Id} onChange={(e) => setPropertyTypeId(e.target.value)} required />
                            <label className="form-check-label commonblackcolor" htmlFor="inlineRadio1">{propItems.PropertyType}</label>
                          </div>)}

                          <div className="form-check form-check-inline" >
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="0" onChange={(e) => setPropertyTypeId(e.target.value)} />
                            <label className="form-check-label commonblackcolor" htmlFor="inlineRadio1">Other</label>
                          </div>
                        </div>
                        <div className="mainwritehereadd">
                          <div className="registerinputnewedirr form-controlsustomeditnew">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Write Here" onChange={(e) => setOtherPropertyType(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="row mainsideanpimgdiv">
                      {MediaList.map((item, idx) => (
                        <div className="col-4" key={idx}>
                          <div className="mainanpimg">
                            <img src={item.MediaFileUrl}></img>
                          </div>
                        </div>
                      ))}
                      {MediaList.length < 6 ?
                        <div className="col-4">
                          <div className="mainanpimg">

                            <a href="#" id="btnUploadPropertyImage">   <img src={addanp}></img></a>

                            <input type="file" accept="image/*" id="fuUploadPropertyImage" onChange={handleChange} className="d-none" />
                          </div>
                        </div>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mainboxnewaddproperty mb-0">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <p className="blutextheadingeditt">Location Information</p>
                  </div>
                  <div className="col-xl-7">
                    <div className="row mainlocationinfodivv">
                      <div className="col-md-6">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <p className="maintitlereuestservicenee">Property Number </p>
                          <input type="number" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setPropertyNumber(e.target.value)} placeholder="Enter House / Property Number" required />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <p className="maintitlereuestservicenee">Near By Address</p>
                          <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setNearByPlace(e.target.value)} placeholder="Enter Near by Address" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <p className="maintitlereuestservicenee">Street</p>
                          <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setStreet(e.target.value)} placeholder="Enter Street Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <p className="maintitlereuestservicenee">Pin Code</p>
                          <input type="number" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setPinCode(e.target.value)} placeholder="Enter Pin Code" />
                        </div>

                      </div>
                      <div className="col-md-4">
                        <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                          <p className="maintitlereuestservicenee">Country</p>

                          <select id="ddlCountry" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" required onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                            <option selected>Select Country</option>
                            {CountryList.map((CountryListItems) => (
                              <option key={CountryListItems.CountryId} value={CountryListItems.CountryId}> {CountryListItems.CountryName} </option>
                            ))}
                          </select>
                        </div>

                      </div>
                      <div className="col-md-4">
                        <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                          <p className="maintitlereuestservicenee">State</p>
                          <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" required onChange={(e) => { getStateChange(e.target.value); setStateId(e.target.value) }}>
                            <option selected>Select State</option>
                            {StateList.map((StateItems) => (
                              <option key={StateItems.StateId} value={StateItems.StateId}> {StateItems.StateName} </option>
                            ))}
                          </select> </div>

                      </div>
                      <div className="col-md-4">
                        <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                          <p className="maintitlereuestservicenee">City</p>
                          <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" required onChange={(e) => setCityId(e.target.value)}>
                            <option selected>Select City</option>
                            {CityList.map((CityItems) => (
                              <option key={CityItems.CityId} value={CityItems.CityId}> {CityItems.CityName} </option>
                            ))}
                          </select></div>

                      </div>

                    </div>


                  </div>
                  <div className="col-xl-5">
                    <p className="maintitlereuestservicenee">Location  </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4079037565034!2d72.50534231495733!3d23.04550252125371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bb51d412b01%3A0x4c5338c9649c6eab!2sZion%20Z1!5e0!3m2!1sen!2sin!4v1661162103882!5m2!1sen!2sin" width="100%" height="191" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="mainlocationpreview"></iframe>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setFullAddress(e.target.value)} placeholder="Enter Your Address" />


                    </div>
                    {/* <GoogleComponent
                     apiKey={API_KEY}
                     language={'en'}
                     country={'country:in|country:us'}
                     coordinates={true}
                     locationBoxStyle={'custom-style'}
                     locationListStyle={'custom-style-list'}
                     onChange={(e) => { this.setState({ place: e }) }} />                    */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mainaddpropertybutton">
              {/* <a href="/add-new-property2">*/} <button type="button" className="btn btn-primary btnnextedit " onClick={addNewProperty}>Next <i className="fas fa-long-arrow-alt-right"></i></button> {/* </a> */}
              <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>
              <a href="#">  <button type="button" className="btn btn-primary btnnexteditline" onClick={addNewPropertyInDraft}>Save as Draft </button></a>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mainfootertexteditadd">
          <div>
            <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
          </div>
          <div className="d-flex">
            <p className="footercopytextedit">T&C</p>
            <p className="footercopytextedit2">Privacy</p>
          </div>

        </div>

      </div>
    </section>
  </React.Fragment>)
}


export default addPropertyPage
