import React, { Component, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import axios from "axios";
import { ToastProvider, useToasts } from 'react-toast-notifications';


const RegisterYourProfile = () => {

  const { addToast } = useToasts();

  const [EmailVerify, setEmailVerify] = useState(0);
  const [MobileVerify, setMobileVerify] = useState(0);
  const [EmailVerifySuccess, setEmailVerifySuccess] = useState(0);
  const [MobileVerifySuccess, setMobileVerifySuccess] = useState(0);
  const [ProfilePic, setProfilePic] = useState(0);
  const [UserEmail, setUserEmail] = useState([]);
  const [EmailOTP, setEmailOTP] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [MobileOTP, setMobileOTP] = useState([]);
  const [ProfilepicUrl,setProfilepicUrl]=useState([]);

  const sendEmailOTP = async () => {


    if (UserEmail != "") {
      const formData = new FormData();

      formData.append('MobileNumber', "");
      formData.append('Email', UserEmail);
      formData.append('OTPType', "Email");
      formData.append('DeviceInfo', "");

      console.log("VEmail", UserEmail);

      axios.post(global.APIURL + '/SendOTP', formData)
        .then(res => {
          console.log("resapi", res);
          //  addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
          if (res.data.Status == true) {
            setEmailVerify(1);
          }
          else {
            setEmailVerify(0);
          }
          console.log("EmailVerify", EmailVerify);

        });
    }
    else {
      alert("Enter Email Address");
    }
  }

  const verifyEmailOTP = async () => {

    const formData = new FormData();

    formData.append('MobileNumber', "");
    formData.append('Email', UserEmail);
    formData.append('OTPType', "Email");
    formData.append('OTP', EmailOTP);

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        console.log("resapi", res);
        //alert(res.data.StatusMessage);
       // addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
        if (res.data.Status == true) {
          setEmailVerify(0);
          setEmailVerifySuccess(1);
        }
        else {
          setEmailVerify(1);
          setEmailVerifySuccess(0);
        }

      });

  }



  const sendMobileOTP = async () => {

    if (MobileNo != "") {
      const formData = new FormData();

      formData.append('MobileNumber', MobileNo);
      formData.append('Email', "");
      formData.append('OTPType', "Mobile");
      formData.append('DeviceInfo', "");

      axios.post(global.APIURL + '/SendOTP', formData)
        .then(res => {
          console.log("resMobileapi", res);
         // addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
          if (res.data.Status == true) {
            setMobileVerify(1);
          }
          else {
            setMobileVerify(0);
          }
        });
    }
    else {
      alert("Enter Mobile Number")
    }
  }

  const verifyMobileOTP = async () => {

    const formData = new FormData();
    formData.append('MobileNumber', MobileNo);
    formData.append('Email', "");
    formData.append('OTPType', "Mobile");
    formData.append('OTP', MobileOTP);

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        console.log("resapi", res);
        //alert(res.data.StatusMessage);
        //addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
        
        if (res.data.Status == true) {
          setMobileVerify(0);
          setMobileVerifySuccess(1);
        }
        else {
          setMobileVerify(1);
          setMobileVerifySuccess(0);
        }

      });
  }

 

  const register = async () => {

    let VName = document.getElementById("txtName").value;
    let VGender = document.getElementById("txtGender").value;
    let VDOB = document.getElementById("txtDOB").value;
    let VPassword = document.getElementById("txtPassword").value;

    const formData = new FormData();

    formData.append('Name', VName);
    formData.append('MobileNumber', MobileNo);
    formData.append('Email', UserEmail);
    formData.append('OTPType', "Email");
    formData.append('DeviceInfo', "");
    formData.append('Gender', VGender);
    formData.append('ProfilePhoto', ProfilePic);
    formData.append('DOB', VDOB);
    formData.append('Password', VPassword);
    console.log("VGender", VGender);

    axios.post(global.APIURL + '/Register', formData)
      .then(res => {
        console.log("registerresapi", res);
        console.log("registerresapi", res.data.Result.Email);
       if(res.data.status==true)
       {
        //addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true });
        alert(res.data.StatusMessage);
        localStorage.setItem("RegisterId",JSON.stringify(res.data.Result.RegisterId));
        localStorage.setItem("Name",JSON.stringify(res.data.Result.Name));
         localStorage.setItem("Email",JSON.stringify(res.data.Result.Email));
         localStorage.setItem("MobileNo",JSON.stringify(res.data.Result.MobileNo));
         localStorage.setItem("EmailVerified",JSON.stringify(res.data.Result.EmailVerified));
         localStorage.setItem("MobileVerified",JSON.stringify(res.data.Result.MobileVerified));
         window.location.href = "/dashboard-new";
        
       }
       if(res.data.Status==false)
       {
        addToast(res.data.StatusMessage , { appearance: 'error',autoDismiss: true });
       }
         
      });
  }
  console.log("ProfilePic", ProfilePic);

  const verfication=()=>{

    if(MobileVerifySuccess == 0 && EmailVerifySuccess == 1)
      {
     
        let VOTP1=document.getElementById("OTP1").value;
        let VOTP2=document.getElementById("OTP2").value;
        let VOTP3=document.getElementById("OTP3").value;
        let VOTP4=document.getElementById("OTP4").value;
    
        const OTP=VOTP1+VOTP2+VOTP3+VOTP4;
        console.log("OTP",OTP);
    
        const formData = new FormData();
        formData.append('MobileNumber', MobileNo);
        formData.append('Email', "");
        formData.append('OTPType', "Mobile");
        formData.append('OTP', OTP);
    
        axios.post(global.APIURL + '/OtpVerify', formData)
          .then(res => {
            console.log("resapi", res);
            alert(res.data.StatusMessage);
            //addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
            
            if (res.data.Status == true) {
             register();
            }             
          });
      }
      else if(MobileVerifySuccess == 1 && EmailVerifySuccess == 0)
      {

        const formData = new FormData();

        let VOTP1=document.getElementById("OTP5").value;
        let VOTP2=document.getElementById("OTP6").value;
        let VOTP3=document.getElementById("OTP7").value;
        let VOTP4=document.getElementById("OTP8").value;
    
        const OTP=VOTP1+VOTP2+VOTP3+VOTP4;
        console.log("OTP",OTP);
    
        formData.append('MobileNumber', "");
        formData.append('Email', UserEmail);
        formData.append('OTPType', "Email");
        formData.append('OTP', OTP);
    
        axios.post(global.APIURL + '/OtpVerify', formData)
          .then(res => {
            console.log("resapi", res);
            alert(res.data.StatusMessage);
           // addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
            if (res.data.Status == true) {
           
              register();
            }
              
          });
      }
      else if(MobileVerifySuccess == 0 && EmailVerifySuccess == 0 )
      {
        let VOTP1=document.getElementById("OTP1").value;
        let VOTP2=document.getElementById("OTP2").value;
        let VOTP3=document.getElementById("OTP3").value;
        let VOTP4=document.getElementById("OTP4").value;
    
        const OTP=VOTP1+VOTP2+VOTP3+VOTP4;
        console.log("OTP",OTP);
    
        const formData = new FormData();
        formData.append('MobileNumber', MobileNo);
        formData.append('Email', "");
        formData.append('OTPType', "Mobile");
        formData.append('OTP', OTP);
    
        axios.post(global.APIURL + '/OtpVerify', formData)
          .then(res => {
            console.log("resapi", res);
            alert(res.data.StatusMessage);
            //addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
            
            if (res.data.Status == true) {
             register();
            }             
          });

          const formData1 = new FormData();

          let VOTP5=document.getElementById("OTP5").value;
          let VOTP6=document.getElementById("OTP6").value;
          let VOTP7=document.getElementById("OTP7").value;
          let VOTP8=document.getElementById("OTP8").value;
      
          const OTP2=VOTP5+VOTP6+VOTP7+VOTP8;
          console.log("OTP",OTP);
      
          formData1.append('MobileNumber', "");
          formData1.append('Email', UserEmail);
          formData1.append('OTPType', "Email");
          formData1.append('OTP', OTP2);
      
          axios.post(global.APIURL + '/OtpVerify', formData1)
            .then(res => {
              console.log("resapi", res);
              alert(res.data.StatusMessage);
             // addToast(res.data.StatusMessage, { appearance: 'success',autoDismiss: true }); 
              if (res.data.Status == true) {
             
                register();
              }
                
            });

      }

    
  }

  return (
    <React.Fragment>
      <MetaTags  >
        <title>Register|</title>
      </MetaTags>

      {MobileVerifySuccess == 0 && EmailVerifySuccess == 0 ? <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="verificationeditext">
                Verification
              </p>
              <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close" >Skip</button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img src={otpicon} ></img>
              </div>
              <div>
                {MobileVerifySuccess == 0 ? <form action="" className="formotp">
                  <div>
                    <p className="otpverificationtitlenew">Mobile Number Verification</p>
                  </div>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP1"/>
                  <input className="otp mr1vw" type="text" on maxLength='1' id="OTP2"/>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP3"/>
                  <input className="otp" type="text" maxLength='1' id="OTP4"/>

                </form> : ""}

                {EmailVerifySuccess == 0 ? <form action="" className="formotp">
                  <div>
                    <p className="otpverificationtitlenew">Email Address Verification</p>
                  </div>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP5"/>
                  <input className="otp mr1vw" type="text" on maxLength='1' id="OTP6"/>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP7"/>
                  <input className="otp" type="text" maxLength='1' id="OTP8"/>
                </form> : ""}

                <div className="text-center">
                  <button type="button" className="btn btn-primary btnnextedit " onClick={verfication}>Continue <i className="fas fa-long-arrow-alt-right"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : ""}
      {MobileVerifySuccess == 0 && EmailVerifySuccess == 1 ? <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="verificationeditext">
                Verification
              </p>
              <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close">Skip</button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img src={otpicon} ></img>
              </div>
              <div>
                {MobileVerifySuccess == 0 ? <form action="" className="formotp">
                  <div>
                    <p className="otpverificationtitlenew">Mobile Number Verification</p>
                  </div>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP1"/>
                  <input className="otp mr1vw" type="text" on maxLength='1' id="OTP2"/>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP3"/>
                  <input className="otp" type="text" maxLength='1' id="OTP4"/>

                  <div className="text-center">
                  <button type="button" className="btn btn-primary btnnextedit " onClick={verfication}>Continue <i className="fas fa-long-arrow-alt-right"></i></button>
                </div>
                </form>
                
                : ""}

            
              </div>
            </div>
          </div>
        </div>
      </div> : ""}
      {MobileVerifySuccess == 1 && EmailVerifySuccess == 0 ? <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="verificationeditext">
                Verification
              </p>
              <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close">Skip</button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img src={otpicon} ></img>
              </div>
              <div>
             
                {EmailVerifySuccess == 0 ? <form action="" className="formotp">
                  <div>
                    <p className="otpverificationtitlenew">Email Address Verification</p>
                  </div>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP5"/>
                  <input className="otp mr1vw" type="text" on maxLength='1' id="OTP6"/>
                  <input className="otp mr1vw" type="text" maxLength='1' id="OTP7"/>
                  <input className="otp" type="text" maxLength='1' id="OTP8"/>

                  <div className="text-center">
                  <button type="button" className="btn btn-primary btnnextedit " onClick={verfication}>Continue<i className="fas fa-long-arrow-alt-right"></i></button>
                </div>
                </form> 
                
                : ""}

              </div>
            </div>
          </div>
        </div>
      </div> : ""}

      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark registerheader">
        <div className="container">
          <img className="loginlogo mb-0" src={mianheaderimg}></img>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" href="/home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/login">Login</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Need Help?</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row gx-4">
              <div className="col-md-12">
                <p className="registertitleedit">
                  Register Your Profile
                </p>
                <p className="registerdescription">
                  Create account to start using logoipsum
                </p>
              </div>
              <div className="col-md-10">
                <div className="row gx-4">
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                      <input type="email" className="form-control" id="txtName" placeholder="Enter Your Name" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <div>
                        <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                      </div>
                      <div className="input-group mb1vw">
                        <input type="text" className="form-control" placeholder="Enter Your Email Address" id="txtEmail" onChange={(e) => setUserEmail(e.target.value)} aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary btnsendedit" type="button" id="button-addon2" onClick={sendEmailOTP}>Send</button>
                      </div>
                      {EmailVerify == 1 ?
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter otp" aria-label="Recipient's username" onChange={(e) => setEmailOTP(e.target.value)} id="txtEmailOTP" aria-describedby="button-addon2" />
                          <button className="btn btn-outline-secondary btnsendedit" type="button" id="button-addon2" onClick={verifyEmailOTP}>Verify</button>
                        </div> : ""}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <div>
                        <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                      </div>
                      <div className="input-group mb1vw">
                        <input type="text" className="form-control" id="txtMobileNo" onChange={(e) => setMobileNo(e.target.value)} placeholder="Enter Your Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary btnsendedit" type="button" id="button-addon2" onClick={sendMobileOTP}>Send</button>
                      </div>
                      {MobileVerify == 1 ?
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter otp" onChange={(e) => setMobileOTP(e.target.value)} aria-label="Recipient's username" id="txtMobileOTP" aria-describedby="button-addon2" />
                          <button className="btn btn-outline-secondary btnsendedit" type="button" id="button-addon2" onClick={verifyMobileOTP}>Verify</button>
                        </div> : ""}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">DOB</label>
                      <input type="date" className="form-control" id="txtDOB" placeholder="Select Your Birthdate" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Password</label>
                      <input type="text" className="form-control" id="txtPassword" placeholder="Enter Password" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mainradiodivedit">
                      <label htmlFor="exampleFormControlInput1" className="form-label radiolabale">Gender</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="txtGender" id="txtGender" value="Male" />
                      <label className="form-check-label form-check-labelcnew" htmlFor="inlineRadio1">Male</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="txtGender" id="txtGender" value="Female" />
                      <label className="form-check-label form-check-labelcnew" htmlFor="inlineRadio2">Female</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check form-check-inline registerinput">
                      <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                      <label className="form-check-label form-label" htmlFor="inlineCheckbox1">By ticking, you are confirming that you have read, understood and agree to the Terms and conditions and privacy policy</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {MobileVerifySuccess == 1 && EmailVerifySuccess == 1 ?
                      <button type="button" className="btn btn-primary btnnextedit" onClick={register}>Register<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    {MobileVerifySuccess == 0 && EmailVerifySuccess == 1 ?
                      <button type="button" className="btn btn-primary btnnextedit " data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={sendMobileOTP} >Next(NMV)(1)<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    {MobileVerifySuccess == 1 && EmailVerifySuccess == 0 ?
                      <button type="button" className="btn btn-primary btnnextedit " data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={sendEmailOTP}>Next (NEV)(2)<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    {MobileVerifySuccess == 0 && EmailVerifySuccess == 0 ?
                      <button type="button" className="btn btn-primary btnnextedit " data-bs-toggle="modal" data-bs-target="#exampleModal3" onClick={()=>{sendEmailOTP(),sendMobileOTP()}} >Next (3)<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    <button type="button" className="btn btn-primary btnnexteditline">Cancel </button>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="registerinput">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                  <div>
                  { ProfilepicUrl=="" ?<img src={profileimg} className="imgprofileedit"></img>:""}
                    <img src={ProfilepicUrl} className="imgprofileedit"></img>
                  </div>
                </div>
                <div >
                  <input type="file" name="file-input"
                    id="file1" accept="image/*" onChange={(e) => {setProfilePic(e.target.files[0]) ; setProfilepicUrl(URL.createObjectURL(e.target.files[0]))}} className="d-none"/>
                   <a href="#" id="upfile1">  <label htmlFor="file-input" className="btn btn-primary btnuploadnew">Upload</label></a>
                 </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default RegisterYourProfile


