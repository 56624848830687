import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { size } from "lodash";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  CardTitle,
  CardBody,
  Label,
} from "reactstrap";
import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import images from "../../assets/images";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../store/actions";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
    };
    this.messageBox = null;
  }

  componentDidMount() {
    const { onGetChats, onGetGroups, onGetContacts, onGetMessages } =
      this.props;
    const { currentRoomId } = this.state;
    onGetChats();
    onGetGroups();
    onGetContacts();
    onGetMessages(currentRoomId);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props;
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }));
  };

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }));
  };

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  };

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }));
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props;
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId });
    onGetMessages(roomId);
  };

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props;
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    };
    this.setState({ curMessage: "" });
    onAddMessage(message);
  };

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
    }
  };

  onKeyPress = e => {
    const { key, value } = e;
    const { currentRoomId, currentUser } = this.state;
    if (key === "Enter") {
      this.setState({ curMessage: value });
      this.addMessage(currentRoomId, currentUser.name);
    }
  };

  //serach recent user
  searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  render() {
    const { chats, groups, contacts, messages } = this.props;
    const { currentRoomId, currentUser } = this.state;

    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>Settings | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <CardTitle className="mb-3">Settings</CardTitle>
          
            <Row>
              
              <Col className="col-12">
                <Card>
                  <CardBody>
                  <div className="h5 mb-3">General</div>
                      <div className="text-center">
                      <ul className="list-unstyled megamenu-list">
                       <Row>
                       <Col lg="2">
                        <li>
                        <a href="/form-elements"> <i className="bx bxs-user-rectangle"></i>
                            Locality</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/form-uploads"> <i className="bx bxs-user-rectangle"></i>
                            User Type</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc3"> <i className="bx bxs-user-rectangle"></i>
                            User Employee</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/form-xeditable"> <i className="bx bxs-user-rectangle"></i>
                            Company</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc24"> <i className="bx bxs-user-rectangle"></i>
                            Branch</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc6"> <i className="bx bxs-user-rectangle"></i>
                            Allowed File Type</a>
                    </li></Col>
                       </Row>
                        </ul>
                      </div>

                      <div className="h5 mb-3">HR & Accounts</div>
                      <div className="text-center">
                      <ul className="list-unstyled megamenu-list">
                       <Row>
                       <Col lg="2">
                        <li>
                        <a href="/kc-email-inbox"> <i className="bx bxs-user-rectangle"></i>
                        Department Master</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc-detail"> <i className="bx bxs-user-rectangle"></i>
                        Designation Master</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc-email"> <i className="bx bxs-user-rectangle"></i>
                        Employee Type</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc-five"> <i className="bx bxs-user-rectangle"></i>
                        Shift Master</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/ui-general"> <i className="bx bxs-user-rectangle"></i>
                        Leave Type</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc-three"> <i className="bx bxs-bookmark-plus"></i>
                        Company Leave Master</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc13"> <i className="bx bxs-notepad"></i>
                        Allowance</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/ui-general"> <i className="bx bxs-notepad"></i>
                        Leave Type</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc14"> <i className="bx bxs-user-badge"></i>
                        Professional Tax</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc-one"> <i className="bx bx-rupee"></i>
                        Salarey Scale</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc16"> <i className="bx bxs-calculator"></i>
                        Pay&nbsp;Calculated On</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc17"> <i className="bx bxs-watch-alt"></i>
                        Overtime</a>
                    </li></Col>
                    <Col lg="2">
                        <li>
                        <a href="/kc18"> <i className="bx bxs-adjust"></i>
                        Deduction Time</a>
                    </li></Col>
                       </Row>
                        </ul>
                      </div>
                  

                    
                     
                     
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
});

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
