import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";


import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";
import Commheader from "../frontend/header";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";


const propertydetail = () => {
  const [OtherPropertyType, setOtherPropertyType] = useState('');
  const [PropertyTitle, setPropertyTitle] = useState('');
  const [OtherPropertyTypeTitle, setOtherPropertyTypeTitle] = useState('');
  const [PropertyTypeId, setPropertyTypeId] = useState('0');
  const [PropertyTypeList, setPropertyTypeList] = useState([]);
  const [PropertyMediaList, setPropertyMediaList] = useState([]);
  const [PropertyNumber, setPropertyNumber] = useState('');
  const [NearByPlace, setNearByPlace] = useState('');
  const [Street, setStreet] = useState('');
  const [Pincode, setPincode] = useState('');
  const [CountryList, setCountryList] = useState([]);
  const [StateFilterList, setStateFilterList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [CountryId, setCountryId] = useState(0);
  const [StateId, setStateId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [FullAddress, setFullAddress] = useState([]);

  const [LandArea, setLandArea] = useState('');
  const [LandAreaIn, setLandAreaIn] = useState('');
  const [SuperBuiltUP, setSuperBuiltUP] = useState('');
  const [SuperBuiltUpIn, setSuperBuiltUpIn] = useState('');

  const [PropertyInsightList, setPropertyInsightList] = useState([]);
  const [PropertyFurnishedList, setPropertyFurnishedList] = useState([]);
  const [PropertyDocumentList,setPropertyDocumentList]=useState([]);


  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getCountryList();
      getPriorityDetail();
      getPropertyType();

    }
  }, []);

  const getPropertyType = async () => {
    axios.get(global.APIURL + '/PropertyType')
      .then(res => {
        setPropertyTypeList(res.data.Result);
      });
  }

  function handleChange(e) {
    const PropertyMediaArr = {
      PId: PropertyMediaList.length + 1,
      MediaFile: e.target.files[0],
      MediaFileUrl: URL.createObjectURL(e.target.files[0])
    }
    setPropertyMediaList([...PropertyMediaList, PropertyMediaArr]);
  }

  const getPriorityDetail = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: 104,
      PropertyId: 17
    }
    axios.post(global.APIURL + '/propertyDetail', PropListData)
      .then(res => {
        console.log("res",res);
        if (res.data.Result != '') {
          setPropertyTitle(res.data.Result[0].PropertyTitle);
          setPropertyTypeId(res.data.Result[0].PropertyTypeID);
          setOtherPropertyType(res.data.Result[0].OtherPropertyType == null ? '' : res.data.Result[0].OtherPropertyType == '' ? '' : 'Other');
          setOtherPropertyTypeTitle(res.data.Result[0].OtherPropertyType == null ? '' : res.data.Result[0].OtherPropertyType);
          setPropertyNumber(res.data.Result[0].PropertyNumber == null ? '' : res.data.Result[0].PropertyNumber);
          setNearByPlace(res.data.Result[0].NearByPlace == null ? '' : res.data.Result[0].NearByPlace);
          setStreet(res.data.Result[0].Street == null ? '' : res.data.Result[0].Street);
          setPincode(res.data.Result[0].Pincode == null ? '' : res.data.Result[0].Pincode);
          setFullAddress(res.data.Result[0].FullAddress==null?'':res.data.Result[0].FullAddress);
          setCountryId(res.data.Result[0].Country == null ? 0 : res.data.Result[0].Country == '' ? 0 : res.data.Result[0].Country);
          var CountryId = res.data.Result[0].Country == null ? 0 : res.data.Result[0].Country == '' ? 0 : res.data.Result[0].Country;
          var VStateName = res.data.Result[0].StateName == null ? '' : res.data.Result[0].StateName;
          var SF = StateFilterList.filter(x => x.StateName == VStateName);
          var SId = SF.length == 0 ? 0 : SF[0].StateId == null ? 0 : SF[0].StateId == '' ? 0 : SF[0].StateId;
          setStateId(SId);
          setCityId(res.data.Result[0].City == null ? 0 : res.data.Result[0].City == '' ? 0 : res.data.Result[0].City);
          StateList.length = 0;
          for (let sd = 0; sd < StateFilterList.length; sd++) {
            if (StateFilterList[sd].CountryId == CountryId) {
              const Stateitem = {
                CountryId: StateFilterList[sd].CountryId,
                StateId: StateFilterList[sd].StateId,
                StateName: StateFilterList[sd].StateName
              };
              StateList.push(Stateitem);
            }
          }

          for (let sd = 0; sd < CityFilterList.length; sd++) {
            if (CityFilterList[sd].StateId == SId) {
              const Cityitem = {
                StateId: CityFilterList[sd].StateId,
                CityId: CityFilterList[sd].CityId,
                CityName: CityFilterList[sd].CityName
              };
              CityList.push(Cityitem);
            }
          }

          setLandArea(res.data.Result[0].LandArea);
          setLandAreaIn(res.data.Result[0].LandAreaIn);
          setSuperBuiltUP(res.data.Result[0].SuperBuiltUP);
          setSuperBuiltUpIn(res.data.Result[0].SuperBuiltUpIn);

          const ProMediaArr = res.data.Result[0].Media;
          for (let pm = 0; pm < ProMediaArr.length; pm++) {
            const VMediaFileUrl = ProMediaArr[pm].Url;
            const PropertyMediaArr = {
              PId: PropertyMediaList.length + 1,
              MediaFile: '',
              MediaFileUrl: VMediaFileUrl
            }
            setPropertyMediaList([...PropertyMediaList, PropertyMediaArr]);
          }

          const ProInsightArr = res.data.Result[0].PropertyInsight;
          for (let pm = 0; pm < ProInsightArr.length; pm++) {
            let Vim = ProInsightArr[pm].InsightMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyMediaArr = {
              Id: ProInsightArr[pm].Id,
              InsightName: ProInsightArr[pm].InsightName,
              InsightWidth: ProInsightArr[pm].InsightWidth,
              InsightLength: ProInsightArr[pm].InsightLength,
              InsightHeight: ProInsightArr[pm].InsightHeight,
              InsightMedia: IMFileName,
              RecordType: 'O'
            }
            PropertyInsightList.push(PropertyMediaArr);
          }

          const ProFurnishedArr = res.data.Result[0].PropertyFurnished;
          for (let pm = 0; pm < ProFurnishedArr.length; pm++) {
            let Vim = ProFurnishedArr[pm].FurnishedMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyFurnishedArr = {
              FurnishedId: ProFurnishedArr[pm].FurnishedId,
              FurnishedDetails: ProFurnishedArr[pm].FurnishedDetails,
              FurnishedMedia: IMFileName,
              RecordType: 'O'
            }
            PropertyFurnishedList.push(PropertyFurnishedArr);
          }
          const ProDocumentsArr = res.data.Result[0].Document;

          for (let pm = 0; pm < ProDocumentsArr.length; pm++) {

            let Vim = ProDocumentsArr[pm].DocumentMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyDocumentArr = {
              DocumentId: ProDocumentsArr[pm].DocumentId,
              DocumentName: ProDocumentsArr[pm].DocumentName,
              DocumentMedia: IMFileName,
              RecordType: 'O'
            }
            PropertyDocumentList.push(PropertyDocumentArr);
          }
          console.log("PropertyDocumentList", PropertyDocumentList);

        }
      });
  }

  const getCountryList = () => {
    CountryList.length = 0;
    StateFilterList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        var CLA = [];
        var SLA = [];
        var CTA = [];
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CountryList.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;

            const Stateitem = {
              CountryId: cl[cd].Id,
              StateId: Sl[sd].Id,
              StateName: Sl[sd].StateName
            };
            StateFilterList.push(Stateitem);

            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                StateId: StateId,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CityFilterList.push(Cityitem);
            }
          }
        }
      });
  }

  const getCountryChange = (CountryId) => {
    var SF = StateFilterList.filter(x => x.CountryId == CountryId);
    setStateList(SF);
  }

  const getStateChange = (StateId) => {
    var CF = CityFilterList.filter(x => x.StateId == StateId);
    setCityList(CF);
  }

  const saveEditProperty=()=>{
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId=JSON.parse(localStorage.getItem('EditPropertyId'));
    const formData = new FormData();
  
    formData.append('RegisterId', VRegisterId);
    formData.append('Action', "Edit");
    formData.append('PropertyId', VPropertyId);
    formData.append('TotalMediaCount', PropertyMediaList.length);
    formData.append('PropertyTypeID', PropertyTypeId);
    formData.append('OtherPropertyType', OtherPropertyType);
    formData.append('PropertyTitle', PropertyTitle);
    formData.append('PropertyNumber', PropertyNumber);
    formData.append('NearByPlace', NearByPlace);
    formData.append('Street', Street);
    formData.append('Pincode', Pincode);
    formData.append('City', CityId);
    formData.append('State', StateId);
    formData.append('Country', CountryId);
    formData.append('FullAddress', FullAddress);
    formData.append('Latitude', "");
    formData.append('Longitude', "");

    for (let di = 0; di < MediaList.length; di++) {

        let RCount = di + 1;
        let VMedia = MediaList[di].MediaFile;
        let CM = 'Media' + RCount;
        formData.append(CM, VMedia);
      }


      axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
      .then(res => {
        console.log("edit-res", res);

        });

        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
          }
    
  
  }

  return (
    <React.Fragment>
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-12">
                <p className="addservicetextform">Property Details</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="row align-items-center">
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Property Title </p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Property Title" defaultValue={PropertyTitle} onChange={(e)=>setPropertyTitle(e.target.value)}/>
                                  </div>

                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="mainredioboxeditap">
                                    <p className="maintitlereuestservicenee">Property Type</p>
                                    <div className="d-flex align-items-center">
                                      <div>
                                        {PropertyTypeList.map((propItems) =>
                                          <div className="form-check form-check-inline" key={propItems.Id}>
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id={propItems.Id} defaultChecked={parseInt(propItems.Id) == parseInt(PropertyTypeId)} value={propItems.Id} onChange={(e) => setPropertyTypeId(e.target.value)} />
                                            <label className="form-check-label commonblackcolor" htmlFor={propItems.Id}>{propItems.PropertyType}</label>
                                          </div>
                                        )}
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" onChange={(e) => setOtherPropertyType('Other')} />
                                          <label className="form-check-label commonblackcolor" htmlFor="inlineRadio4">Other</label>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="registerinputnewedirr form-controlsustomeditnew">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {OtherPropertyType == 'Other' ?
                                <div className="row mt-2">
                                  <div className="col-md-6">
                                    <div className="registerinputnewedirr form-controlsustomeditnew">
                                      <p className="maintitlereuestservicenee">Other Property Type </p>
                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setOtherPropertyType(e.target.value)}  placeholder="Write Here" defaultValue={OtherPropertyTypeTitle} />
                                    </div>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <div className="col-md-5">
                              <div className="row mainsideanpimgdiv">

                                {PropertyMediaList.map((item, idx) => (
                                  <div className="col-4" key={idx}>
                                    <div className="mainanpimg">
                                      <img src={item.MediaFileUrl}></img>
                                      <div className="maindivdeletprpertyaddnewsmall2pd">
                                        <div className="deletprpertyaddnewsmall2pd">
                                          <i className="las la-trash-alt"></i>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                ))}

                                {PropertyMediaList.length < 6 ?
                                  <div className="col-4">
                                    <div className="mainanpimg">
                                      <a id="btnUploadPropertyDetailImage">  <img src={addanp}></img></a>
                                      <input type="file" accept="image/*" id="fuUploadPropertyDetailImage" onChange={handleChange} className="d-none" />
                                    </div>

                                  </div>
                                  : ''}

                              </div>

                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>

                      </div>
                      <div className="col-md-12">
                        <div className=" mb-0">
                          <div className="row align-items-center">
                            <div className="col-md-12">
                              <p className="addservicetextform mainmarginpdtitle">Location Information</p>
                            </div>
                            <div className="col-md-7">

                              <div className="row mainlocationinfodivv">

                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Property Number </p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter House / Property Number" onChange={(e)=>setPropertyNumber} defaultValue={PropertyNumber} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Near By Address</p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Near by Address" defaultValue={NearByPlace} onChange={(e)=>setNearByPlace(e.target.value)}/>
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Street</p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Street Name" defaultValue={Street} onChange={(e)=>setStreet(e.target.value)}/>
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Pin Code</p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Pin Code" defaultValue={Pincode} onChange={(e)=>setPincode(e.target.value)}/>
                                  </div>

                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">Country</p>
                                    <select id="ddlCountry" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                                      <option selected>Select Country</option>
                                      {CountryList.map((CountryListItems) => (
                                        <option key={CountryListItems.CountryId} value={CountryListItems.CountryId} selected={CountryListItems.CountryId === CountryId}> {CountryListItems.CountryName} </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">State</p>
                                    <select id="ddlState" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getStateChange(e.target.value); setStateId(e.target.value) }}>
                                      <option selected>Select State</option>
                                      {StateList.map((StateItems) => (
                                        <option key={StateItems.StateId} value={StateItems.StateId} selected={StateItems.StateId === StateId}> {StateItems.StateName} </option>
                                      ))}
                                    </select>
                                  </div>

                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">City</p>
                                    <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setCityId(e.target.value)}>
                                      <option selected>Select City</option>
                                      {CityList.map((CityItems) => (
                                        <option key={CityItems.CityId} value={CityItems.CityId} selected={CityItems.CityId === CityId}> {CityItems.CityName} </option>
                                      ))}
                                    </select>
                                  </div>

                                </div>


                              </div>


                            </div>
                            <div className="col-md-5">
                              <p className="maintitlereuestservicenee">Location  </p>
                              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4079037565034!2d72.50534231495733!3d23.04550252125371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bb51d412b01%3A0x4c5338c9649c6eab!2sZion%20Z1!5e0!3m2!1sen!2sin!4v1661162103882!5m2!1sen!2sin" width="100%" height="191" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="mainlocationpreview"></iframe>
                              <div className="registerinputnewedirr form-controlsustomeditnew">

                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" defaultValue={FullAddress} placeholder="Enter Your Address" onChange={(e)=>setFullAddress(e.target.value)} />
                              </div>

                            </div>

                          </div>

                        </div>

                      </div>


                    </div>






                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">

                      <div className="col-md-12">
                        <div className="row ">
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="maintitlereuestservicenee">Land Area</p>
                              </div>
                              <div className="col-md-8">
                                <div className="registerinputnewedirr form-controlsustomeditnew">

                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Land Area" defaultValue={LandArea} />
                                </div>
                              </div>
                              <div className="col-md-4">

                                <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                  <option selected>Sq. ft</option>
                                  <option value="One" selected={"One" === LandAreaIn}>One</option>
                                  <option value="Two" selected={"Two" === LandAreaIn}>Two</option>
                                  <option value="Three" selected={"Three" === LandAreaIn}>Three</option>
                                </select>

                              </div>


                            </div>

                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="maintitlereuestservicenee">Super Built Up</p>
                              </div>
                              <div className="col-md-8">
                                <div className="registerinputnewedirr form-controlsustomeditnew">

                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter" defaultValue={SuperBuiltUP} />
                                </div>
                              </div>
                              <div className="col-md-4">

                                <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                  <option selected>Sq. ft</option>
                                  <option value="One" selected={"One" === SuperBuiltUpIn}>One</option>
                                  <option value="Two" selected={"Two" === SuperBuiltUpIn}>Two</option>
                                  <option value="Three" selected={"Three" === SuperBuiltUpIn}>Three</option>
                                </select>

                              </div>


                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>

                      </div>
                      <div className="col-md-12">
                        <div className=" mb-0">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="addservicetextform mainmarginpdtitle">Property Insight (Optional)</p>
                            </div>
                            <div className="col-md-6 text-end">
                              <a href="#"> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New Property</p></a>
                            </div>

                          </div>
                          {PropertyInsightList.map((item, idx) => (
                            <div className="row mainaddnewinsightalldiv" key={idx}>
                              <div className="col-md-10">
                                <div className="row align-items-center ">
                                  <div className="col-md-1">

                                    <img src={deletedash} className="deletedashimg"></img>

                                  </div>
                                  <div className="col-md-2">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" defaultValue={item.InsightName} />

                                    </div>

                                  </div>
                                  <div className="col-md-2">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" defaultValue={item.InsightWidth} />
                                      <div className="maininputdefulttext">
                                        <p>W</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-2">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" defaultValue={item.InsightLength} />
                                      <div className="maininputdefulttext">
                                        <p>L</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-2">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" defaultValue={item.InsightHeight} />
                                      <div className="maininputdefulttext">
                                        <p>H</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-3">
                                    <div className="">
                                      <input type="file" name="file" id="file" className="file" />
                                      <div className="mainfileuploadimgwith">
                                        <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" defaultValue={item.InsightMedia} />
                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="row mainaddnewinsightalldiv">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1">

                                  <img src={deletedash} className="deletedashimg"></img>

                                </div>
                                <div className="col-md-2">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />

                                  </div>

                                </div>
                                <div className="col-md-2">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                    <div className="maininputdefulttext">
                                      <p>W</p>
                                    </div>
                                  </div>

                                </div>
                                <div className="col-md-2">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                    <div className="maininputdefulttext">
                                      <p>L</p>
                                    </div>
                                  </div>

                                </div>
                                <div className="col-md-2">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" />
                                    <div className="maininputdefulttext">
                                      <p>H</p>
                                    </div>
                                  </div>

                                </div>
                                <div className="col-md-3">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <i className="las la-trash-alt"></i>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div className="col-md-2 text-end">
                              <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                <form>
                                  <div className="form-group newwform-group">
                                    <input type="checkbox" id="html" />
                                    <label htmlFor="html">is Property Furnished ?</label>
                                  </div>

                                </form>
                              </div>

                            </div>
                            <div className="col-md-12">
                              <div className="maindivederaddpropertyinsight"></div>

                            </div>

                          </div>
                          {PropertyFurnishedList.map((item, idx) => (
                          <div className="row mainaddnewinsightalldiv align-items-center " key={idx}>
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1">

                                  <img src={deletedash} className="deletedashimg"></img>

                                </div>
                                <div className="col-md-3">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" defaultValue={item.FurnishedDetails} />

                                  </div>

                                </div>
                                <div className="col-md-2">
                                  <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                    <option selected>Room 1</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>

                                </div>

                                <div className="col-md-3">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" defaultValue={item.FurnishedMedia} />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <i className="las la-trash-alt"></i>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 ">
                                  <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                                </div>

                              </div>


                            </div>
                            
                          </div>
                           ))}
                          <div className="row mainaddnewinsightalldiv align-items-center ">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1">

                                  <img src={deletedash} className="deletedashimg"></img>

                                </div>
                                <div className="col-md-3">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />

                                  </div>

                                </div>
                                <div className="col-md-2">
                                  <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                    <option selected>Room 1</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>

                                </div>

                                <div className="col-md-3">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <i className="las la-trash-alt"></i>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 ">
                                  <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                                </div>

                              </div>


                            </div>
                            <div className="col-md-2 text-end">
                              <a href="#"> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New </p></a>
                            </div>
                          </div>

                        </div>

                      </div>



                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">


                      <div className="col-md-12">
                        <div className=" ">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="addservicetextform mainmarginpdtitle">Documents</p>
                            </div>
                            <div className="col-md-6 text-end">
                              <a href="#"> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New Document</p></a>
                            </div>

                          </div>

                        { PropertyDocumentList.map((item,idx)=>( <div className="row mainaddnewinsightalldiv" key={idx}>
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1">

                                  <img src={deletedash} className="deletedashimg"></img>

                                </div>
                                <div className="col-md-8">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" defaultValue={item.DocumentName}/>

                                  </div>

                                </div>



                                <div className="col-md-3">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" defaultValue={item.DocumentMedia}/>
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <i className="las la-trash-alt"></i>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div className="col-md-2 text-end">
                              <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                            </div>

                          </div>))
}
                          <div className="row mainaddnewinsightalldiv">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1">

                                  <img src={deletedash} className="deletedashimg"></img>

                                </div>
                                <div className="col-md-8">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="write here" />

                                  </div>

                                </div>



                                <div className="col-md-3">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <i className="las la-trash-alt"></i>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div className="col-md-2 text-end">
                              <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                            </div>

                          </div>



                        </div>

                      </div>



                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <a href="#"> <button type="button" className="btn btn-primary btnnextedit " onClick={saveEditProperty} >Save </button></a>
                <a href="#">  <button type="button" className="btn btn-primary btnnexteditline">Cancel </button></a>


              </div>


            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment >
  )
}

export default propertydetail
