import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";


import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";










import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";

import Commheader from "../frontend/header";
import axios from "axios";


const manageservice = () => {
  const [ServiceList, setServiceList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);
  useEffect(() => {
    setShowLoader(1);
    getServiceList();

  }, []);

  const getServiceList = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: 1
    }
    axios.post(global.APIURL + '/SevicelistData', PropListData)
      .then(LPres => {
        let LCD = LPres.data.Result.map((lcdeatail) => {
          return lcdeatail
        })
        setServiceList(LCD);
      });

      setShowLoader(0);
  }

  const EditDetail = (e, ServiceId, PropertyId) => {
    localStorage.setItem("EditServiceId", JSON.stringify(ServiceId));
    localStorage.setItem("EditPropertyId", JSON.stringify(PropertyId));
    console.log(ServiceId + ':' + PropertyId);
    window.location.href = "/edit-request-new-service";
  };

  const ServiceDetail = (e, ServiceId, PropertyId) => {
    localStorage.setItem("DetailServiceId", JSON.stringify(ServiceId));
    localStorage.setItem("DetailPropertyId", JSON.stringify(PropertyId));
    window.location.href = "/service-details";
  };

  return (
    <React.Fragment>
      {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-6">
                <p className="addservicetextform">Manage Services</p>

              </div>
              <div className="col-md-6 text-end">
                <a href="/request-new-service"> <p className="addnewpropertytext">  <i className="las la-plus-circle"></i>Add New  Service</p></a>

              </div>


            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="maintabledivedit">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="mainwidthtable" scope="col">Service</th>
                        <th className="mainwidthtable" scope="col">Property</th>
                        <th className="mainwidthtable2" scope="col">Request</th>
                        <th className="mainwidthtable2" scope="col">Statsus</th>
                        <th className="mainwidthtable2" scope="col">Review</th>
                        <th className="mainwidthtable2" scope="col">Query</th>
                        <th className="mainwidthtable2" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ServiceList.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            <div>
                              <a onClick={e => ServiceDetail(e, item.ServiceId, item.PropertyId)}>
                                <p className="mainservicename">{item.ServiceName}</p>
                                <p className="mainservicetypetext">{item.ServiceTypeName}</p>
                                <p className="mainserviceidtext">ID #SR0005GS62</p>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <div>
                                <img src={item.PropertyfirstImage} className="mainpropertysliderimg2" />
                              </div>
                              <div className="w-100">
                                <div className="d-flex justify-content-between w-100">
                                  <p className="mainpropertytitlelistms">{item.PropertyName}</p>

                                </div>
                                <div>
                                  <p className="propertycatageoriedms">{item.PropertyType}</p>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <img src={locationpropertyedit} className="locationpropertyeditms" />
                                  </div>
                                  <div>
                                    <p className="propertytextlocationms mb-0">{item.Address}</p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="servicelistdatems"> {item.ServiceDate}</p>
                            <p className="servicelisttimems"> {item.ServiceTime}</p>
                          </td>
                          <td>
                            {item.Review == '' ? '' : <button className="btn redbtn">Pending</button>}
                          </td>
                          <td>
                            {item.ReviewStar == '' ? '' : <button className="btn maingraybtnedit">Not Available</button>}
                          </td>
                          <td>
                            {item.ReviewStar == '' ? '' : <button className="btn greenbtn">Resolved</button>}
                          </td>
                          <td>
                            <a className="btn greenbtn" id="btnEdit" onClick={e => EditDetail(e, item.ServiceId, item.PropertyId)}>Edit</a>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum</p>
            </div>
            <div className="d-flex">
              <p className="footercopytextedit">T&C</p>
              <p className="footercopytextedit2">Privacy</p>


            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )

}

export default manageservice
