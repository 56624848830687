import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Input,
    img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/userdefaultimage.jpg";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";


import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";










import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";



const header = () => {
    let UName = JSON.parse(localStorage.getItem('Name'));
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
        window.location.href = "/home";
    }
    else if (VRegisterId == "") {
        window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
        window.location.href = "/home";
    }
    const logOut = async () => {
        localStorage.setItem("RegisterId", JSON.stringify(''));
        localStorage.setItem("Name", JSON.stringify(''));
        localStorage.setItem("Email", JSON.stringify(''));
        localStorage.setItem("Phone", JSON.stringify(''));
        localStorage.setItem("EmailVerified", JSON.stringify(''));
        localStorage.setItem("MobileVerified", JSON.stringify(''));
        window.location.href = "/home";
    }
    return (
        <React.Fragment>
          
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark registerheader">
                <div className="container">
                    <a href="/dashboard-new"><img className="loginlogo mb-0" src={mianheaderimg}></img></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><i className="las la-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav align-items-md-center ms-md-auto mb-2 mb-md-0">
                            <li className="nav-item">
                                <a className="nav-link " href="/dashboard-new">Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/request-new-service">Request New Service</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Support</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false"> <img className="mainprofiledashboard" src={mainprofiledashboard} /> {UName}</a>
                                <ul className="dropdown-menu dropdown-menunewww">
                                    <li><a className="dropdown-item" href="/manage-service">Manage Service</a></li>
                                    <li><a className="dropdown-item" href="/property-list">View Properties</a></li>
                                    <li><a className="dropdown-item" href="/transaction-history">Transaction History</a></li>
                                    <li><a className="dropdown-item" href="/manage-profile">Manage Profile</a></li>
                                    <li><a className="dropdown-item" href="/referrals-list">Refer a Friend</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" onClick={logOut}>Log Out</a></li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>


        </React.Fragment>
    )

}

export default header
