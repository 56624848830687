import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_backdrop: false,
    }
    this.tog_standard = this.tog_standard.bind(this)
    this.tog_fullscreen = this.tog_fullscreen.bind(this)
    this.tog_xlarge = this.tog_xlarge.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.tog_small = this.tog_small.bind(this)
    this.tog_center = this.tog_center.bind(this)
    this.tog_scroll = this.tog_scroll.bind(this)
    this.tog_backdrop = this.tog_backdrop.bind(this)
  }

  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  tog_backdrop() {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
    }))
    this.removeBodyCss()
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }

  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge,
    }))
    this.removeBodyCss()
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }))
    this.removeBodyCss()
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }))
    this.removeBodyCss()
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }

  render() {
    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>User Details | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <CardTitle className="h6 mb-4">Professionl Details</CardTitle>
                     <Col xl={12} sm={6}>
                      <h5 className="font-size-14">Profession Type</h5>
                        <div className="mt-4 mb-4 d-flex align-items-center">
                        
                          <div className="form-check">
                          
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                             Fresher
                            </label>
                          </div>
                          <div className="form-check ms-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                             Experienced
                            </label>
                          </div>
                        </div>
                      </Col>
                     <Row>
                     <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label col-form-user"
                          
                        >
                         Highest Qulification Details
                        </Label>
                        <Col sm={6}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          />
                        </Col>
                      </div>
                      <Col sm={6}>
                        <div className="h6 mb-3">
                          Total Experience
                        </div>
                      </Col>
                      <Col sm={12}>
                      <div className ="table-responsive mb-2">
                      <table className="table table-borderless">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr.</th>
                          <th scope="col" className="">Company</th>
                          <th scope="col" className="">Designation</th>
                          <th scope="col" className="">Duration</th>
                          <th scope="col" className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="user-td">1</th>
                          <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                          <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                          <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                          <td>
                            <div className="text-center">
                              <a href=""><i className="mdi mdi-close-circle h3"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                      </div>
                      </Col>
                        <Col sm={6}>
                          <a href="#">+Add New Line</a>
                        </Col>
                      </Row>

                      <CardTitle className="h6 mb-4 mt-4">Family Member Details</CardTitle>
                     <Row>
                      <Col sm={12}>
                      <div className ="table-responsive mb-2">
                      <table className="table table-borderless">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr.</th>
                          <th scope="col" className="">Family&nbsp;Member&nbsp;Name</th>
                          <th scope="col" className="">Relation</th>
                          <th scope="col" className="">DOB</th>
                          <th scope="col" className="">A/c No.</th>
                          <th scope="col" className="">Contact&nbsp;No.</th>
                          <th scope="col" className="">Email Id</th>
                          <th scope="col" className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <th scope="row" className="user-td">1</th>
                          <td>John Tommy</td>
                          <td>Brother</td>
                          <td>20/05/1997</td>
                          <td>0123456789</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="text-center">
                              <a href=""><i className="mdi mdi-close-circle h3"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">2</th>
                          <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                          <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                         
                         <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                           <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                           <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                           <td> <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          /></td>
                          <td>
                            <div className="text-center">
                              <a href=""><i className="mdi mdi-close-circle h3"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                      </div>
                      </Col>
                        <Col sm={6}>
                          <a href="#">+Add New Line</a>
                        </Col>
                      </Row>
                     

                      <Row>
                      <Col className="col-12 mt-4">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>
                     
                               <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiModal