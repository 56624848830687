import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import mainsliderheroimg from "../../assets/images/frontend-img/mainsliderheroimg.png";
import mainsliderelement from "../../assets/images/frontend-img/mainsliderelement.png";
import dropdownicon from "../../assets/images/frontend-img/dropdownicon.png";
import serchicon from "../../assets/images/frontend-img/serchicon.png";
import builtforimpact from "../../assets/images/frontend-img/builtforimpact.png";
import goodtofindonline from "../../assets/images/frontend-img/goodtofindonline.png";
import whoweareimgmain from "../../assets/images/frontend-img/whoweareimgmain.png";
import playicon from "../../assets/images/frontend-img/playicon.png";
import whowearetop from "../../assets/images/frontend-img/whowearetop.png";
import whoweareline from "../../assets/images/frontend-img/whoweareline.png";
import hdtw1 from "../../assets/images/frontend-img/hdtw1.png";
import hdtw2 from "../../assets/images/frontend-img/hdtw2.png";
import hdtw3 from "../../assets/images/frontend-img/hdtw3.png";
import hdtw4 from "../../assets/images/frontend-img/hdtw4.png";
import shaphw1 from "../../assets/images/frontend-img/shaphw1.png";
import shaphw2 from "../../assets/images/frontend-img/shaphw2.png";
import shaphw3 from "../../assets/images/frontend-img/shaphw3.png";
import testimonialcheck from "../../assets/images/frontend-img/testimonialcheck.png";
import testimonialuser from "../../assets/images/frontend-img/testimonialuser.png";
import star2 from "../../assets/images/frontend-img/star2.png";
import star3 from "../../assets/images/frontend-img/star3.png";
import star4 from "../../assets/images/frontend-img/star4.png";
import tp1 from "../../assets/images/frontend-img/tp1.png";
import tp2 from "../../assets/images/frontend-img/tp2.png";
import tp3 from "../../assets/images/frontend-img/tp3.png";
import featureinclude from "../../assets/images/frontend-img/featureinclude.png";
import featureincludenot from "../../assets/images/frontend-img/featureincludenot.png";
import civil from "../../assets/images/frontend-img/civil.png";
import interior from "../../assets/images/frontend-img/interior.png";
import property from "../../assets/images/frontend-img/property.png";
import development from "../../assets/images/frontend-img/development.png";
import footershap from "../../assets/images/frontend-img/footershap.png";
import workflowsape from "../../assets/images/frontend-img/workflowsape.png";
import testimonialshap1 from "../../assets/images/frontend-img/testimonialshap1.png";
import testimonialshap from "../../assets/images/frontend-img/testimonialshap.png";
import Commheader from "../frontend/header";






import mainbg from "../../assets/images/frontend-img/mainbg.png";
import Section from "pages/Crypto/CryptoIcoLanding/HeroSection/Section";




class UiVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <section className="maintopbar">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-white topbarsocialicon">
                  <a href="#"><i className="fab fa-facebook-f"></i></a>
                  <a href="#"><i className="fab fa-instagram"></i></a>
                  <a href="#"><i className="fab fa-linkedin-in"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                </div>
                <div className="text-white topbarcontact">
                  <a className="mt-0" href="tel:+11234567"
                  ><i className="fas fa-phone me-md-3 me-2"></i>Call us : (+1) 123 4567
                    890</a
                  >
                 
                  <a href="mailto:info@havnor.com" className="me-0"
                  ><i className="fas fa-envelope me-md-3 me-2"></i>Mail us :
                    info@havnor.com</a
                  >
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="">
              <div className="">
                <nav
                  className="navbar navbar-expand-lg navbar-light hedermain nav-container p-0 withoutfixnav"
                  aria-label="Eighth navbar example"
                >
                  <div className="container">
                    <a className="navbar-brand" href="home.html">
                      <img src={mianheaderimg} className="headerlogo" alt="" />
                    </a>

                    <div>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarsExample07"
                        aria-controls="navbarsExample07"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                    </div>

                    <div
                      className="collapse navbar-collapse navbar-collapseedit"
                      id="navbarsExample07"
                    >
                      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                        <li className="nav-item navbar-linksedit">
                          <a
                            className="nav-link "
                            aria-current="page"
                            href="home.html"
                          >Home
                          </a>
                        </li>
                        <li className="nav-item navbar-linksedit">
                          <a className="nav-link" href="about.html">About us</a>
                        </li>
                        <li className="nav-item navbar-linksedit">
                          <a className="nav-link" aria-current="page" href="product.html"
                          >How it Works?</a
                          >
                        </li>
                        <li className="nav-item navbar-linksedit">
                          <a className="nav-link" href="service.html">Pricing</a>
                        </li>

                        <li className="nav-item navbar-linksedit">
                          <a
                            className="nav-link"
                            aria-current="page"
                            href="/login"
                          >Login</a
                          >
                        </li>
                        <li className="nav-item navbar-linksedit">
                          <a
                            className="nav-link me-0 btneditgetstarted"
                            aria-current="page"
                            href="contact.html"
                          ><button type="button" className="btn btn-outline-light btngetstarted">Get Started</button></a
                          >
                        </li>

                      </ul>
                    </div>
                  </div>
                </nav>


              </div>
            </div>
          </section>
          <section>
            <div className="maindivforhero">
              <div className="mainbgblue">
                {/* <img src={mainbg} className="mainbg" alt="" /> */}
              </div>

              <div className="container">
                <div className="row maindivforhero">
                  <div className="col-md-6">
                    <div className="maindivsideslider">
                      <img src={mainsliderheroimg} className="mainbgsideimg" alt="" />
                      <img src={mainsliderelement} className="mainbgsideimgelement" alt="" />

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="maincontentherodiv">
                      <p className="mainherotext">
                        Get the help you need, every
                        step of the way
                      </p>
                      <p className="subtextedithero">
                        Praesentium unde ipsum nihil hic non cupiditate distinctio dlorum tenetur. Explicabo et officia temporibus
                      </p>
                      <div className="maindividercontent">
                      </div>
                      <p className="estimatecosttet">Estimate Cost</p>
                      <div className="mainestimcostbox">
                        <div className="row">
                          <div className="col-md-4 pe-md-0 maindropdownbox">
                            <select className="form-select" aria-label="Default select example">
                              <option selected>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>

                            </select>
                            <img src={dropdownicon} className="dropdowniconedit" alt="" />
                          </div>
                          <div className="col-md-4 pe-md-0 ps-md-0 maindropdownbox">
                            <select className="form-select" aria-label="Default select example">
                              <option selected>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>

                            </select>
                            <img src={dropdownicon} className="dropdowniconedit" alt="" />
                          </div>
                          <div className="col-md-4 pe-md-0 ps-md-0 mainserchboxedit">

                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="serch" />
                            <img src={serchicon} className="serchboxmainimg" alt="" />
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row relaxationmain ">
                <div className="col-md-4">
                  <p className="maincommonsmalltext">
                    Achieve More
                  </p>
                  <p className="commonmainbigtitle">
                    Relaxation and your Satisfaction
                  </p>
                  <p className="blacktextedit">
                    Qui veritatis non. Eius qui est maiores aut suscipit in sint. Dolorem nihil officiis cumque quidem.
                  </p>

                </div>
                <div className="col-md-4 ">
                  <div className="text-center mainboxrelaxtion">
                    <img src={builtforimpact} className="relaxationimgedit" alt="" />
                    <p className="rboxedittext">Built For Impact</p>
                    <p className="mainrboxtextsub">
                      Omnis rem eos rerum sint ipsum rem cupiditate atque. Quisquam dolore dolores quo qui saepe.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="text-center mainboxrelaxtion2">
                    <img src={goodtofindonline} className="relaxationimgedit" alt="" />
                    <p className="rboxedittext">Goods to Find Online</p>
                    <p className="mainrboxtextsub">
                      Nostrum enim qui. Cum cupiditate et ut blanditiis ad libero illo. Assumenda modi vero rerum.
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </section>
          <section>
            <div className="whowearemaindiv">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <p className="maincommonsmalltext2">
                      Who We Are
                    </p>
                    <p className="commonmainbigtitle">
                      Enthusiastic Individuals Working for Extraordinary User.
                    </p>
                    <p className="blacktextedit">
                      Ipsa commodi et molestiae est et totam saepe quia. Doloribus possimus ipsam aut voluptas in et quasi omnis mollitia.
                    </p>
                    <button type="button" className="btn btn-danger readmorebtn">Read More</button>

                  </div>
                  <div className="col-md-8 ">
                    <div className="row align-items-center mainsidebarwwr">
                      <div className="col-md-6">
                        <div className="playvideoimg">
                          <img src={whoweareimgmain} className="whoweareimgmainedit" alt="" />
                          <a href="#"><img src={playicon} className="playiconedit" alt="" /></a>
                        </div>

                      </div>
                      <div className="col-md-6">
                        <div className="mainwhowearebox">
                          <p className="wwrboxcount">01</p>
                          <p className="wwrboxtitle">Exceed your expectations</p>
                          <p className="wwrboxsubtitle">Nam enim dolor ullam quas est possimus ut. Deserunt sunt quibusdam omnis minus est fuga.</p>

                        </div>
                        <div className="mainwhowearebox">
                          <p className="wwrboxcount">02</p>
                          <p className="wwrboxtitle">Professional Expert</p>
                          <p className="wwrboxsubtitle">Maxime in et facilis. Consequatur laboriosam autem. Odio odit magnam est.</p>

                        </div>

                      </div>


                    </div>

                  </div>

                </div>

              </div>
              <img src={whowearetop} className="whowearetopimg" alt="" />
              <img src={whoweareline} className="whowearelineimg" alt="" />
            </div>
          </section>
          <section>
            <div className="container">
              <div className="mainsectiontitle">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p className="maincommonsmalltext">
                      Our WorkFlow
                    </p>
                    <p className="commonmainbigtitle">
                      How Does This Work?
                    </p>

                  </div>

                </div>


                <div className="row mainboxesdivhowitwork">

                  <div className="col-md-3">
                    <div className="mainboxhowitwork">

                      <img src={hdtw1} className="howitworkboximg" alt="" />
                      <p className="howitboxtitle">
                        Itaque Veniam
                      </p>
                      <p className="mainrboxtextsub">Quia aut nihil tempora veritatis ipsa repudiandae in. Fuga sed repellendus. Inventore.</p>
                      <img src={shaphw1} className="shaphowit1" alt="" />


                    </div>



                  </div>
                  <div className="col-md-3">
                    <div className="mainboxhowitwork2">

                      <img src={hdtw2} className="howitworkboximg" alt="" />
                      <p className="howitboxtitle">
                        Modi Sequi Nulla
                      </p>
                      <p className="mainrboxtextsub">Ipsum error beatae nobis facilis. Accusamus voluptates quia explicabo natus eos ea molestiae dolore.</p>
                      <img src={shaphw2} className="shaphowit2" alt="" />
                    </div>




                  </div>
                  <div className="col-md-3">
                    <div className="mainboxhowitwork">

                      <img src={hdtw3} className="howitworkboximg" alt="" />
                      <p className="howitboxtitle">
                        Officia Soluta
                      </p>
                      <p className="mainrboxtextsub">Optio aut et rem. Eum illo enim quo. Repellendus facere quaerat commodi. Et magni ut recusandae unde</p>
                      <img src={shaphw3} className="shaphowit3" alt="" />
                    </div>



                  </div>
                  <div className="col-md-3">
                    <div className="mainboxhowitwork2">

                      <img src={hdtw4} className="howitworkboximg" alt="" />
                      <p className="howitboxtitle">
                        Non Dignissimos
                      </p>
                      <p className="mainrboxtextsub">Corrupti et sint et nisi unde minus rerum accusantium. Porro voluptatibus eum non debitis quae possimus</p>
                    </div>



                  </div>

                </div>
              </div>
            </div>

          </section>
          <section>
            <div className="maintestimonialdiv">
              <img className="leftshaptestimonial" src={testimonialshap1}></img>
              <img className="rightshaptestimonial" src={testimonialshap}></img>

              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-4">

                    <p className="maincommonsmalltext">
                      Testimonial
                    </p>
                    <p className="commonmainbigtitlelight">
                      Know what our users says
                    </p>
                    <p className="blacktexteditlight">
                      Ut commodi et ipsam molestias omnis aliquid laudantium nemo dolore. Mollitia voluptas quod qui reprehenderit dolorem commodi repudiandae blanditiis.
                    </p>
                    <div className="row maindetailleftsidetestimonial">
                      <div className="col-md-6">
                        <div className="maintestimonialdetils">
                          <p>
                            <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                            90+ Professional Expert
                          </p>
                        </div>

                      </div>
                      <div className="col-md-6">
                        <div className="maintestimonialdetils">
                          <p>
                            <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                            900+ Services
                          </p>
                        </div>

                      </div>
                      <div className="col-md-6">
                        <div className="maintestimonialdetils">
                          <p>
                            <img src={testimonialuser} className="testimonialuser" alt="" />
                            450+ Users
                          </p>
                        </div>

                      </div>
                      <div className="col-md-6">
                        <div className="maintestimonialdetils">
                          <p>
                            <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                            350+ Reviews
                          </p>
                        </div>

                      </div>

                    </div>

                    <button type="button" className="btn btn-warning readmorebtnew">Read More</button>
                  </div>
                  <div className="col-md-8">
                    <div className="testimonialstarratingmain">
                      <div className="maintestitmonalboxrating">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src={tp1} className="testimonialuserimg" alt="" />
                          </div>
                          <div>
                            <p className="mainrboxtextsub">&quot;At ipsum eligendi est qui. Incidunt et ex ab. Officiis tenetur nihil laudantium ratione a labore adipisci voluptatem.&quot;</p>
                            <p className="testimonialname">Erwin Kessler</p>
                            <img src={star3} className="starimg" alt="" />
                          </div>

                        </div>

                      </div>

                    </div>
                    <div className="testimonialstarratingmain2">
                      <div className="maintestitmonalboxrating">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src={tp2} className="testimonialuserimg" alt="" />
                          </div>
                          <div>
                            <p className="mainrboxtextsub">&quot;Mollitia fugiat qui. Adipisci nesciunt po ssimus cupiditate aut veritatis itaque dolore&quot;</p>
                            <p className="testimonialname">Jackeline Dietrich</p>
                            <img src={star4} className="starimg" alt="" />
                          </div>

                        </div>

                      </div>

                    </div>
                    <div className="testimonialstarratingmain mb-0">
                      <div className="maintestitmonalboxrating">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src={tp3} className="testimonialuserimg" alt="" />
                          </div>
                          <div>
                            <p className="mainrboxtextsub">&quot;Praesentium velit dolores non et deserunt corrupti quia. Consequatur perferendis voluptatem.&quot;</p>
                            <p className="testimonialname">Osborne Torphy</p>
                            <img src={star2} className="starimg" alt="" />
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>


              </div>

            </div>
          </section>
          <section>
            <div className="container">
              <div className="bestdealboxmain">
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <p className="maincommonsmalltext">
                        Best Deal For You
                      </p>
                      <p className="commonmainbigtitle">
                        Our Job is To Provide You
                        With Innovation.
                      </p>

                    </div>

                  </div>
                  <div className="col-md-8">
                    <div className="mainsidetextheading">
                      <p className="mainrboxtextsub">
                        Est vitae optio excepturi voluptatibus consequatur pariatur deserunt. Praesentium blanditiis magnam error maiores sed. Est laborum doloribus nobis omnis cum ipsam officia.
                      </p>
                    </div>

                  </div>

                </div>
                <div className="row miandivpricingbox">
                  <div className="col-md-3">
                    <div className="mainpricetable">
                      <div className="boxpricing">
                        <p className="plantitle text-center">
                          Free Plan
                        </p>
                        <p className="pricingtext">
                          $19<span>/Month</span>
                        </p>
                        <div className="dividerpricing"></div>
                        <div className="contentpricing">
                          <p className="includetext"> <img src={featureinclude} alt="" />Free feature one</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature two</p>
                          <p className="includetextno"><img src={featureincludenot} alt="" />Unlimited feature three</p>
                          <p className="includetextno"><img src={featureincludenot} alt="" />2x feature four</p>
                          <p className="includetextno"><img src={featureincludenot} alt="" />100x feature five</p>
                          <button type="button" className="btn btn-primary pricingblubtn">Get Started</button>
                        </div>

                      </div>


                    </div>

                  </div>
                  <div className="col-md-3">
                    <div className="mainpricetable">
                      <div className="boxpricing">
                        <p className="plantitle text-center">
                          Silver Plan
                        </p>
                        <p className="pricingtext">
                          $29<span>/Month</span>
                        </p>
                        <div className="dividerpricing"></div>
                        <div className="contentpricing">
                          <p className="includetext"> <img src={featureinclude} alt="" />Free feature one</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature two</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature three</p>
                          <p className="includetextno"><img src={featureincludenot} alt="" />2x feature four</p>
                          <p className="includetextno"><img src={featureincludenot} alt="" />100x feature five</p>
                          <button type="button" className="btn btn-primary pricingblubtn">Get Started</button>
                        </div>

                      </div>


                    </div>

                  </div>
                  <div className="col-md-3">
                    <div className="mainpricetable">
                      <div className="boxpricing">
                        <p className="plantitle text-center">
                          Gold Plan
                        </p>
                        <p className="pricingtext">
                          $35<span>/Month</span>
                        </p>
                        <div className="dividerpricing"></div>
                        <div className="contentpricing">
                          <p className="includetext"> <img src={featureinclude} alt="" />Free feature one</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature two</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature three</p>
                          <p className="includetext"><img src={featureinclude} alt="" />2x feature four</p>
                          <p className="includetextno"><img src={featureincludenot} alt="" />100x feature five</p>
                          <button type="button" className="btn btn-primary pricingblubtn">Get Started</button>
                        </div>

                      </div>


                    </div>

                  </div>
                  <div className="col-md-3">
                    <div className="mainpricetable">
                      <div className="boxpricing">
                        <p className="plantitle text-center">
                          Platinum Plan
                        </p>
                        <p className="pricingtext">
                          $55<span>/Month</span>
                        </p>
                        <div className="dividerpricing"></div>
                        <div className="contentpricing">
                          <p className="includetext"> <img src={featureinclude} alt="" />Free feature one</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature two</p>
                          <p className="includetext"><img src={featureinclude} alt="" />Unlimited feature three</p>
                          <p className="includetext"><img src={featureinclude} alt="" />2x feature four</p>
                          <p className="includetext"><img src={featureinclude} alt="" />100x feature five</p>
                          <button type="button" className="btn btn-primary pricingblubtn">Get Started</button>
                        </div>

                      </div>


                    </div>

                  </div>

                </div>
              </div>

            </div>
          </section>
          <section>
            <div className="container">
              <div className="mainworkflowtext">
                <img className="workflowshapimg" src={workflowsape}></img>
                <div className="text-center">
                  <p className="maincommonsmalltext2">Our WorkFlow</p>
                  <p className="commonmainbigtitle">How Does This Work?</p>
                </div>
                <div className="maintabshowwework">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Emergency</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">General Services</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Estate / Project  Management</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-contact-tab4" data-bs-toggle="pill" data-bs-target="#pills-contact4" type="button" role="tab" aria-controls="pills-contact4" aria-selected="false">Documentation</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-contact-tab5" data-bs-toggle="pill" data-bs-target="#pills-contact5" type="button" role="tab" aria-controls="pills-contact5" aria-selected="false">Other Services</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={civil} alt="" />
                            <p className="workflowboxtitle">Civil Maintenance Works</p>
                            <p className="workflowboxdetailks">Qui quod rerum reprehenderit voluptas facere.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={interior} alt="" />
                            <p className="workflowboxtitle">Interior Residential</p>
                            <p className="workflowboxdetailks">Omnis non et atque consectetur sint iusto.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={property} alt="" />
                            <p className="workflowboxtitle">Property Fencing</p>
                            <p className="workflowboxdetailks">Voluptatibus sequi soluta dolor corrupti nisi quod.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={development} alt="" />
                            <p className="workflowboxtitle">Property Development</p>
                            <p className="workflowboxdetailks">Aliquid molestiae alias beatae itaque voluptate.</p>


                          </div>

                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={civil} alt="" />
                            <p className="workflowboxtitle">Civil Maintenance Works</p>
                            <p className="workflowboxdetailks">Qui quod rerum reprehenderit voluptas facere.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={interior} alt="" />
                            <p className="workflowboxtitle">Interior Residential</p>
                            <p className="workflowboxdetailks">Omnis non et atque consectetur sint iusto.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={property} alt="" />
                            <p className="workflowboxtitle">Property Fencing</p>
                            <p className="workflowboxdetailks">Voluptatibus sequi soluta dolor corrupti nisi quod.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={development} alt="" />
                            <p className="workflowboxtitle">Property Development</p>
                            <p className="workflowboxdetailks">Aliquid molestiae alias beatae itaque voluptate.</p>


                          </div>

                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={civil} alt="" />
                            <p className="workflowboxtitle">Civil Maintenance Works</p>
                            <p className="workflowboxdetailks">Qui quod rerum reprehenderit voluptas facere.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={interior} alt="" />
                            <p className="workflowboxtitle">Interior Residential</p>
                            <p className="workflowboxdetailks">Omnis non et atque consectetur sint iusto.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={property} alt="" />
                            <p className="workflowboxtitle">Property Fencing</p>
                            <p className="workflowboxdetailks">Voluptatibus sequi soluta dolor corrupti nisi quod.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={development} alt="" />
                            <p className="workflowboxtitle">Property Development</p>
                            <p className="workflowboxdetailks">Aliquid molestiae alias beatae itaque voluptate.</p>


                          </div>

                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact4" role="tabpanel" aria-labelledby="pills-contact-tab4">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={civil} alt="" />
                            <p className="workflowboxtitle">Civil Maintenance Works</p>
                            <p className="workflowboxdetailks">Qui quod rerum reprehenderit voluptas facere.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={interior} alt="" />
                            <p className="workflowboxtitle">Interior Residential</p>
                            <p className="workflowboxdetailks">Omnis non et atque consectetur sint iusto.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={property} alt="" />
                            <p className="workflowboxtitle">Property Fencing</p>
                            <p className="workflowboxdetailks">Voluptatibus sequi soluta dolor corrupti nisi quod.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={development} alt="" />
                            <p className="workflowboxtitle">Property Development</p>
                            <p className="workflowboxdetailks">Aliquid molestiae alias beatae itaque voluptate.</p>


                          </div>

                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact5" role="tabpanel" aria-labelledby="pills-contact-tab5">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={civil} alt="" />
                            <p className="workflowboxtitle">Civil Maintenance Works</p>
                            <p className="workflowboxdetailks">Qui quod rerum reprehenderit voluptas facere.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={interior} alt="" />
                            <p className="workflowboxtitle">Interior Residential</p>
                            <p className="workflowboxdetailks">Omnis non et atque consectetur sint iusto.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={property} alt="" />
                            <p className="workflowboxtitle">Property Fencing</p>
                            <p className="workflowboxdetailks">Voluptatibus sequi soluta dolor corrupti nisi quod.</p>


                          </div>

                        </div>
                        <div className="col-md-3">
                          <div className="workflowbox">
                            <img src={development} alt="" />
                            <p className="workflowboxtitle">Property Development</p>
                            <p className="workflowboxdetailks">Aliquid molestiae alias beatae itaque voluptate.</p>


                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </section>
          <section>
            <div className="container">
              <div className="row justify-content-center helpdiv">
                <div className="text-center col-md-6">
                  <p className="commonmainbigtitle">We Are Here To Help You!</p>
                  <p className="mainrboxtextsub">Est vitae optio excepturi voluptatibus consequatur pariatur deserunt. Praesentium blanditiis magnam error maiores sed. Est laborum doloribus nobis omnis cum ipsam officia.</p>
                  <button type="button" className="btn btn-warning readmorebtnew">Get Started</button>
                </div>

              </div>

            </div>
          </section>
          <section>
            <div className="footermaindiv">
              <img src={footershap} className="footershapimg"></img>
              <img src={footershap} className="footershapimg"></img>

              <div className="container">
                <div className="row maincontentdivfooter">
                  <div className="col-md-4">
                    <div className="paddingfooterdiv">
                      <p className="footertitle"> Getting Started</p>
                      <p className="footerdescription">Beatae quidem et laudantium. Voluptates eos eaque aut incidunt consectetur enim sed nulla. Sed eaque et sint qui. </p>
                      <button type="button" className="btn btn-danger loginbtnfooter">Login</button>

                    </div>

                  </div>
                  <div className="col-md-4">
                    <div className="paddingfooterdiv2">
                      <p className="footertitle"> Quick Inquiry</p>
                      <div className="footerform">
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Name" />
                      </div>
                      <div className="footerform">
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                      </div>
                      <div className="footerform">
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Mobile Number" />
                      </div>
                      <div className="footerform">
                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Message" />
                      </div>
                      <div>
                        <a href="#" className="submittextbtn">Submit<i className="fas fa-long-arrow-alt-right"></i></a>
                      </div>

                    </div>

                  </div>
                  <div className="col-md-4">
                    <div>
                      <p className="footertitle">Newsletter</p>
                      <p className="footerdescription">Omnis illo ipsum pariatur magni unde necessitatibus ut illo. In autem qui ut accusamus sint perferendis veritatis voluptatem. Eos impedit ea. </p>
                      <div className="input-group input-groupfooter">
                        <input type="text" className="form-control" placeholder="Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-danger" type="button" id="button-addon2">Submit</button>
                      </div>


                    </div>

                  </div>

                </div>
                <div className="footersocialmedia">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mainfooterpagetext"> 
                          <p className="footerpagetext"> <a href="#">Terms of user </a> </p>
                          <p className="footerpagetext2"> <a href="#">Privacy</a></p>
                          <p className="footerpagetext2"> <a href="#">Refund</a></p>
                      </div>


                    </div>
                    <div className="col-md-6">
                      <div className="footersocialmediaicon">
                        <a href="#"><i className="fab fa-facebook-f ms-0"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="disclamerlinefooter"> 
                <p>
                COPYRIGHT © ALL RIGHTS RESERVED BY Loremipsum
                </p>

                </div>

              </div>

            </div>
          </section>
        </div>
      </React.Fragment>
    )
  }
}

export default UiVideo
