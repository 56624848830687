import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  Label,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  getOrders,
  addNewOrder,
  updateOrder,
  deleteOrder,
} from "store/actions";

import EcommerceOrdersModal from "./EcommerceOrdersModal";
import DeleteModal from "../../../components/Common/DeleteModal";

class EcommerceOrders extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      viewmodal: false,
      modal: false,
      orders: [],
      order: "",
      deleteModal: false,
      EcommerceOrderColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{order.id}</>,
        },
        {
          dataField: "orderId",
          text: "Order ID",
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.orderId}
            </Link>
          ),
        },
        {
          dataField: "billingName",
          text: "Billing Name",
          sort: true,
        },
        {
          dataField: "orderdate",
          text: "Date",
          sort: true,
          formatter: (cellContent, row) => this.handleValidDate(row.orderdate),
        },
        {
          dataField: "total",
          text: "Total",
          sort: true,
        },
        {
          dataField: "paymentStatus",
          text: "Payment Status",
          sort: true,
          formatter: (cellContent, row) => (
            <Badge
              className={"font-size-12 badge-soft-" + row.badgeclass}
              color={row.badgeclass}
              pill
            >
              {row.paymentStatus}
            </Badge>
          ),
        },
        {
          dataField: "paymentMethod",
          isDummyField: true,
          text: "Payment Method",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <i
                className={
                  row.paymentMethod !== "COD"
                    ? "fab fa-cc-" +
                    this.toLowerCase1(row.paymentMethod) +
                    " me-1"
                    : "fab fas fa-money-bill-alt me-1"
                }
              />{" "}
              {row.paymentMethod}
            </>
          ),
        },
        {
          dataField: "view",
          isDummyField: true,
          text: "View Details",
          sort: true,
          formatter: () => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={this.toggleViewModal}
            >
              View Details
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          formatter: (cellContent, order) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleOrderClick(order)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => this.onClickDelete(order)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
    };

    this.handleOrderClick = this.handleOrderClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleOrderClicks = this.handleOrderClicks.bind(this);
    this.toLowerCase1 = this.toLowerCase1.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }

  componentDidMount() {
    const { orders, onGetOrders } = this.props;
    if (orders && !orders.length) {
      onGetOrders();
    }
    this.setState({ orders });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props;
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ order: {}, isEdit: false });
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleOrderClicks = () => {
    this.setState({ order: "", isEdit: false });
    this.toggle();
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (order) => {
    this.setState({ order: order });
    this.setState({ deleteModal: true });
  };

  handleDeleteOrder = () => {
    const { onDeleteOrder } = this.props;
    const { order } = this.state;
    if (order.id !== undefined) {
      onDeleteOrder(order);
      this.onPaginationPageChange(1);
      this.setState({ deleteModal: false });
    }
  };

  handleOrderClick = arg => {
    const order = arg;

    this.setState({
      order: {
        id: order.id,
        orderId: order.orderId,
        billingName: order.billingName,
        orderdate: order.orderdate,
        total: order.total,
        paymentStatus: order.paymentStatus,
        paymentMethod: order.paymentMethod,
        badgeclass: order.badgeclass,
      },
      isEdit: true,
    });

    this.toggle();
  };

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  render() {
    const { orders } = this.props;

    const order = this.state.order;

    const { SearchBar } = Search;

    const { isEdit, deleteModal } = this.state;

    const { onAddNewOrder, onUpdateOrder } = this.props;

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: orders.length, // replace later with size(Order),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "orderId",
        order: "desc",
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
     <div className="page-content">
          <MetaTags>
            <title>Customer/Client Management | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Customer-Client Management</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Management</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
             
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EcommerceOrders.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  className: PropTypes.any,
};

const mapStateToProps = state => ({
  orders: state.ecommerce.orders,
});

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getOrders()),
  onAddNewOrder: order => dispatch(addNewOrder(order)),
  onUpdateOrder: order => dispatch(updateOrder(order)),
  onDeleteOrder: order => dispatch(deleteOrder(order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EcommerceOrders));
