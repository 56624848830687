import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiTypography extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Col className="col-12 mb-3">
                <div className="breadcrumb align-items-center justify-content-end">
               <a href="/dashboard" className="me-2">Home</a> <span>/</span><span className="ms-2">Lead List</span>
                </div>
              </Col>
            {/* <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Tabs & Accordions"
            /> */}

            <Card>
              <CardBody>
                <Row>
                  <Col xl={12}>
                  <CardTitle className="h4">Lead List</CardTitle>
                    <p className="card-title-desc">
                    <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-4 col-form-label w-115"
                          
                        >
                         Search Leads
                        </Label>
                        <Col sm={3}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder=""
                          />
                        </Col>
                        <Col sm={7}>
                        <div className="text-end">
                          Date of Lead <i className="mdi mdi-calendar-month h4 ms-3"></i>
                        </div>
                        </Col>
                      </div>
                    </p>
                  </Col>
                  <Col lg={12}>
                <p className="text-center">Leaders of the duration of 25/5/2019 to 25/8/2021</p>
              </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                  <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">FullName</th>
                          <th scope="col" className="">Qualification</th>
                          <th scope="col" className="">Occupation</th>
                          <th scope="col" className="">Country</th>
                          <th scope="col" className="">State</th>
                          <th scope="col" className="">Mobile No</th>
                          <th scope="col" className="">Email Id</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">Yash Patel</th>
                          <td>B.com</td>
                          <td>Business</td>
                          <td>India</td>
                          <td>Gujarat</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-eye h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-pencil h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-delete h5"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">Raj Patel</th>
                          <td>B.com</td>
                          <td>Business</td>
                          <td>India</td>
                          <td>Gujarat</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-eye h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-pencil h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-delete h5"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">Yashvi Patel</th>
                          <td>B.com</td>
                          <td>Business</td>
                          <td>India</td>
                          <td>Gujarat</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-eye h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-pencil h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-delete h5"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">Krina Domadiya</th>
                          <td>B.com</td>
                          <td>Business</td>
                          <td>India</td>
                          <td>Gujarat</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-eye h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-pencil h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-delete h5"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">Yogesh Dave</th>
                          <td>B.com</td>
                          <td>Business</td>
                          <td>India</td>
                          <td>Gujarat</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-eye h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-pencil h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-delete h5"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">Riddhi Suthar</th>
                          <td>B.com</td>
                          <td>Business</td>
                          <td>India</td>
                          <td>Gujarat</td>
                          <td>0123456789</td>
                          <td>email@gmail.com</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-eye h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-pencil h5 me-3"></i></a>
                              <a href=""><i className="mdi mdi-delete h5"></i></a>
                              
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTypography
